const itemsToRemove = [
  "adminInfo",
  "adminRole",
  "adminToken",
  "page",
  "ConsultationLimit",
  "ConsultationsearchText",
  "ConsultationpaymentStatus",
  "ConsultationprescriptionStatus",
  "PaidPrescriptionLimit",
  "PaidPrescriptionsearchText",
  "RefillLimit",
  "RefillsearchText",
  "RefillpaymentStatus",
  "RefillprescriptionStatus",
];

export const ENV = {
  serverUrl: process.env.REACT_APP_SERVER_URL,
  encryptUserData: function (data) {
    if (data) {
      localStorage.setItem("adminInfo", JSON.stringify(data?.admin));
      localStorage.setItem("adminRole", JSON.stringify(data?.admin?.role));
    }
    if (data?.token) {
      localStorage.setItem("adminToken", JSON.stringify(data?.token));
    }
  },

  logOut: () => {
    itemsToRemove.forEach((item) => {
      localStorage.removeItem(item);
    });
  },
};
