import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Breadcrumb } from "react-bootstrap";
import { getMedicalConsultationsActive } from "../../../actions/medicalConsultationAction";
import { addForm, getFormById, getForms } from "../../../actions/formsAction";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import { getAllPrescription } from "../../../actions/refillPrescriptionAction";

import "react-form-builder2/dist/app.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormBuilder from "./FormBuilder";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CreateForm = () => {
  const navigate = useNavigate();
  const generalFields = [
    {
      name: "haveTitle",
      text: "Include Title Field",
      type: "checkbox",
      haveTitle: false,
    },
    {
      name: "haveFirstName",
      text: "Include First Name Field",
      type: "checkbox",
      haveFirstName: false,
    },
    {
      name: "haveLastName",
      text: "Include Last Name Field",
      type: "checkbox",
      haveLastName: false,
    },
    {
      name: "haveEmail",
      text: "Include Email Field",
      type: "checkbox",
      haveEmail: false,
    },
    {
      name: "haveDob",
      text: "Include DOB Field",
      type: "checkbox",
      haveDob: false,
    },
    {
      name: "haveGender",
      text: "Include Gender Check boxes",
      type: "checkbox",
      haveGender: false,
    },
    {
      name: "haveAge",
      text: "Include Age Field",
      type: "checkbox",
      haveAge: false,
    },
    {
      name: "haveWeight",
      text: "Include Weight Field",
      type: "checkbox",
      haveWeight: false,
    },
    {
      name: "haveHeight",
      text: "Include Height Field",
      type: "checkbox",
      haveHeight: false,
    },
    {
      name: "havePhone",
      text: "Include Phone Field",
      type: "checkbox",
      havePhone: false,
    },
    {
      name: "haveAddress",
      text: "Include Address Field",
      type: "checkbox",
      haveAddress: false,
    },
    {
      name: "haveCity",
      text: "Include City Field",
      type: "checkbox",
      haveCity: false,
    },
    {
      name: "haveState",
      text: "Include State/Region/Province Field",
      type: "checkbox",
      haveState: false,
    },
    {
      name: "haveZipCode",
      text: "Include Zip Field",
      type: "checkbox",
      haveZipCode: false,
    },
    {
      name: "haveIncludeFile",
      text: "Show ID Card Upload Fields",
      type: "checkbox",
      haveIncludeFile: false,
    },
  ];
  const [fromBuilder, setFromBuilder] = useState({});
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [formTypeParameter, setFormTypeParameter] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    formConsultation: "",
    existingFormConsultation: "",
    termsConditions: "",
  });
  const [selectedExistingMedicalType, setSelectedExistingMedicalType] =
    useState("");
  const [
    selectedExistingPrescriptionType,
    setSelectedExistingPrescriptionType,
  ] = useState("");

  const [selectedMedicalType, setSelectedMedicalType] = useState("");

  const [medicalConsultationData, setMedicalConsultation] = useState({});
  const [existingMedicalConsultationData, setExistingMedicalConsultation] =
    useState([]);
  const [existingMedicalprescription, setExistingMedicalprescription] =
    useState([]);
  const [statusState, setStatusState] = useState(false);
  const [formData, setFormData] = useState();
  const [hit, setHit] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    haveTitle: false,
    haveFirstName: false,
    haveLastName: false,
    haveEmail: false,
    haveDob: false,
    haveGender: false,
    haveAge: false,
    haveWeight: false,
    haveHeight: false,
    havePhone: false,
    haveAddress: false,
    haveCity: false,
    haveState: false,
    haveZipCode: false,
    haveIncludeFile: false,
  });
  const getAllPrescriptionRefill = async () => {
    setLoader(true);
    let res = await getAllPrescription();
    if (res.data.data) {
      setPrescriptionData(res.data.data);
    }
    setLoader(false);
  };
  const getAllMedicalConsultation = async () => {
    setLoader(true);
    let res = await getMedicalConsultationsActive();
    if (res.data.data) {
      setMedicalConsultation(res.data.data);
    }
    setLoader(false);
  };
  const handleData = (newData) => {
    setFromBuilder(newData);
  };

  const onChangeGeneralFields = (e) => {
    setSelectAll(e.target.checked);
    setCheckboxes((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = e.target.checked;
      });
      setFormData({
        ...formData,
        ...newState,
      });
      return newState;
    });
  };

  const handleCheckbox = (event) => {
    setCheckboxes((pre) => {
      const result = { ...pre, [event.target.name]: event.target.checked };
      setFormData((prevFormData) => ({
        ...prevFormData,
        [event.target.name]: event.target.checked,
      }));

      let allSelected = false;
      Object.keys(result).forEach((val) => {
        if (result[val]) {
        } else {
          allSelected = true;
        }
      });
      let selectCount = allSelected ? false : true;
      setSelectAll(selectCount);
      return result;
    });
  };

  const onEditorChange = (editor) => {
    // eslint-disable-next-line
    setFormData({
      // eslint-disable-next-line
      ...formData,
      ["termsConditions"]: editor.getData(),
    });
  };

  const handleFormType = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "formConsultation" || name === "prescriptionRefill") {
      setSelectedMedicalType(value);
    }
  };

  const handleFormPath = (e) => {
    const { name, value } = e.target;
    // Assuming formTypeParameter is a state variable

    setFormTypeParameter(value);
  };

  const submit = async () => {
    let validate = true;
    setHit(!hit);
    var data = { formFields: fromBuilder.actions.getData("json") };
    var formFieldss = "formFields";
    setFormData({
      ...formData,
      [formFieldss]: data,
    });

    data = Object.assign(formData, data);

    if (formData?.title?.trim() === "" || formData?.title === undefined) {
      setErrors((currentError) => {
        return { ...currentError, title: "Form Title is required!" };
      });
      validate = false;
    } else {
      setErrors((currentError) => {
        return { ...currentError, title: "" };
      });
      validate = true;
    }
    if (
      formData?.formConsultation == "" ||
      formData?.formConsultation === undefined
    ) {
      setErrors((currentError) => {
        return {
          ...currentError,
          formConsultation: "Medical Type is required!",
        };
      });
      validate = false;
    } else {
      setErrors((currentError) => {
        return { ...currentError, formConsultation: "" };
      });
      validate = true;
    }

    if (validate) {
      const formDataWithoutId = { ...formData };
      delete formDataWithoutId._id;
      delete formDataWithoutId.createdAt;
      delete formDataWithoutId.updatedAt;

      setLoader(true);
      let res = await addForm(formDataWithoutId);
      if (res?.data?.success) {
        navigate("/forms/forms");
        toast.success(res?.data?.message);
      }
      setLoader(false);
    }
  };

  const checkStatus = (check) => {
    setStatusState(check);
    setFormData((pre) => ({ ...pre, status: check }));
  };

  //Existing medical
  const getAllExistingMedicalConsultation = async () => {
    setLoader(true);
    let res = await getForms();
    if (res?.data?.data) {
      setExistingMedicalConsultation(res?.data?.data?.forms);
    }
    setLoader(false);
  };
  //Existing Prescription
  const getAllExistingMedicalPrescription = async () => {
    setLoader(true);
    let res = await getAllPrescription();
    if (res.data.data) {
      setExistingMedicalprescription(res.data.data);
    }
    setLoader(false);
  };

  const handleExistingMedicalType = async (id) => {
    try {
      setLoader(true);
      let res = await getFormById(id, formTypeParameter);
      if (res.data.data) {
        setFormData(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      // Handle any potential errors
      console.error("Error fetching existing medical type:", error);
    } finally {
      setLoader(false);
    }
  };

  // get by id click Prescription refil
  const handleExistingPrescription = async (id) => {
    try {
      setLoader(true);
      // Uncomment the following lines
      let res = await getFormById(id, formTypeParameter);

      if (res.data.data) {
        setFormData(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      // Handle any potential errors
      console.error("Error fetching existing Prescription type:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllMedicalConsultation();
    getAllPrescriptionRefill();
    getAllExistingMedicalConsultation();
    getAllExistingMedicalPrescription();
  }, []);

  useEffect(() => {
    if (formData) {
      setTimeout(function () {
        if (fromBuilder.actions.setData) {
          fromBuilder.actions.setData(formData.formFields);
        }
      }, 500);
      var t = {};
      Object.keys(formData).map((key) => {
        if (formData[key] === true || formData[key] === false) {
          t[key] = formData[key];
        }
        return true;
        // eslint-disable-next-line
      });

      if (Object.keys(t).length > 0) {
        setCheckboxes(t);
      }
    }
  }, [fromBuilder, formData]);

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5 create-form">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/forms/forms">
                    Forms
                  </Link>
                  <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">Add Content</h4>
                </div>
                <div className="panel-body">
                  <h5 className="pvr-header">Fill all fields</h5>
                  <Row>
                    <Col md={1}>
                      <label className="label-font">Form Type</label>
                    </Col>
                    <Col
                      md={11}
                      className="check-inline d-flex mt-3 flex-column"
                    >
                      <label className="right-label-radio mr-3 mb-2">
                        Consultation Form
                        <input
                          name="formType"
                          value="consultationForm"
                          className="form_type"
                          type="radio"
                          checked={
                            formData?.formType === "consultationForm"
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            handleFormPath(e);
                            handleFormType(e);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* <label className="right-label-radio mr-3 mb-2">
                        Lab Test Form
                        <input
                          name="formType"
                          value="labTestForm"
                          className="form_type"
                          type="radio"
                          checked={
                            formData?.formType === "labTestForm" ? true : false
                          }
                          onChange={handleFormType}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio mr-3 mb-2">
                        Imaging Form
                        <input
                          name="formType"
                          value="imagingForm"
                          className="form_type"
                          type="radio"
                          checked={
                            formData?.formType === "imagingForm" ? true : false
                          }
                          onChange={handleFormType}
                        />
                        <span className="checkmark"></span>
                      </label> */}
                      {/* <label className="right-label-radio mr-3 mb-2">
                      Mental Health
                        <input
                          name="formType"
                          value="mentalHealthForm"
                          className="form_type"
                          type="radio"
                          checked={formData?.formType === "mentalHealthForm" ? true : false}
                          onChange={handleFormType}
                        />
                        <span className="checkmark"></span>
                      </label> */}

                      <label className="right-label-radio mr-3 mb-2">
                        Prescription Refill
                        <input
                          name="formType"
                          value="prescriptionRefill"
                          className="form_type"
                          type="radio"
                          checked={
                            formData?.formType === "prescriptionRefill"
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            handleFormPath(e);
                            handleFormType(e);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    {formData?.formType === "consultationForm" && (
                      <>
                        {" "}
                        <Col md={6} className="search-wrap">
                          <Form.Group controlId="formBasicMedicalType">
                            <Form.Label className="text-white">
                              Medical Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="formConsultation"
                              value={selectedMedicalType}
                              onChange={handleFormType}
                            >
                              <option value="" disabled>
                                Select Medical Type
                              </option>
                              {medicalConsultationData &&
                              medicalConsultationData.length > 0
                                ? medicalConsultationData.map((d, k) => {
                                    return (
                                      <option
                                        key={d._id}
                                        value={d._id}
                                        className="text-capitalize"
                                      >
                                        {d.title}
                                      </option>
                                    );
                                  })
                                : null}
                            </Form.Control>
                            <span
                              className={
                                errors?.formConsultation ? `` : `d-none`
                              }
                            >
                              <label className="pl-1 text-danger">
                                {errors?.formConsultation}
                              </label>
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md={6} className="search-wrap">
                          <Form.Group controlId="formBasicMedicalType">
                            <Form.Label className="text-white">
                              Existing Medical Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="existingFormConsultation"
                              value={selectedExistingMedicalType}
                              onChange={(e) => {
                                setSelectedExistingMedicalType(e.target.value);
                                handleExistingMedicalType(e.target.value);
                              }}
                            >
                              <option disabled value="">
                                Select Existing Medical Type
                              </option>
                              {existingMedicalConsultationData?.map((d, k) => {
                                return (
                                  <option
                                    key={d._id}
                                    value={d._id}
                                    className="text-capitalize"
                                  >
                                    {d.title}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <span>
                              <p className="search-wrap text-dangeronly mt-1 mb-0">
                                Select from above Select Box and form title will
                                be Default Form, if you edit the old form for
                                new type.
                              </p>
                            </span>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    {formData?.formType === "prescriptionRefill" && (
                      <>
                        <Col md={6} className="search-wrap">
                          <Form.Group controlId="formBasicMedicalType">
                            <Form.Label className="text-white">
                              Prescription Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="prescriptionRefill"
                              value={selectedMedicalType}
                              onChange={handleFormType}
                            >
                              <option disabled value="">
                                Select Prescription Type
                              </option>
                              {prescriptionData && prescriptionData.length > 0
                                ? prescriptionData.map((d, k) => {
                                    return (
                                      <option
                                        key={d._id}
                                        value={d._id}
                                        className="text-capitalize"
                                      >
                                        {d.title}
                                      </option>
                                    );
                                  })
                                : null}
                            </Form.Control>
                            <span
                              className={
                                errors?.prescriptionRefill ? `` : `d-none`
                              }
                            >
                              <label className="pl-1 text-danger">
                                {errors?.prescriptionRefill}
                              </label>
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md={6} className="search-wrap">
                          <Form.Group controlId="formBasicMedicalType">
                            <Form.Label className="text-white">
                              Existing Prescription Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="existingFormPrescription"
                              value={selectedExistingPrescriptionType}
                              onChange={(e) => {
                                setSelectedExistingPrescriptionType(
                                  e.target.value
                                );
                                handleExistingPrescription(e.target.value);
                              }}
                            >
                              <option disabled value="">
                                Select Existing Prescription Type
                              </option>
                              {existingMedicalprescription?.map((d, k) => {
                                return (
                                  <option
                                    key={d._id}
                                    value={d._id}
                                    className="text-capitalize"
                                  >
                                    {d.title}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <span>
                              <p className="search-wrap text-dangeronly mt-1 mb-0">
                                Select from above Select Box and form title will
                                be Default Form, if you edit the old form for
                                new type.
                              </p>
                            </span>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Form Title
                        </Form.Label>
                        <Form.Control
                          type="name"
                          name="title"
                          placeholder="Enter Form Title (Default Form)"
                          onChange={handleFormType}
                        ></Form.Control>
                        <span className={errors.title ? `` : `d-none`}>
                          <label className="pl-1 text-danger">
                            {errors.title}
                          </label>
                        </span>
                      </Form.Group>
                    </Col>
                    {/* <Col md={6} className="search-wrap">
                                            <label>Select Existing Form</label>
                                            <Form.Group>
                                                <select className='w-100'>
                                                    <option value="">Select</option>
                                                    <option value="">Strep Throat</option>
                                                    <option value="">Ancne</option>
                                                </select>
                                            </Form.Group>
                                        </Col> */}

                    <Col
                      md={11}
                      className="check-inline d-flex mt-3 flex-column"
                    >
                      <label className="label-font">Status</label>
                      <label className="right-label-radio mr-3 mb-2 mt-2">
                        Active
                        <input
                          name="status"
                          className="status"
                          checked={statusState}
                          value={statusState}
                          type="radio"
                          onChange={() => checkStatus(true)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio mr-3 mb-2">
                        Inactive
                        <input
                          name="status1"
                          className="status"
                          checked={!statusState}
                          value={!statusState}
                          type="radio"
                          onChange={() => checkStatus(false)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                  <h5 className="pvr-header mt-2">General Fields</h5>
                  <div className="general-fields-checkboxes">
                    <div className="checkbox">
                      <label className="container">
                        <input
                          type="checkbox"
                          key="check_all"
                          id="check_all"
                          name="check_all"
                          onChange={onChangeGeneralFields}
                          checked={selectAll}
                        />{" "}
                        Check All
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="checkboxes d-flex flex-wrap">
                      {generalFields?.map((field) => {
                        return (
                          <div className="checkbox">
                            <label className="container">
                              <input
                                type={field.type}
                                key={field.name}
                                id={field.name}
                                name={field.name}
                                checked={
                                  (formData && formData[field.name]) || false
                                }
                                onChange={handleCheckbox}
                              />
                              {field.text}
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5 className="pvr-header mt-2">
                    Terms & Conditions Content{" "}
                    <span className="text-danger">*</span>
                  </h5>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <CKEditor
                      editor={ClassicEditor}
                      name="termsConditions"
                      data={
                        formData?.termsConditions
                          ? formData?.termsConditions
                          : ""
                      }
                      content={
                        formData?.termsConditions
                          ? formData?.termsConditions
                          : ""
                      }
                      onChange={(event, editor) => onEditorChange(editor)}
                    />
                    <span className={errors.termsConditions ? `` : `d-none`}>
                      <label className="pl-1 text-danger">
                        {errors.termsConditions}
                      </label>
                    </span>
                  </Form.Group>
                  <h5 className="pvr-header mt-3">
                    Add Custom Fields For Medical Info Tab (English)
                  </h5>
                  <FormBuilder onDataChange={handleData} hit={hit} />
                  <div className="text-right mt-4">
                    <button className="btn-filled" onClick={submit}>
                      Continue
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default CreateForm;
