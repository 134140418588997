import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk'
import { allReducers } from "./combineReducers";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk]

const store = createStore(
    allReducers,
    composeWithDevTools(applyMiddleware(...middleware))
)



export default store