import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getRoles, addAdminUser } from "../../actions/adminUserAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import SweetAlert from "react-bootstrap-sweetalert";

// Schema for yup
const userSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^[^!@#$%^&*+=<>:;|~]*$/, { message: "Invalid format." })
    .trim()
    .min(3, "Username must have at least 3 characters")
    .required("Username field is required"),
  email: Yup.string().email().required("Email field is required"),
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("Password field is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password field is required"),
  roleId: Yup.string().required("Role type is required"),
});
const AddAdminUser = () => {
  const navigate = useNavigate();
  const [getFile, setFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [roles, setRoles] = useState({});
  const [uploadImage, setUploadImage] = useState(false);
  const formik = useFormik({
    initialValues: {
      roleId: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      photo: "",
      status: false,
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("roleId", values.roleId);
      formData.append("username", values.username);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("confirmPassword", values.confirmPassword);
      formData.append("photo", values.photo);
      formData.append("status", values.status);
      setLoader(true);
      let res = await addAdminUser(formData);
      if (res?.data?.success) {
        navigate("/users");
        toast.success(res?.data?.message);
      }
      setLoader(false);
    },
  });
  const getAllRoles = async () => {
    let res = await getRoles();
    if (res?.data?.success) {
      setRoles(res.data.data);
    }
  };

  const handlePreviewImage = (e, setFieldValue) => {
    if (e.target.files[0].type.split("/")[1]) {
      let imageType = e.target.files[0].type.split("/")[1];
      if (
        imageType === "png" ||
        imageType === "jpg" ||
        imageType === "jpeg" ||
        imageType === "gif" ||
        imageType === "webp"
      ) {
        setFieldValue("photo", e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        setUploadImage(true);
        e.target.value = null;
      }
    }
  };
  const hideModel = () => {
    setUploadImage(false);
  };
  useEffect(() => {
    getAllRoles();
  }, []);
  return (
    <>
      {uploadImage ? (
        <SweetAlert
          warning
          showCloseButton
          confirmBtnText="OK"
          onConfirm={hideModel}
          confirmBtnBsStyle="danger"
          title="Failed! Please select correct Image Format"
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert "
        >
          Supported files ["png", "jpg", "jpeg", "gif", "webp"]
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/users">
                    Admin User
                  </Link>
                  <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Add Admin User
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicusername"
                          >
                            <Form.Label>
                              Username <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="username"
                              value={formik.values.username}
                              placeholder="Enter username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.username &&
                            formik.errors.username ? (
                              <span
                                className={
                                  formik.errors.username ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.username}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Email <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={formik.values.email}
                              placeholder="Enter email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <span
                                className={formik.errors.email ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.email}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicRole"
                          >
                            <Form.Label>
                              Select Role <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              as="select"
                              name="medicalType"
                              onChange={(e) =>
                                formik.setFieldValue("roleId", e.target.value)
                              }
                            >
                              <option value="">Select Role</option>
                              {roles && roles.length > 0
                                ? roles.map((d, k) => {
                                    if (
                                      d.title.toLowerCase() !== "super admin"
                                    ) {
                                      return (
                                        <option
                                          key={d._id}
                                          value={d._id}
                                          className="text-capitalize"
                                        >
                                          {d.title}
                                        </option>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })
                                : null}
                            </Form.Control>
                            {formik.touched.roleId && formik.errors.roleId ? (
                              <span
                                className={formik.errors.roleId ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.roleId}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>
                              Password <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              value={formik.values.password}
                              placeholder="Enter password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <span
                                className={
                                  formik.errors.password ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.password}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicConfirmPassword"
                          >
                            <Form.Label>
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="confirmPassword"
                              value={formik.values.confirmPassword}
                              placeholder="Enter password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.confirmPassword &&
                            formik.errors.confirmPassword ? (
                              <span
                                className={
                                  formik.errors.confirmPassword ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.confirmPassword}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            controlId="formFile"
                            className="mb-3"
                            onChange={(e) =>
                              handlePreviewImage(e, formik.setFieldValue)
                            }
                          >
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control
                              type="file"
                              name="photo"
                              accept=".png,.jpg,.jpeg,.webp"
                              className="custom-upload-img"
                            />
                            {formik.touched.photo && formik.errors.photo ? (
                              <span
                                className={formik.errors.photo ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.photo}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            controlId="formFilePreview"
                            className="mb-3"
                          >
                            {getFile ? (
                              <img
                                src={getFile}
                                width="200"
                                height="200"
                                alt="Admin profile"
                              />
                            ) : null}
                          </Form.Group>
                          <Row>
                            <Col md={1}>
                              <label className="label-font">Status</label>
                            </Col>
                            <Col
                              md={11}
                              className="check-inline d-flex mt-3 flex-column generic-btn-width"
                            >
                              <label className="right-label-radio mr-3 mb-2">
                                Active
                                <input
                                  name="status"
                                  className="status"
                                  checked={formik.values.status}
                                  value={formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", true)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="right-label-radio mr-3 mb-2">
                                Inactive
                                <input
                                  name="status1"
                                  className="status"
                                  checked={!formik.values.status}
                                  value={!formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", false)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Col>
                          </Row>
                          <Button className="btn-filled" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default AddAdminUser;
