import { React, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logoAdmin.svg";
import favicon from "../../assets/images/Logo.png";

// react-bootstrap components
import { Collapse, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import routes from "../../routes";
import $ from "jquery";
const image =
  "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658146838/hex-nft/assets/sidebar-bg_gmgqbc_sgisga.png";

function Sidebar({ permissionName }) {
  // to check for active links and opened collapses
  const [state, setState] = useState({});
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin?.role;

  const location = useLocation();

  const navbarHandler = () => {
    $("html").removeClass("nav-open");
  };

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  // const getCollapseInitialState = (routes) => {
  // 	for (let i = 0; i < routes?.length; i++) {

  // 		if (location.pathname === routes[i]?.path) {
  // 			return true;
  // 		}
  // 	}
  // 	return false;
  // };
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].submenus)) {
        return true;
      } else if (location.pathname === routes[i].path) {
        return true;
      }
    }
    return false;
  };

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.submenus),
          ...getCollapseStates(route.submenus),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  useEffect(() => {
    if (routes && routes.length > 0) {
      setState(getCollapseStates(routes));
    }
    // eslint-disable-next-line
  }, [routes, permissionName]);

  // function getCollapseStates(list) {
  //   // Initialize an empty array to store the collapse states
  //   let collapseStates = [];

  //   // Iterate through the list and set the initial collapse state to false
  //   for (let i = 0; i < list.length; i++) {
  // 	collapseStates[i] = false;
  //   }

  //   // Return the list of collapse states
  //   return collapseStates;
  // }

  const createLinks = (routes) => {
    return routes?.map((route, key) => {
      if (route?.showInSideBar) {
        if (route?.collapse) {
          var st = {};
          st[route["state"]] = !state[route.state];
          if (currentUserRole) {
            if (currentUserRole[route.permissionName]) {
              return (
                <Nav.Item
                  className={
                    getCollapseInitialState(route.submenus) ? "active" : ""
                  }
                  as="li"
                  key={key}
                >
                  <Nav.Link
                    className={state[route.state] ? "collapsed" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, ...st });
                    }}
                    data-toggle="collapse"
                    aria-expanded={state[route.state]}
                  >
                    <span className="nav-icon-holder">
                      <i className={route.icon}></i>
                    </span>
                    <p>
                      {route?.name}
                      <b className="caret"></b>
                    </p>
                  </Nav.Link>
                  <Collapse in={state[route.state]}>
                    <div>
                      <Nav as="ul" className="col">
                        {createLinks(route.submenus)}
                      </Nav>
                    </div>
                  </Collapse>
                </Nav.Item>
              );
            }
          }
        } else {
          if (currentUserRole) {
            if (currentUserRole[route.permissionName]) {
              return (
                <Nav.Item
                  className={activeRoute(route?.path)}
                  key={key}
                  as="li"
                >
                  <NavLink className={"nav-link"} to={route.path}>
                    <span className="nav-icon-holder">
                      <i className={route.icon}></i>
                    </span>
                    <p>{route?.name}</p>
                  </NavLink>
                </Nav.Item>
              );
            }
          }
        }
      }
      return false;
    });
  };

  return (
    <>
      <div className="sidebar" data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-end">
            <Link className="logo-mini" to="/">
              <div className="logo-img">
                <img src={favicon} alt="Hex Logo" />
              </div>
            </Link>
            <Link className="simple-text logo-normal" to="/">
              <img src={logo} alt="Hex Logo" />
            </Link>
            <span
              className="nav-icon-holder cross-icon"
              onClick={() => navbarHandler()}
            >
              <i className="fa fa-times"></i>
            </span>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
