import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useParams } from "react-router-dom";
import { ENV } from "../../config/config";
import { getJobApplicationById } from "../../actions/jobApplicationAction";

export default function ViewJobApllication() {
  const { id } = useParams();

  const [applicationData, setApplicationData] = useState({});
  const [loader, setLoader] = useState(true);

  const setFilePath = (files) => {
    return `https://admin.pcaremd-mern.arhamsoft.org/public/images/`;
  };

  useEffect(() => {
    const getApplication = async () => {
      let res = await getJobApplicationById(id);
      if (res?.data?.data) {
        setApplicationData(res.data.data);
      }
      setLoader(false);
    };
    getApplication();
  }, [id]);

  const handleDownloadCv = () => {
    const cvFilePath = `https://admin.pcaremd-mern.arhamsoft.org/public/${applicationData.cv}`;

    const applicantName =
      applicationData?.firstName.concat(applicationData?.lastName) ||
      "Applicant";
    const downloadLink = document.createElement("a");
    downloadLink.href = cvFilePath;
    downloadLink.download = `${applicantName}_resume.pdf`;
    document.body.appendChild(downloadLink);

    // Simulate a click on the download link

    downloadLink.click();

    // Remove the download link from the document body
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="dashboard-consultation-wrapper">
          <div className="consultation-heading mb-5">
            <h2>Job Apllication Detail</h2>
            <p>Admin Dashboard</p>
          </div>
          <div className="table-heading position-relative mb-5">
            <h5>About Applicant</h5>
          </div>
          <div className="table-responsive">
            <Table striped className="consultation-table mb-4">
              <tbody>
                <tr>
                  <th>Applicant Name</th>
                  <td>
                    {applicationData?.firstName +
                      " " +
                      applicationData.lastName}
                  </td>
                  <th>Email</th>
                  <td>
                    <span className="badge badge-success">
                      {applicationData?.email}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Other Information</th>
                  <td>{applicationData?.information}</td>
                  <th>Any interest in live patient lectures</th>
                  <td>{applicationData?.interest}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{applicationData?.phone}</td>
                  <th>Specialty</th>
                  <td>{applicationData?.specialty}</td>
                </tr>
                <tr>
                  <th>Licensed </th>
                  <td>{applicationData?.state}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="table-heading position-relative mb-5">
            <h5>
              {applicationData?.cv
                ? "Attachment found"
                : "No Attachments found"}
            </h5>
          </div>

          {applicationData?.cv && (
            <>
              <button className="form-controll" onClick={handleDownloadCv}>
                Download Attached File
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}
