import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getBlogCategories,
  deleteBlogCategoryById,
} from "../../actions/blogCategoryAction";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import InitializeLocalStorage from "../../components/PaginationHandle";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const Index = () => {
  const [blogCategoryData, setBlogCategoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const hideModel = () => {
    setDelete(false);
  };
  const deleteBlogCategory = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteBlogCategoryById(deleteId);
    if (res.data.success) {
      toast.success(res.data.message);
      getAllBlogCategory();
    }
    setLoader(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const getAllBlogCategory = async () => {
    setLoader(true);
    let res = await getBlogCategories();
    if (res?.data?.data) {
      setBlogCategoryData(res.data.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    getAllBlogCategory();
  }, []);

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const getProperty = (row, columnId) => {
      switch (columnId) {
        case "Name":
          return row?.title?.toLowerCase() || "";

        case "Status":
          return row?.status || "";

        default:
          return "";
      }
    };

    const a = getProperty(rowA, columnId);
    const b = getProperty(rowB, columnId);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Name"),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {" "}
          {row.status ? (
            <label className="label label-success m-0">Active</label>
          ) : (
            <label className="label label-danger m-0">Inactive</label>
          )}
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Status"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.editBlogCategories ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/blog-category/edit/${row._id}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success "
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={""}
                    type="button"
                    className="btn-link btn-icon btn btn-success disabled"
                    disabled
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            )}
            {currentUserRole?.deleteBlogCategories ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    onClick={() => showModel(row._id)}
                    type="button"
                    className="btn-link btn-icon btn btn-danger"
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    type="button"
                    className="btn-link btn-icon btn btn-danger disabled"
                    disabled
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),

      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(selectedOption, text);
  };

  const filterSearchDatatable = (text, searchText) => {
    const filteredItemsResult = blogCategoryData.filter((data) => {
      if (text) {
        if (
          data.status.toString().toLowerCase().includes(text.toLowerCase()) &&
          data?.title.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return data;
        }
      } else if (data?.title.toLowerCase().includes(searchText.toLowerCase())) {
        return data;
      }
      return undefined;
    });
    setFilteredItems([...filteredItemsResult]);
    setSelectedOption(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...blogCategoryData]);
    setSelectedOption("");
  };

  useEffect(() => {
    if (blogCategoryData) {
      setFilteredItems([...blogCategoryData]);
    }
  }, [blogCategoryData]);

  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteBlogCategory}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5 blog-category-admin-tablelayout">
          <Container fluid>
            <Row>
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with title...
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Filter with status...
                          </Form.Label>
                          <select
                            value={selectedOption}
                            onChange={(e) =>
                              filterSearchDatatable(e.target.value, filterText)
                            }
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">Blog categories</h4>
                  {currentUserRole?.addBlogCategories ? (
                    <div className="btn-add">
                      <Link
                        className="float-sm-right btn-filled d-flex align-items-center"
                        to="/blog-category/add"
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Blog Category
                      </Link>
                    </div>
                  ) : (
                    <div className="btn-add">
                      <Button
                        className="float-sm-right btn-filled d-flex align-items-center disabled"
                        disabled
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Blog Category{" "}
                      </Button>
                    </div>
                  )}
                </div>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Index;
