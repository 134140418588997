import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getLogs, deleteLogsById } from "../../actions/LogsAction";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";

import moment from "moment-timezone";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const Index = () => {
  const [logData, setLogData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const hideModel = () => {
    setDelete(false);
  };
  const deleteLogs = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteLogsById(deleteId);
    if (res.data.success) {
      toast.success(res.data.message);
      getAllBlog();
    }
    setLoader(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const getAllBlog = async () => {
    setLoader(true);
    let res = await getLogs();
    if (res?.data?.data) {
      setLogData(res.data.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    getAllBlog();
  }, []);

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.title.toLowerCase();
    const b = rowB.title.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  // const convertDate = (dateString) => {
  //   const dateObject = new Date(dateString);
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return dateObject.toLocaleDateString("en-US", options);
  // };

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("M-D-YYYY, h:mm A");
  };

  const columns = [
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Name",
      selector: (row) => row.userName,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Role",
      selector: (row) => row.Role,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "createdAt",
      selector: (row) => convertDate(row.createdAt),
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.viewLogs ? (
              !(row._id === currentUserRole?._id) ? (
                <li className="d-inline-block align-top">
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                    placement="left"
                  >
                    <Link
                      to={`/view-log/${row._id}`}
                      type="button"
                      className="btn-link btn-icon btn btn-success "
                    >
                      <FaEye />
                    </Link>
                  </OverlayTrigger>
                </li>
              ) : (
                <button
                  type="button"
                  className="btn-link btn-icon btn btn-success disabled"
                  disabled
                >
                  <FaEye />
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn-link btn-icon btn btn-success disabled"
                disabled
              >
                <FaEye />
              </button>
            )}
            {currentUserRole?.deleteLogs ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    onClick={() => showModel(row._id)}
                    type="button"
                    className="btn-link btn-icon btn btn-danger"
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    type="button"
                    className="btn-link btn-icon btn btn-danger disabled"
                    disabled
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),

      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(text);
  };

  const filterSearchDatatable = (text) => {
    const filteredItemsResult = logData.filter((data) => {
      if (text) {
        if (
          data?.Role?.toLowerCase().includes(text.toLowerCase()) ||
          data?.email?.toLowerCase().includes(text.toLowerCase()) ||
          data?.action?.toLowerCase().includes(text.toLowerCase())
        ) {
          return data;
        }
      }
      return undefined;
    });
    if (!text) {
      setFilteredItems([...logData]);
    } else {
      setFilteredItems([...filteredItemsResult]);
    }
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...logData]);
  };

  useEffect(() => {
    if (logData) {
      setFilteredItems([...logData]);
    }
  }, [logData]);

  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteLogs}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with title...
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Index;
