import React, { useEffect, useLayoutEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Footer from "../components/Footers/AdminFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { setAdmin } from "../redux/admin/adminActions";
import { useDispatch, useSelector } from "react-redux";
import routes from "../routes";
import Login from "../views/Login";

import { useState } from "react";
import PermissionDenied from "../permissionDenied/PermissionDenied";

let flag = false;
const Admin = ({ title, children, permissionName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = location;

  const parts = pathname.split("/");

  if (
    routes.map((route, index) => `${route.path}`).includes(pathname)
    // parts[1] == "view-consultation"
  ) {
  } else {
    const page =
      localStorage.getItem("ConsultationPage") ||
      localStorage.getItem("PaidPrescriptionPage") ||
      localStorage.getItem("RefillPage");

    localStorage.removeItem(`${page}`);

    // localStorage.removeItem("limit");
    localStorage.removeItem("searchText");
  }

  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin?.role;
  const [isAllow, setIsAllow] = useState(false);

  useLayoutEffect(() => {
    if (!localStorage.getItem("adminToken")) {
      navigate("/");
      title = "Login";
    } else {
      let user = localStorage.getItem("adminInfo");
      dispatch(setAdmin(JSON.parse(user)));
    }
  }, [dispatch, navigate]);

  useLayoutEffect(() => {
    if (title) {
      document.title = "Admin | " + title;
    } else {
      document.title = "ThePulseCareMD";
    }
  }, [title]);

  useLayoutEffect(() => {
    if (localStorage.getItem("adminToken")) {
      flag = true;
      if (currentUserRole) {
        if (currentUserRole[permissionName]) {
          setIsAllow(true);
        } else {
          setIsAllow(false);
        }
      }
    }
  }, [location, currentUserRole, permissionName]);

  return (
    <>
      {flag ? (
        <div className={`wrapper`}>
          <Sidebar permissionName={permissionName} />
          <div id="main-panel" className="main-panel">
            <AdminNavbar />
            {isAllow ? (
              <div className="content">{children}</div>
            ) : (
              <PermissionDenied />
            )}
            <Footer />
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Admin;
