import React, { useEffect, useRef } from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const FormBuilder = ({ onDataChange, hit }) => {
  const fb = useRef();
  var fields = [];

  var replaceFields = [];

  var actionButtons = [];

  var templates = {};

  // test disabledAttrs
  var disabledAttrs = ["access", "other"];

  var inputSets = [
    {
      label: "Treatment Options",
      name: "treatment_option", // optional
      fields: [
        {
          type: "radio-group", //checkbox-group
          required: true,
          label: "Select applicable treatment option",
          className: "treatment_options",
          name: "treatment_options",
          values: [
            {
              label: "Option 1",
              value: "",
              selected: true,
            },
            {
              label: "Option 2",
              value: "",
            },
          ],
        },
      ],
    },
    {
      label: "Tests Options",
      name: "tests_options", // optional
      tooltip: "tests_options",
      fields: [
        {
          type: "checkbox-group", //checkbox-group
          required: true,
          label: "Select Preferred Tests",
          className: "tests_options",
          name: "tests_options",

          values: [
            {
              label: "Option 1",
              value: "",
              selected: true,
            },
            {
              label: "Option 2",
              value: "",
            },
          ],
        },
      ],
    },
  ];
  var typeUserDisabledAttrs = {
    autocomplete: ["access"],
  };

  var typeUserAttrs = {
    text: {
      className: {
        label: "Class",
        options: {
          "red form-control": "Red",
          "green form-control": "Green",
          "blue form-control": "Blue",
        },
        style: "border: 1px solid red",
      },
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    textarea: {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    select: {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    "checkbox-group": {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    "radio-group": {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    date: {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
    number: {
      autoPopulate: {
        label: "Auto Populate",
        type: "checkbox",
        checked: "",
      },
      showAlertOnValue: {
        label: "Show Alert",
        type: "checkbox",
        checked: "",
      },
      alertValue: {
        label: "Enter value to restrict user.",
        type: "text",
        value: "",
      },
      alertMessageTxt: {
        label: "Enter Alert Message",
        type: "text",
        value: "",
      },
    },
  };
  // Register Event onAdd
  var typeUserEvents = {
    text: {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    textarea: {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    select: {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    "checkbox-group": {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    "radio-group": {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    date: {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
    number: {
      onadd: function (fld) {
        fixCheckedPropForField(fld, "autoPopulate");
        fixCheckedPropForField(fld, "showAlertOnValue");
      },
    },
  };

  function fixCheckedPropForField(fld, fieldName) {
    // Retrieve the Checkbox as a jQuery object
    var $checkbox = $(".fld-" + fieldName, fld);

    // According to the value of the attribute "value", check or uncheck
    if ($checkbox.val() === "true") {
      $checkbox.attr("checked", true);
    } else {
      $checkbox.attr("checked", false);
    }
  }
  var fbOptions = {
    subtypes: {
      text: ["datetime-local"],
      paragraph: ["a"],
    },

    onSave: function (e, formData) {},

    stickyControls: {
      enable: true,
    },
    sortableControls: true,
    fields: fields,
    templates: templates,
    inputSets: inputSets,
    typeUserDisabledAttrs: typeUserDisabledAttrs,
    typeUserAttrs: typeUserAttrs,
    typeUserEvents: typeUserEvents,
    disableInjectedStyle: false,
    actionButtons: actionButtons,
    disableFields: ["autocomplete", "file", "button"],
    replaceFields: replaceFields,
    controlOrder: [
      "tests_options",
      "treatment_option",
      "text",
      "textarea",
      "paragraph",
      "anchor",
      "header",
      "checkbox-group",
      "radio-group",
      "select",
    ],
    disabledFieldButtons: {
      text: ["copy"],
    },
    // controlPosition: 'left'
    disabledAttrs,
  };
  useEffect(() => {
    if ($("#fb-editor .form-wrap.form-builder ").length === 0) {
      onDataChange($(fb.current).formBuilder(fbOptions));
    }
    // eslint-disable-next-line
  }, [hit]);

  return (
    <>
      <div id="fb-editor" ref={fb}></div>
    </>
  );
};

export default FormBuilder;
