import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getAllConsultation = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/appointment/get", data));
const getConsultationById = (id) =>
  PromiseHandler(apiHelper("get", `/api/admin/appointment/get/${id}`));
const addOrUpdatePrescription = (userId, appointmentId, data) =>
  PromiseHandler(
    apiHelper(
      "post",
      `/api/admin/prescription/send/${appointmentId}/${userId}`,
      data
    )
  );
const getAllPrescription = (data) =>
  PromiseHandler(
    apiHelper("post", "/api/admin/appointment/get/prescription-refill", data)
  );
const getAllPaidPrescriptionNotSent = (data) =>
  PromiseHandler(
    apiHelper(
      "post",
      "/api/admin/appointment/get/paid-prescription-Not-Sent",
      data
    )
  );

export {
  getAllConsultation,
  getConsultationById,
  addOrUpdatePrescription,
  getAllPrescription,
  getAllPaidPrescriptionNotSent,
};
