import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  // Table
} from "react-bootstrap";
import { RiRefreshFill } from "react-icons/ri";

import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { FaEye, FaEdit, FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { getAllConsultation } from "../../actions/consultationAction";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import _debounce from "lodash/debounce";
import DataTable, { createTheme } from "react-data-table-component";
const paginationRowsPerPageOptions = [10, 20, 30];

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

// const firstPagesTheme = createTheme("first-pages", {
//   // Define your custom styles for the first four pages
//   text: {
//     primary: "black",
//     secondary: "grey",
//   },
//   background: {
//     default: "white",
//   },
// });

// const lastPagesTheme = createTheme("last-pages", {
//   // Define your custom styles for the last three pages
//   text: {
//     primary: "white",
//     secondary: "grey",
//   },
//   background: {
//     default: "black",
//   },
// });

const customTheme = {
  // title: {
  //   fontSize: "22px",
  //   fontColor: "blue",
  // },
  // header: {
  //   fontSize: "16px",
  //   fontColor: "green",
  // },
  // rows: {
  //   fontSize: "14px",
  //   fontColor: "black",
  //   borderColor: "lightgray",
  // },
  // pagination: {
  //   fontSize: "14px",
  //   fontColor: "black",
  //   backgroundColor: "lightgray",
  //   button: {
  //     backgroundColor: "white",
  //     hoverBackgroundColor: "lightblue",
  //     fontColor: "black",
  //     hoverFontColor: "white",
  //   },
  // },
};
const Consultation = () => {
  const [consultationsData, setConsultationData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [filterText, setFilterText] = useState("");
  const [debouncedText, setDebouncedText] = useState(filterText);
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportCsv, setExportCsv] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sort, setSort] = useState();
  const [direction, setDirection] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [notGreaterStartDate, SetNotGreaterStartDate] = useState();
  const navigate = useNavigate();

  const refreshTable = () => {
    localStorage.setItem(
      "page",
      localStorage.getItem("page") ? localStorage.getItem("page") : 1
    );
    localStorage.setItem(
      "ConsultationLimit",
      localStorage.getItem("ConsultationLimit")
        ? localStorage.getItem("ConsultationLimit")
        : 10
    );

    getConsultations();
  };

  // For handling payment status filtering based on URL query parameter .
  const location = useLocation();
  const { pathname } = location;

  window?.localStorage.setItem("prevPath", pathname);

  const searchParams = new URLSearchParams(location.search);
  // Set initial payment status based on URL query parameter
  const isPaidParam = searchParams.get("isPaid");
  const initialPaymentStatus =
    isPaidParam === "notpaid" ? "unpaid" : isPaidParam;
  const [selectedOptionPayment, setSelectedOptionPayment] =
    useState(initialPaymentStatus);

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("M-D-YYYY, h:mm A");
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (pathname === "/consultations") {
        const isPaid = searchParams.get("isPaid");
        if (isPaid === "paid") {
          setSelectedOptionPayment("paid");
        } else if (isPaid === "notpaid") {
          setSelectedOptionPayment("unpaid");
        } else if (isPaid === "declined") {
          setSelectedOptionPayment("declined");
        }
      }
      setLoader(false);
    };

    fetchData();
  }, [isPaidParam, selectedOptionPayment, pathname]);

  useLayoutEffect(() => {
    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
     
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("page");
  
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handlePageChange = (page) => {
    localStorage.setItem("page", page);
    getConsultations(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    localStorage.setItem("ConsultationLimit", newPerPage);
    localStorage.setItem("page", page);

    getConsultations(page, newPerPage);
    setPerPage(newPerPage);
  };

  const debouncedFilterDatatable = _debounce((text) => {
    setDebouncedText(text.trim());
  }, 500);

  const getConsultations = async (page, limit = perPage) => {
    setLoader(true);
    if (startDate && endDate && startDate > endDate) {
      SetNotGreaterStartDate("Start Date cannot be greater than End Date");
      setLoader(false);
      return;
    } else {
      SetNotGreaterStartDate(null);
    }

    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
      
    }

    var storedPage = localStorage.getItem("page");
    var storedLimit = localStorage.getItem("ConsultationLimit");

    if (storedPage === undefined || storedPage === null) {
      page = 1;
    } else {
      page = parseInt(storedPage);
    }
    if (storedLimit === undefined || storedLimit === null) {
      limit = 10;
    } else {
      limit = parseInt(storedLimit);
    }
    window.localStorage.setItem("currentURL", pathname);

    setPerPage(
      localStorage.getItem("ConsultationLimit")
        ? localStorage.getItem("ConsultationLimit")
        : limit
    );

    setCurrentPage(page);
    setFilterText(
      localStorage.getItem("ConsultationsearchText")
        ? localStorage.getItem("ConsultationsearchText")
        : debouncedText
    );

    setSelectedOptionPayment(
      localStorage.getItem("ConsultationpaymentStatus")
        ? localStorage.getItem("ConsultationpaymentStatus")
        : selectedOptionPayment
    );
    setSelectedOption(
      localStorage.getItem("ConsultationprescriptionStatus")
        ? localStorage.getItem("ConsultationprescriptionStatus")
        : selectedOption
    );

    let obj = {
      startDate,
      endDate,
      page,
      limit,
      exportCsv,
      isReviewed: localStorage.getItem("ConsultationprescriptionStatus")
        ? localStorage.getItem("ConsultationprescriptionStatus")
        : selectedOption,
      isPaid: localStorage.getItem("ConsultationpaymentStatus")
        ? localStorage.getItem("ConsultationpaymentStatus")
        : selectedOptionPayment,
      filterText: localStorage.getItem("ConsultationsearchText")
        ? localStorage.getItem("ConsultationsearchText")
        : debouncedText,
      sort,
      direction,
    };

    let res = await getAllConsultation(obj);
    if (res?.data?.data?.appointments) {
      setTotalRows(res?.data?.data?.pagination?.total);
      setConsultationData(res?.data?.data?.appointments);
      if (res?.data?.downloadLink) {
        downloadFile(res?.data?.downloadLink);
        setExportCsv(0);
      }
    }
    setLoader(false);
  };

  function downloadFile(fileUrl) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleSort = (column, sortDirection) => {
    setSort(column.sortField);
    setDirection(sortDirection);
  };

  const columns = [
    {
      name: "ID",
      selector: (row, index) => {
        const counter =
          ((isNaN(currentPage) ? 1 : currentPage) - 1) * perPage + index + 1;
        return counter;
      },
      minWidth: "50px",
      sortable: true,
      sortField: "ID",
    },
    {
      name: "Disease",
      selector: (row) => row.diseaseName,
      minWidth: "140px",
      sortable: true,
      sortField: "diseaseName",
    },
    {
      name: "Form Type",
      selector: (row) => (
        <>
          <div className="form-type">
            <label className="label label-success m-0">
              {row?.formType?.formType === "consultationForm"
                ? "Consultation Form"
                : row?.formType?.formType === "prescriptionRefill"
                ? "Prescription Refill"
                : row?.formType?.formType}
            </label>
          </div>
        </>
      ),
      minWidth: "160px",
      sortable: true,
      sortField: "formType",
    },

    {
      name: "Patient Name",
      selector: (row) => row?.firstName + " " + row?.lastName,
      minWidth: "160px",
      sortable: true,
      sortField: "firstName",
    },

    {
      name: "Created At",
      selector: (row) => convertDate(row?.createdAt),
      minWidth: "220px",
      sortable: true,
      sortField: "createdAt",
    },

    {
      name: "Discount",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.discount?.isUsed ? (
              <label className="label label-success m-0">
                <i className="fa fa-check" aria-hidden="true"></i>
              </label>
            ) : (
              <label className="label label-danger m-0">
                <i className="fa fa-times" aria-hidden="true"></i>
              </label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },

    {
      name: "Promo",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.promoCode?.isUsed ? (
              <label className="label label-success m-0">
                <i className="fa fa-check" aria-hidden="true"></i>
              </label>
            ) : (
              <label className="label label-danger m-0">
                <i className="fa fa-times" aria-hidden="true"></i>
              </label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },


    {
      name: "platform",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.isAppAppointment ? (
              <label className="label label-success m-0">
                {/* <i className="fa fa-check" aria-hidden="true"></i> */}
                App
              </label>
            ) : (
              <label className="label label-danger m-0">
                {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                Web
              </label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },

    {
      name: "Payment Status",
      selector: (row) => (
        <>
          {" "}
          {row.isPaid === "paid" ? (
            <label className="label label-success m-0">Paid</label>
          ) : row.isPaid === "unpaid" ? (
            <label className="label label-warning m-0 text-white bg-warning">
              Not Paid
            </label>
          ) : (
            row.isPaid === "declined" && (
              <label className="label label-danger m-0">Declined</label>
            )
          )}
        </>
      ),
      minWidth: "180px",
      sortable: true,
      sortField: "isPaid",
    },

    {
      name: "Prescription Status",
      selector: (row) => (
        <>
          {" "}
          {row.isReviewed ? (
            <label className="label label-success m-0">Prescription Sent</label>
          ) : (
            <label className="label label-danger m-0">
              Prescription Not Sent
            </label>
          )}
        </>
      ),
      minWidth: "210px",
      sortable: true,
      sortField: "isReviewed",
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.viewConsultationsDetails ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/view-consultation/${row._id}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success "
                  >
                    <FaEye />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                  placement="left"
                >
                  <span className="btn-link btn-icon btn btn-success " disabled>
                    <FaEye />
                  </span>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),
      sortable: false,
    },
  ];

  const handleSelectChangePayment = (e) => {
    let val = e.target.value;
    setSelectedOptionPayment(val);
    window.localStorage.setItem("ConsultationpaymentStatus", val);

    filterSearchDatatable();
  };

  const handleSelectChangePrescription = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
    window.localStorage.setItem("ConsultationprescriptionStatus", val);
    filterSearchDatatable();
  };
  const filterDatatable = (text) => {
    window.localStorage.setItem("ConsultationsearchText", text);
    setFilterText(text);
  };

  const filterSearchDatatable = (text) => {
    if (text === undefined) {
      text = "";
    }

    const filteredItemsResult = consultationsData?.filter((data) => {
      const isPaymentMatch =
        !selectedOptionPayment || data?.isPaid === selectedOptionPayment;

      const isPrescriptionMatch =
        !selectedOption || data?.isReviewed === (selectedOption === "true");

      const isSearchMatch = dataMatchesSearch(data, text);

      return isPaymentMatch && isPrescriptionMatch && isSearchMatch;
    });

    setFilteredItems([...filteredItemsResult]);
  };

  const dataMatchesSearch = (data, text) => {
    text = text && text.trim() !== "" ? text : filterText;
    let findName = data?.firstName + " " + data?.lastName;
    return (
      !text ||
      data?.diseaseName?.toLowerCase().includes(text.toLowerCase()) ||
      data?.state?.title
        ?.toString()
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      data?.formType?.formType?.toString().includes(text.toLowerCase()) ||
      data?.email?.toString().includes(text.toLowerCase()) ||
      findName?.toString().toLowerCase().includes(text.toLowerCase()) ||
      data?.state?.title
        ?.toString()
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      data?.isPaid?.toString().toLowerCase().includes(text.toLowerCase())
    );
  };

  const resetFilter = () => {
    window?.localStorage.removeItem("ConsultationsearchText");
    setDebouncedText("");
    window?.localStorage.removeItem("ConsultationpaymentStatus");
    window?.localStorage.removeItem("ConsultationprescriptionStatus");

    navigate("/consultations");
    setFilterText("");
    setFilteredItems([...consultationsData]);
    setSelectedOption("");
    setStartDate("");
    setEndDate("");
    setExportCsv(0);
    setSelectedOptionPayment("");
  };

  useEffect(() => {
    if (consultationsData) {
      setFilteredItems([...consultationsData]);
    }
  }, [consultationsData]);

  useEffect(() => {
    debouncedFilterDatatable(filterText);
    return () => debouncedFilterDatatable.cancel(); // Cleanup debounce on unmount
  }, [debouncedFilterDatatable]);

  useEffect(() => {
    filterSearchDatatable();
    getConsultations();
  }, [
    selectedOption,
    selectedOptionPayment,
    debouncedText,
    endDate,
    startDate,
    exportCsv,
    sort,
    direction,
  ]);

  const generatePageNumbers = (currentPage = 1, totalPages = 10) => {
    currentPage = isNaN(currentPage) ? 1 : currentPage;

    const maxPages = 4;
    const windowStart = Math.max(currentPage - Math.floor(maxPages / 2), 1);
    const windowEnd = Math.min(windowStart + maxPages - 1, totalPages);
    return Array.from(
      { length: windowEnd - windowStart + 1 },
      (_, i) => i + windowStart
    );
  };
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="pt-3 pt-md-5">
        <Container fluid>
          <Row>
            {/* Start of filter container */}
            <Col md="12">
              <div
                className={`custom-collapse ${
                  isOpen ? "open" : ""
                }desktop_none mobile_block`}
              >
                <div
                  className="custom-collapse-header"
                  onClick={toggleCollapse}
                >
                  <span>
                    Filter
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </span>
                  <span className={`arrow ${isOpen ? "open" : ""}`}></span>
                </div>
                <div
                  className="custom-collapse-body"
                  style={{ maxHeight: isOpen ? "1000px" : "0" }}
                >
                  <div className={`collapse-content ${isOpen ? "open" : ""}`}>
                    <Card className="filter-card card ">
                      <Card.Header>
                        <div className="d-flex align-items-center justify-content-between table-head">
                          <Card.Title className="text-white" as="h4">
                            Filter
                          </Card.Title>
                        </div>
                      </Card.Header>

                      <Card.Body>
                        <Row>
                          <Col xl={4} sm={6} className="search-wrap">
                            <Form.Group
                              className={isPaidParam ? "disablefields" : ""}
                            >
                              <Form.Label className="d-block mb-2 text-white">
                                Search with title
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="search"
                                disabled={isPaidParam ? "disabled" : ""}
                                placeholder="Search"
                                value={filterText}
                                onChange={(e) =>
                                  filterDatatable(e.target.value)
                                }
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          {}
                          <Col xl={4} sm={6} className="search-wrap">
                            <Form.Group
                              className={isPaidParam ? "disablefields" : ""}
                            >
                              <Form.Label className="d-block mb-2 text-white">
                                Filter with status
                              </Form.Label>
                              <select
                                disabled={isPaidParam ? "disabled" : ""}
                                value={selectedOption}
                                onChange={(e) =>
                                  handleSelectChangePrescription(e)
                                }
                              >
                                <option value="">Select</option>
                                <option value="true">Prescription Sent</option>
                                <option value="false">
                                  Prescription Not Sent
                                </option>
                              </select>
                            </Form.Group>
                          </Col>
                          <Col xl={4} sm={6} className="search-wrap">
                            <Form.Group
                              className={isPaidParam ? "disablefields" : ""}
                            >
                              <Form.Label className="d-block mb-2 text-white">
                                Filter with payment status
                              </Form.Label>
                              <select
                                disabled={isPaidParam ? "disabled" : ""}
                                value={selectedOptionPayment}
                                onChange={(e) => handleSelectChangePayment(e)}
                              >
                                <option value="">Select</option>
                                <option value="paid">Paid</option>
                                <option value="unpaid">Not Paid</option>
                                <option value="declined">Declined</option>
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="row">
                          <div className="col-xl-9">
                            <div className="datepikker-btn d-flex flex-wrap datapiker-custom-alignment">
                              <Form.Group className={"mr-2"}>
                                <Form.Label className="d-block mb-2 text-white">
                                  Start Date:
                                </Form.Label>
                                <div
                                  className={
                                    isPaidParam
                                      ? "date-picker-container form-controll disablefields"
                                      : "date-picker-container form-controll"
                                  }
                                >
                                  <DatePicker
                                    disabled={isPaidParam ? "disabled" : ""}
                                    showIcon
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    maxDate={new Date()}
                                    icon="fa fa-calendar"
                                    placeholderText="mm/dd/yyyy"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group className="mr-2">
                                <Form.Label className="d-block mb-2 text-white">
                                  End Date:
                                </Form.Label>
                                <div
                                  className={
                                    isPaidParam
                                      ? "date-picker-container form-controll disablefields"
                                      : "date-picker-container form-controll"
                                  }
                                >
                                  <DatePicker
                                    disabled={isPaidParam ? "disabled" : ""}
                                    showIcon
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    maxDate={new Date()}
                                    icon="fa fa-calendar"
                                    placeholderText="mm/dd/yyyy"
                                  />
                                </div>
                                <span style={{ color: "#ff777f" }}>
                                  {notGreaterStartDate}
                                </span>
                              </Form.Group>
                              <Form.Group>
                                <label className="d-none d-sm-block mb-2 form-label">
                                  &nbsp;
                                </label>
                                <div className="d-flex  filter-btns-holder">
                                  <button
                                    type="button"
                                    className="outline-button w-auto"
                                    onClick={resetFilter}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-xl-3 d-flex justify-content-end ">
                            <div
                              className={
                                isPaidParam ? "disablefields" : "export-btn"
                              }
                            >
                              <Form.Group className="mr-3">
                                <label className="d-none d-sm-block mb-2 form-label">
                                  &nbsp;
                                </label>
                                <div className="d-flex  filter-btns-holder justify-content-end">
                                  <button
                                    disabled={isPaidParam ? "disabled" : ""}
                                    type="button"
                                    className="outline-button w-auto export-btn"
                                    onClick={() => setExportCsv(1)}
                                  >
                                    Export
                                  </button>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <Card className="filter-card card mobile_none">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title className="text-white" as="h4">
                      Filter
                    </Card.Title>
                  </div>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group
                        className={isPaidParam ? "disablefields" : ""}
                      >
                        <Form.Label className="d-block mb-2 text-white">
                          Search with title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          disabled={isPaidParam ? "disabled" : ""}
                          placeholder="Search"
                          value={filterText}
                          onChange={(e) => filterDatatable(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {}
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group
                        className={isPaidParam ? "disablefields" : ""}
                      >
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with status
                        </Form.Label>
                        <select
                          disabled={isPaidParam ? "disabled" : ""}
                          value={selectedOption}
                          onChange={(e) => handleSelectChangePrescription(e)}
                        >
                          <option value="">Select</option>
                          <option value="true">Prescription Sent</option>
                          <option value="false">Prescription Not Sent</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group
                        className={isPaidParam ? "disablefields" : ""}
                      >
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with payment status
                        </Form.Label>
                        <select
                          disabled={isPaidParam ? "disabled" : ""}
                          value={selectedOptionPayment}
                          onChange={(e) => handleSelectChangePayment(e)}
                        >
                          <option value="">Select</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Not Paid</option>
                          <option value="declined">Declined</option>
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-xl-9">
                      <div className="datepikker-btn d-flex flex-wrap datapiker-custom-alignment">
                        <Form.Group className={"mr-2"}>
                          <Form.Label className="d-block mb-2 text-white">
                            Start Date:
                          </Form.Label>
                          <div
                            className={
                              isPaidParam
                                ? "date-picker-container form-controll disablefields"
                                : "date-picker-container form-controll"
                            }
                          >
                            <DatePicker
                              disabled={isPaidParam ? "disabled" : ""}
                              showIcon
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              maxDate={new Date()}
                              icon="fa fa-calendar"
                              placeholderText="mm/dd/yyyy"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mr-2">
                          <Form.Label className="d-block mb-2 text-white">
                            End Date:
                          </Form.Label>
                          <div
                            className={
                              isPaidParam
                                ? "date-picker-container form-controll disablefields"
                                : "date-picker-container form-controll"
                            }
                          >
                            <DatePicker
                              disabled={isPaidParam ? "disabled" : ""}
                              showIcon
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              maxDate={new Date()}
                              icon="fa fa-calendar"
                              placeholderText="mm/dd/yyyy"
                            />
                          </div>
                          <span style={{ color: "#ff777f" }}>
                            {notGreaterStartDate}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button w-auto"
                              onClick={resetFilter}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-xl-3 d-flex justify-content-end">
                      <div
                        className={
                          isPaidParam ? "disablefields" : "export-btn "
                        }
                      >
                        <Form.Group className="mr-3">
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder justify-content-end">
                            <button
                              disabled={isPaidParam ? "disabled" : ""}
                              type="button"
                              className="outline-button w-auto export-btn"
                              onClick={() => setExportCsv(1)}
                            >
                              Export
                            </button>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                <h4 className="card-title">Patient Consultations Listing</h4>
                <div>
                  <div className="d-flex datatable-title filter-btns-holder">
                    <button
                      onClick={refreshTable}
                      type="button"
                      className="outline-button w-auto"
                    >
                      <RiRefreshFill
                        style={{ cursor: "pointer" }}
                        color="#fff"
                        size={28}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                className="react-datatable"
                progressPending={loader}
                paginationPerPage={perPage}
                paginationServer
                progressComponent={<FullPageLoader />}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                paginationComponent={() => (
                  <div className="pagination">
                    rows per page :
                    <select
                      value={perPage}
                      onChange={(e) =>
                        handlePerRowsChange(
                          Number(e.target.value) == NaN
                            ? 1
                            : Number(e.target.value)
                        )
                      }
                    >
                      {paginationRowsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div className="total_pages">
                      {" "}
                      {currentPage && currentPage > 1
                        ? perPage * ((isNaN(currentPage) ? 1 : currentPage) - 1)
                        : 1}{" "}
                      -{(isNaN(currentPage) ? 1 : currentPage) * perPage} of{" "}
                      {totalRows}
                    </div>
                    <div>
                      <button
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      >
                        &lt;&lt;
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        &lt;
                      </button>
                      {generatePageNumbers(
                        currentPage,
                        Math.ceil(totalRows / perPage)
                      ).map((page) => (
                        <button
                          key={page}
                          onClick={() => handlePageChange(page)}
                          className={currentPage === page ? "active" : ""}
                        >
                          {page}
                        </button>
                      ))}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage === Math.ceil(totalRows / perPage)
                        }
                      >
                        <span>&gt; </span>
                      </button>
                      <button
                        onClick={() =>
                          handlePageChange(Math.ceil(totalRows / perPage))
                        }
                        disabled={
                          currentPage === Math.ceil(totalRows / perPage) ||
                          Math.ceil(totalRows / perPage) === 0
                        }
                      >
                        <span>&gt;&gt;</span>
                      </button>
                    </div>
                  </div>
                )}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Consultation;
