/**
 * Edit and manage promo codes for a promotional campaign.
 *
 * @param {string | Date} startDate - The start date of the promotional campaign.
 * @param {string | Date} endDate - The end date of the promotional campaign.
 * @param {number} repetitions - The number of times the promo code can be used.
 * @param {string} formType - The type of form used for code redemption.
 * @param {string[]} selectedUsers - An array of selected users for the promotion.
 * @param {string} discountType - The type of discount applied (e.g., percentage, fixed amount).
 * @param {number} discountAmount - The amount or percentage of the discount.
 * @returns {string} The generated promo code string.
 *
 * Author: Khaliq Mehmood
 * Date:   22 sep, 2023
 */
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  FormGroup,
  Breadcrumb,
} from "react-bootstrap";

import { getUserByName } from "../../actions/userActions";
import { addPromoCode } from "../../actions/promoCodePageAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  getAllPrescription,
  getConsultationAllActive,
} from "../../actions/refillPrescriptionAction";

/**
 * Manages and validates promo codes for a promotional campaign.
 *
 * @param {string | Date} startDate - The start date of the promotional campaign.
 * @param {string | Date} endDate - The end date of the promotional campaign.
 * @param {number} repetitions - The number of times the promo code can be used.
 * @param {string} formType - The type of form used for code redemption.
 * @param {string[]} selectedUsers - An array of selected users for the promotion.
 * @param {string} discountType - The type of discount applied (e.g., percentage, fixed amount).
 * @param {number} discountAmount - The amount or percentage of the discount.
 * @returns {string} The generated promo code string.
 *
 * @throws {Error} If the input parameters are invalid or the promo code cannot be generated.
 *
 */

const partnerDiscountSchema = Yup.object().shape({
  startDate: Yup.string()
    .required("Start Date is required")
    .test("start-date", "Start date must be before end date", function (value) {
      const endDate = this.parent.endDate;
      return new Date(value) <= new Date(endDate);
    }),
  endDate: Yup.string()
    .required("Start Date is required")
    .test(
      "end-date",
      "End date must be greater then start date",
      function (value) {
        const startDate = this.parent.startDate;
        return new Date(value) >= new Date(startDate);
      }
    ),
  name: Yup.string().required("Token name is required"),
  noOfTime: Yup.number()
    .required("Count is required")
    .positive("minimum value is One"),
  allUsers: Yup.boolean(),
  status: Yup.boolean().required("Status is required"),
  code: Yup.string().required("Code is required"),
  discountType: Yup.string().required("discountType is required"),
  discountValue: Yup.number().when("discountType", {
    is: "percentage",
    then: Yup.number()
      .typeError("Discount Value must be a number")
      .required("Discount Value is required")
      .test(
        "is-100-percent",
        "Discount Value must be 100%  for percentage discount type",
        function (value) {
          return value < 100;
        }
      )
      .positive(
        "Discount Value must be greater than or equal to 1 for percentage discount type"
      ),
    otherwise: Yup.number()
      .required("Discount Value is required")
      .positive(
        "Discount Value must be greater than or equal to 1 for price discount type"
      ),
  }),
});
const AddPromoCodeForm = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [prescriptionRefill, setPrescriptionRefill] = useState([]);
  const [preceptions, setPrescriptions] = useState([]);
  const [selections, setSelections] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  /**
   * useEffect hook to initialize component tasks on mount.
   * Fetches prescription refills, user data, prescriptions, and generates a promo code.
   * Runs once after initial render.
   */

  useEffect(() => {
    getAllPrescriptionRefill();
    getUsers();
    getAllPrescriptions();
    generatePromoCode();
  }, []);

  /**
   * Generates a 6-digit random promo code and sets it as the "code" field value in the Formik form.
   *
   * @function
   * @returns {void}
   */

  const generatePromoCode = () => {
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    formik.setFieldValue("code", code);
  };

  /**
   * Formik configuration for the promotional campaign form.
   *
   * Manages form state, validation, and submission logic.
   *
   * @type {object}
   */

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      noOfTime: "",
      name: "",
      allUsers: false,
      code: "",
      status: true,
      discountType: "",
      preceptions: {},
      users: [],
      selectedOptions: [],
      discountValue: "",
    },

    validationSchema: partnerDiscountSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};

      if (!values.allUsers && (!selectedUsers || selectedUsers.length === 0)) {
        errors.users = "At least one user is required";
      }
      if (Object.keys(selections).length === 0) {
        errors.preceptions = "At least one preception is required";
      }
      return errors;
    },

    onSubmit: async (values, { resetForm }) => {
      setLoader(true);
      if (!values.allUsers && (!selectedUsers || selectedUsers.length === 0)) {
        formik.setErrors({
          users: "At least one user is required",
        });
        setLoader(false);
        return;
      }

      if (Object.keys(selections).length === 0) {
        formik.setErrors({
          preceptions: "At least one preception is required",
        });
        setLoader(false);
        return;
      }

      formik.values.users = selectedUsers;
      formik.values.preceptions = selections;
      formik.values.selectedOptions = selectedOptions;
      let res = await addPromoCode(values);
      if (res?.data?.success) {
        navigate("/promo");
        toast.success(res.data.message);
        setSelections({});
        setSelectedOptions([]);
        resetForm();
      }
      setLoader(false);
      setSelections({});
      setSelectedOptions([]);
    },
  });

  /**
   * Handles user search by name.
   *
   * @param {string} e - The search query (user's name) entered by the user.
   * @returns {void}
   */

  const handleSearch = async (e) => {
    let res = await getUserByName(e);
    if (res.data.data) {
      setSearchResults(res?.data?.data);
    }
  };

  /**
   * Handles user selection and adds the selected user to the list of selected users.
   *
   * @param {Array} selectedList - The list of currently selected users.
   * @param {Object} selectedItem - The user object selected by the user.
   * @returns {void}
   */

  function onSelect(selectedList, selectedItem) {
    setSelectedUsers([...selectedUsers, selectedItem._id]);
  }

  /**
   * Handles user removal from the selected list and updates the list of selected users.
   *
   * @param {Array} selectedList - The list of currently selected users.
   * @param {Object} removedItem - The user object to be removed from the selected users list.
   * @returns {void}
   */

  function onRemove(selectedList, removedItem) {
    const updatedUsers = selectedUsers.filter(
      (item) => item !== removedItem._id
    );
    setSelectedUsers(updatedUsers);
  }

  /**
   * Fetches all prescription refills from the server.
   *
   * @returns {void}
   */

  const getAllPrescriptionRefill = async () => {
    setLoader(true);
    let res = await getAllPrescription();
    if (res.data.data) {
      setPrescriptionRefill(res.data.data);
    }
    setLoader(false);
  };

  /**
   * Fetches all prescriptions from the server.
   *
   * @returns {void}
   */

  const getAllPrescriptions = async () => {
    setLoader(true);
    let res = await getConsultationAllActive();
    if (res.data.data) {
      setPrescriptions(res.data.data);
    }
    setLoader(false);
  };

  /**
   * Fetches user data by name from the server and updates the search results.
   *
   * @returns {void}
   */

  const getUsers = async () => {
    setLoader(true);
    let res = await getUserByName();
    if (res.data.data) {
      setSearchResults(res.data.data);
    }
    setLoader(false);
  };

  /**
   * Handles toggling medical options.
   *
   * @param {string} option - The medical option to be toggled.
   * @returns {void}
   */

  const handleOptionToggle = (option) => {
    if (selectedOptions.includes(option)) {
      const modifyObje = { ...selections };
      delete modifyObje[option];
      setSelections(modifyObje);
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  /**
   * Handles toggling select all option for a specific form type.
   *
   * @param {string} formType - The type of form for which select all is toggled.
   * @param {Array} selection - The list of options for the specified form type.
   * @returns {void}
   */

  const handleAllSelectionToggle = (formType, selection) => {
    if (selection?.length == selections[formType]?.length) {
      const updatedSelections = { ...selections };
      delete updatedSelections[formType];
      setSelections(updatedSelections);
    } else {
      const arrayOfLabels = selection.map((obj) => obj.label);
      const jsonData = { ...selections };

      if (!jsonData.hasOwnProperty(formType)) {
        jsonData[formType] = arrayOfLabels;
        setSelections(jsonData);
      } else {
        jsonData[formType] = [...arrayOfLabels];
        setSelections(jsonData);
      }
    }
  };

  /**
   * Handles toggling selection for a specific form type.
   *
   * @param {string} formType - The type of form for which selection is toggled.
   * @param {string} selection - The selected item for the specified form type.
   * @returns {void}
   */

  const handleSelectionToggle = (formType, selection) => {
    const jsonData = { ...selections };

    if (!selections.hasOwnProperty(formType)) {
      jsonData[formType] = [];
      setSelections(jsonData);
    }
    if (jsonData[formType].includes(selection)) {
      const updatedData = jsonData[formType].filter((id) => id !== selection);
      const updatedJsonData = { ...jsonData, [formType]: updatedData };
      setSelections(updatedJsonData);
    } else {
      jsonData[formType].push(selection);
      setSelections(jsonData);
    }
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/promo">
                    Promo Codes
                  </Link>
                  <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Add Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formStartDate"
                          >
                            <Form.Label>
                              Start Date <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              className="promo-code-dates"
                              type="Date"
                              name="startDate"
                              min={new Date().toISOString().split("T")[0]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.startDate}
                            />
                            {formik.touched.startDate &&
                            formik.errors.startDate ? (
                              <span
                                className={
                                  formik.errors.startDate ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.startDate}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formEndDate">
                            <Form.Label>
                              End Date <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              className="promo-code-dates"
                              type="Date"
                              name="endDate"
                              min={new Date().toISOString().split("T")[0]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.endDate}
                            />
                            {formik.touched.endDate && formik.errors.endDate ? (
                              <span
                                className={
                                  formik.errors.endDate ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.endDate}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>
                              Name Of Token
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <span
                                className={formik.errors.name ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.name}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formPromoCode"
                          >
                            <Form.Label>Generated Promo Code</Form.Label>
                            <Row>
                              <Col md={4}>
                                <Form.Control
                                  type="text"
                                  name="code"
                                  placeholder="Promo Code"
                                  value={formik?.values?.code}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </Col>
                              <Col md={8}>
                                <Button
                                  className="btn-filled btn btn-primary"
                                  variant="primary"
                                  onClick={generatePromoCode}
                                >
                                  Generate Promo Code
                                </Button>
                              </Col>
                            </Row>
                            {formik.touched.code && formik.errors.code ? (
                              <span
                                className={formik.errors.code ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.code}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formEndDate">
                            <Form.Label>
                              No of times <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="noOfTime"
                              placeholder="Quantity"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.noOfTime}
                            />
                            {formik.touched.noOfTime &&
                            formik.errors.noOfTime ? (
                              <span
                                className={
                                  formik.errors.noOfTime ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.noOfTime}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <FormGroup>
                            <Row>
                              <Col md={4}>
                                <label className="right-label-radio mr-3 mb-2">
                                  All Users
                                  <input
                                    name="userSelection"
                                    type="radio"
                                    checked={formik?.values?.allUsers}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "serchButton",
                                        false
                                      );
                                      formik.setFieldValue(
                                        "allUsers",
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </Col>

                              <Col md={8} className="check-inline d-flex">
                                <label className="right-label-radio mr-3 mb-2">
                                  Specific Users
                                  <input
                                    name="userSelection"
                                    type="radio"
                                    onClick={() => {
                                      formik.setFieldValue("serchButton", true);
                                      formik.setFieldValue("allUsers", false);
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <div className="check">
                                  {!formik.values.serchButton &&
                                    !formik.values.allUsers && (
                                      <span
                                        className={
                                          formik.errors?.users?.trim()
                                            ? ""
                                            : "d-none"
                                        }
                                      >
                                        <label className="pl-1 check text-danger">
                                          At least one check is required
                                        </label>
                                      </span>
                                    )}
                                </div>
                              </Col>
                            </Row>

                            {formik.values.serchButton && (
                              <>
                                {" "}
                                <Col md={4}>
                                  <label className="label-font">
                                    Search Users
                                  </label>
                                </Col>
                                <Multiselect
                                  placeholder="Enter User's Email"
                                  emptyRecordMsg="No Records Found"
                                  options={searchResults}
                                  onSelect={onSelect}
                                  onRemove={onRemove}
                                  onSearch={(searchText) =>
                                    handleSearch(searchText)
                                  }
                                  displayValue="email"
                                />
                              </>
                            )}
                            {formik.values.serchButton &&
                              selectedUsers?.length === 0 && (
                                <span
                                  className={
                                    formik.errors?.users?.trim() ? "" : "d-none"
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    At least one user is required
                                  </label>
                                </span>
                              )}
                          </FormGroup>
                          <FormGroup as={Col} md={12}>
                            <Form.Label>
                              Select Form Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="search_users ml-0">
                              {preceptions.map((option, i) => (
                                <label key={i}>
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleOptionToggle(option.title)
                                    }
                                  />{" "}
                                  {option.title}
                                </label>
                              ))}
                            </div>
                            {Object.keys(selections).length == 0 &&
                              formik.errors.preceptions && (
                                <div className="text-danger pl-1">
                                  {formik.errors.preceptions}
                                </div>
                              )}
                          </FormGroup>
                          <div className="row ">
                            {preceptions.map((option, i) => (
                              <Form.Group
                                className="mb-3 mr-3"
                                controlId="formStartDate"
                              >
                                {selectedOptions?.includes(option.title) && (
                                  <Form.Label>
                                    {option.title}{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                )}
                                {selectedOptions.includes(option.title) && (
                                  <div className="consult_form_content ">
                                    <div className="consult_wrap">
                                      <h4>SELECT ALL</h4>
                                      <input
                                        type="checkbox"
                                        checked={
                                          selections[option?.title]?.length ==
                                          option?.medicalTypes?.length
                                        }
                                        onChange={() =>
                                          handleAllSelectionToggle(
                                            option.title,
                                            option?.medicalTypes
                                          )
                                        }
                                      />
                                    </div>
                                    {selectedOptions.includes(option.title) &&
                                      selectedOptions.includes(option.title) &&
                                      option?.medicalTypes.map(
                                        (innerOption, i, arr) => (
                                          <div key={i} className="consult_wrap">
                                            <h4>{innerOption?.label}</h4>
                                            <input
                                              type="checkbox"
                                              checked={selections[
                                                option?.title
                                              ]?.includes(innerOption?.label)}
                                              onChange={() =>
                                                handleSelectionToggle(
                                                  option?.title,
                                                  innerOption?.label
                                                )
                                              }
                                            />{" "}
                                          </div>
                                        )
                                      )}
                                  </div>
                                )}
                              </Form.Group>
                            ))}
                          </div>{" "}
                          <FormGroup>
                            <Row>
                              <Col md={4}>
                                <label className="label-font">Status</label>
                              </Col>
                              <Col md={8} className="check-inline d-flex">
                                <label className="right-label-radio mr-3 mb-2">
                                  Active
                                  <input
                                    name="status"
                                    defaultChecked
                                    type="radio"
                                    onChange={(e) =>
                                      formik.setFieldValue("status", true)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="right-label-radio mr-3 mb-2">
                                  Inactive
                                  <input
                                    name="status"
                                    type="radio"
                                    onChange={(e) =>
                                      formik.setFieldValue("status", false)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </Col>
                            </Row>
                            {formik.touched.status && formik.errors.status && (
                              <div className="text-danger pl-1">
                                {formik.errors.status}
                              </div>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md={4}>
                                <label className="label-font">
                                  Discount Type
                                </label>
                              </Col>
                              <Col md={8}>
                                <select
                                  name="discountType"
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "discountType",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                >
                                  <option value="">Select Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="price">Price</option>
                                </select>
                              </Col>
                            </Row>
                            {formik.touched.discountValue &&
                              formik.errors.discountValue && (
                                <div className="text-danger pl-1">
                                  {formik.errors.discountValue}
                                </div>
                              )}
                          </FormGroup>
                          {formik.values?.discountType && (
                            <FormGroup>
                              <Row>
                                <Col md={4}>
                                  <label className="label-font">
                                    Discount Value
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      name="discountValue"
                                      step="0.01"
                                      onChange={formik.handleChange}
                                      className="form-control"
                                    />
                                    <div className="input-group-append">
                                      {formik?.values?.discountType ===
                                      "percentage" ? (
                                        <span className="input-group-text">
                                          %
                                        </span>
                                      ) : (
                                        <span className="input-group-text">
                                          $
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicTags"
                          ></Form.Group>
                          <Button className="btn-filled" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default AddPromoCodeForm;
