import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  FormGroup,
  Breadcrumb,
} from "react-bootstrap";
import { addEmailTemplate } from "../../actions/emailTemplateAction";
import { getMedicalConsultationsActive } from "../../actions/medicalConsultationAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { getAllPrescription } from "../../actions/refillPrescriptionAction";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";
// Schema for yup
const emailTemplateSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title must have at least 2 characters")
    .max(100, "Title can't be longer than 100 characters")
    .trim("Spaces are not allowed")
    .required("Title field is required"),
  content: Yup.string()
    .trim("Spaces are not allowed")
    .required("Content field is required"),
  formConsultation: Yup.string().required("Content field is required"),
  formType: Yup.string().required("Content field is required"),
});
const AddPartnerDiscount = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [medicalConsultationData, setMedicalConsultation] = useState({});
  const [prescriptionData, setPrescriptionData] = useState([]);
  const getAllPrescriptionRefill = async () => {
    setLoader(true);
    let res = await getAllPrescription();
    if (res.data.data) {
      setPrescriptionData(res.data.data);
    }
    setLoader(false);
  };
  const getAllMedicalConsultation = async () => {
    setLoader(true);
    let res = await getMedicalConsultationsActive();
    if (res.data.data) {
      setMedicalConsultation(res.data.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    getAllMedicalConsultation();
    getAllPrescriptionRefill();
  }, []);
  const formik = useFormik({
    initialValues: {
      title: "",
      formConsultation: "",
      content: "",
      formType: "consultationForm",
      status: false,
    },
    validationSchema: emailTemplateSchema,
    onSubmit: async (values) => {
      setLoader(true);
      let res = await addEmailTemplate(values);
      if (res?.data?.success) {
        navigate("/email-template");
        toast.success(res.data.message);
      }
      setLoader(false);
    },
  });

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/email-template">
                    Email Template
                  </Link>
                  <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Add Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={1}>
                        <label className="label-font">
                          Form Type <span className="text-danger">*</span>
                        </label>
                      </Col>
                      <Col
                        md={11}
                        className="check-inline d-flex mt-3 flex-column"
                      >
                        <label className="right-label-radio mr-3 mb-2">
                          Consultation Form
                          <input
                            name="formType"
                            value="consultationForm"
                            checked={
                              formik.values?.formType === "consultationForm"
                                ? true
                                : false
                            }
                            className="formType"
                            type="radio"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="right-label-radio mr-3 mb-2">
                          Prescription Refill
                          <input
                            name="formType"
                            value="prescriptionRefill"
                            checked={
                              formik.values?.formType === "prescriptionRefill"
                                ? true
                                : false
                            }
                            className="formType"
                            type="radio"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {formik.touched?.formType && formik.errors?.formType ? (
                          <span
                            className={formik.errors?.formType ? `` : `d-none`}
                          >
                            <label className="pl-1 text-danger">
                              {formik.errors?.formType}
                            </label>
                          </span>
                        ) : null}
                      </Col>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              placeholder="Enter title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <span
                                className={formik.errors.title ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.title}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          {formik.values.formType === "consultationForm" && (
                            <Form.Group controlId="formBasicMedicalType">
                              <Form.Label>
                                Medical Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                value={formik.values.formConsultation}
                                name="formConsultation"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "formConsultation",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Medical Type</option>
                                {medicalConsultationData &&
                                medicalConsultationData.length > 0
                                  ? medicalConsultationData.map((d, k) => {
                                      return (
                                        <option
                                          key={d._id}
                                          value={d._id}
                                          className="text-capitalize"
                                        >
                                          {d.title}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              {formik.touched.formConsultation &&
                              formik.errors.formConsultation ? (
                                <span
                                  className={
                                    formik.errors.formConsultation
                                      ? ``
                                      : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik.errors.formConsultation}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          )}
                          {formik.values.formType === "prescriptionRefill" && (
                            <Form.Group controlId="formBasicMedicalType">
                              <Form.Label>
                                Prescription Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                value={formik.values.formConsultation}
                                name="formConsultation"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "formConsultation",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">
                                  Select Prescription Type
                                </option>
                                {prescriptionData && prescriptionData.length > 0
                                  ? prescriptionData.map((d, k) => {
                                      return (
                                        <option
                                          key={d._id}
                                          value={d._id}
                                          className="text-capitalize"
                                        >
                                          {d.title}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              {formik.touched.formConsultation &&
                              formik.errors.formConsultation ? (
                                <span
                                  className={
                                    formik.errors.formConsultation
                                      ? ``
                                      : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik.errors.formConsultation}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          )}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Content <span className="text-danger">*</span>
                            </Form.Label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={
                                formik.values.content
                                  ? formik.values.content
                                  : ""
                              }
                              content={
                                formik.values.content
                                  ? formik.values.content
                                  : ""
                              }
                              onChange={(event, editor) =>
                                formik.setFieldValue(
                                  "content",
                                  editor.getData()
                                )
                              }
                            />
                            {formik.touched.content && formik.errors.content ? (
                              <span
                                className={
                                  formik.errors.content ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.content}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <FormGroup>
                            <Row>
                              <Col md={1}>
                                <label className="label-font">Status</label>
                              </Col>
                              <Col
                                md={11}
                                className="check-inline d-flex mt-3 flex-column"
                              >
                                <label className="right-label-radio mr-3 mb-2">
                                  Active
                                  <input
                                    name="status"
                                    className="status"
                                    checked={formik.values.status}
                                    value={formik.values.status}
                                    type="radio"
                                    onChange={(e) =>
                                      formik.setFieldValue("status", true)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>

                                <label className="right-label-radio mr-3 mb-2">
                                  Inactive
                                  <input
                                    name="status1"
                                    className="status"
                                    checked={!formik.values.status}
                                    value={!formik.values.status}
                                    type="radio"
                                    onChange={(e) =>
                                      formik.setFieldValue("status", false)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Button className="btn-filled" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default AddPartnerDiscount;
