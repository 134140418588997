import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const deletePromoCodeById = (id) =>
  PromiseHandler(apiHelper("delete", `/api/admin/promo-code/delete/${id}`));
const getPromoCode = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/Promo-code/get",data));
const getPromoCodeById = (id) =>
  PromiseHandler(apiHelper("get", `/api/admin/Promo-code/get/${id}`));
const updatePromoCodeById = (id, data) =>
  PromiseHandler(apiHelper("put", `/api/admin/promo-code/update/${id}`, data));
const addPromoCode = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/Promo-code/create", data));
const sendPromoEmail = (id) =>
  PromiseHandler(apiHelper("put", `/api/admin/Promo-code/email/${id}`));
export {
  deletePromoCodeById,
  sendPromoEmail,
  getPromoCode,
  getPromoCodeById,
  updatePromoCodeById,
  addPromoCode,
};
