import React, { useState, useEffect } from "react";

import {
    Button,
    Card,
    Form,
    Container,
    Row,
    Col,
    Breadcrumb,


} from "react-bootstrap";

import { updateBlogById, getBlogById } from "../../actions/blogAction";
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import { getBlogCategoriesActive } from "../../actions/blogCategoryAction";

import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify"
import { useFormik } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import * as Yup from 'yup';
import { ENV } from '../../config/config'
import SweetAlert from 'react-bootstrap-sweetalert';


// Schema for yup
const blogCategorySchema = Yup.object().shape({
    title: Yup.string().trim().min(2, "Title must have at least 2 characters").required("Title field is required"),
    blogCategory: Yup.string().required("Blog category field is required"),
    content: Yup.string().trim().required("Content field is required"),
    metaTitle: Yup.string().trim().required("Meta title field is required"),
    metaDescription: Yup.string().trim().required("Meta description field is required"),
    metaKeyWord: Yup.string().trim().required("Meta keyword field is required"),
    photo: Yup.string().required("Photo field is required"),
    status: Yup.string().required("Email field is required"),
});
const EditBlog = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loader, setLoader] = useState(false)
    const [blogCategoryData, setBlogCategoryData] = useState({});
    const [blogData, setBlogData] = useState({});
    const [getFile, setFile] = useState("");
    const [uploadImage, setUploadImage] = useState(false);
    const [imageExists, setImageExists] = useState(true);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: blogData,
        validationSchema: blogCategorySchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('blogCategory', values.blogCategory);
            formData.append('content', values.content);
            formData.append('metaTitle', values.metaTitle);
            formData.append('metaDescription', values.metaDescription);
            formData.append('metaKeyWord', values.metaKeyWord);
            if (getFile) {
                formData.append('photo', values.photo)
            }
            formData.append('status', values.status);
            setLoader(true)
            let res = await updateBlogById(id, formData)
            if (res.data.success) {
                navigate('/blog')
                toast.success(res.data.message)
            }
            setLoader(false)
        },
    });
    const handlePreviewImage = (e, setFieldValue) => {
        if (e.target.files[0].type.split('/')[1]) {
            let imageType = e.target.files[0].type.split('/')[1];
            if (imageType === "png" || imageType === "jpg" || imageType === "jpeg" || imageType === "gif" || imageType === "webp") {
                setFieldValue('photo', e.target.files[0])
                setFile(URL.createObjectURL(e.target.files[0]));
            } else {
                setUploadImage(true)
                e.target.value = null
            }
        }
    }
    const handleImageError = () => {
        setImageExists(false);
    };
    const setFilePath = (files) => {
        return `${ENV.serverUrl}/public/${files}`;
    }


    const getAllBlogCategory = async () => {
        setLoader(true);
        let res = await getBlogCategoriesActive();
        if (res?.data?.data) {

            setBlogCategoryData(res.data.data);
        }
        setLoader(false);
    };
    const hideModel = () => {
        setUploadImage(false)
    }
    useEffect(() => {
        getAllBlogCategory();
    }, [])
    useEffect(() => {
        const getBlog = async () => {
            setLoader(true);
            let res = await getBlogById(id);
            if (res?.data?.data) {
                let data = res.data?.data
                data.blogCategory = data?.blogCategory?._id;
                setBlogData(data);
            }
            setLoader(false);
        };
        getBlog();
    }, [id]);
    return (
        <>
            {
                uploadImage ?
                    <SweetAlert
                        warning
                        showCloseButton
                        confirmBtnText="OK"
                        onConfirm={hideModel}
                        confirmBtnBsStyle="danger"
                        title="Failed! Please select correct Image Format"
                        onCancel={hideModel}
                        focusCancelBtn
                        customClass="del-sweet-alert "
                    >
                        Supported files ["png", "jpg", "jpeg", "gif", "webp"]
                    </SweetAlert>
                    :
                    null
            }
            {
                loader ? <FullPageLoader />
                    :
                    <div className="pt-3 pt-md-5">
                        <Container fluid>
                            <Row>
                                <Col md="12">
                                    <Breadcrumb className="care-md-breadcrumb">
                                        <Link className="breadcrumb-item" to="/blog">Blog</Link>
                                        <Breadcrumb.Item active>Add</Breadcrumb.Item>
                                    </Breadcrumb>
                                    <Card className="filter-card card">
                                        <Card.Header>
                                            <div className="d-flex align-items-center justify-content-between table-head">
                                                <Card.Title className="text-white" as="h4">
                                                    Add Blog
                                                </Card.Title>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Form onSubmit={formik.handleSubmit}>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Title <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="title" value={formik.values.title} placeholder="Enter title" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.title && formik.errors.title ? (
                                                                <span className={formik.errors.title ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.title}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicMedicalType">
                                                            <Form.Label>Blog Category <span className="text-danger">*</span></Form.Label>

                                                            <Form.Control as="select" name="blogCategory" value={formik.values.blogCategory} onChange={(e) => formik.setFieldValue('blogCategory', e.target.value)} >
                                                                <option value="">Select blog category</option>
                                                                {
                                                                    blogCategoryData && blogCategoryData.length > 0 ?
                                                                        blogCategoryData.map((d, k) => {
                                                                            return (<option key={d._id} value={d._id} className="text-capitalize">{d.title}</option>)
                                                                        })
                                                                        : null
                                                                }

                                                            </Form.Control>
                                                            {formik.touched.blogCategory && formik.errors.blogCategory ? (
                                                                <span className={formik.errors.blogCategory ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.blogCategory}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Content <span className="text-danger">*</span></Form.Label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={formik.values.content ? formik.values.content : ''}
                                                                content={formik.values.content ? formik.values.content : ''}
                                                                onChange={(event, editor) => formik.setFieldValue('content', editor.getData())}
                                                            />
                                                            {formik.touched.content && formik.errors.content ? (
                                                                <span className={formik.errors.content ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.content}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Meta Title <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="metaTitle" value={formik.values.metaTitle} placeholder="Enter Meta Title" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.metaTitle && formik.errors.metaTitle ? (
                                                                <span className={formik.errors.metaTitle ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.metaTitle}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Meta Description <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control as="textarea" value={formik.values.metaDescription} name="metaDescription" rows={5} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                            {formik.touched.metaDescription && formik.errors.metaDescription ? (
                                                                <span className={formik.errors.metaDescription ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.metaDescription}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Keyword <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="metaKeyWord" value={formik.values.metaKeyWord} placeholder="Enter Meta Keyword" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.metaKeyWord && formik.errors.metaKeyWord ? (
                                                                <span className={formik.errors.metaKeyWord ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.metaKeyWord}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group controlId="formFile" className="mb-3" onChange={(e) => handlePreviewImage(e, formik.setFieldValue)}>
                                                            <Form.Label>Upload Image <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="file" name="photo" accept=".png,.jpg,.jpeg,.webp" />
                                                            {formik.touched.photo && formik.errors.photo ? (
                                                                <span className={formik.errors.photo ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.photo}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group controlId="formFilePreview" className="mb-3">
                                                            {
                                                                getFile ?
                                                                    <img src={getFile} width="200" height="200" alt="blog not found" />
                                                                    : imageExists ?
                                                                        <img src={setFilePath(formik.values?.photo)} onError={handleImageError} crossOrigin="anonymous" width="200" height="200" alt="blog not found" /> : null
                                                            }

                                                        </Form.Group>
                                                        <Row>
                                                            <Col md={1}>
                                                                <label className="label-font">Status</label>
                                                            </Col>
                                                            <Col md={11} className="check-inline d-flex mt-3 flex-column">
                                                                <label className="right-label-radio mr-3 mb-2">Active
                                                                    <input name="status" className="status" checked={formik.values.status} value={formik.values.status} type="radio" onChange={(e) => formik.setFieldValue('status', true)} />
                                                                    <span className="checkmark"></span>
                                                                </label>

                                                                <label className="right-label-radio mr-3 mb-2">Inactive
                                                                    <input name="status1" className="status" checked={!formik.values.status} value={!formik.values.status} type="radio" onChange={(e) => formik.setFieldValue('status', false)} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Button className="btn-filled" type="submit">
                                                            Update
                                                        </Button>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }

        </>
    );
};
export default EditBlog;
