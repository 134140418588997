import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { addRole, updateRole } from "../../actions/rolesActions";

const PermissionsModal = ({
  showRolesModal,
  handleCloseModal,
  modalType,
  setRoleTitle,
  roleTitle,
  setStatus,
  status,
  role,
  setPermissions,
  permissions,
  setSelectAll,
  selectAll,
  getRoleData,
}) => {
  const [errorTitleMsg, setErrorTitleMsg] = useState("");
  const [checkFormManagement, setCheckFormManagement] = useState(false);
  const [checkCmsManagement, setCheckCmsManagement] = useState(false);
  const [createdLoader, setCreatedLoader] = useState(false);

  useEffect(() => {
    if (role) {
      if (modalType === 2 || modalType === 3) {
        delete role["createdAt"];
        delete role["updatedAt"];
        delete role["__v"];
        setPermissions({ ...role });
        setRoleTitle(role.title);
        setStatus(role.status);
        if (role?.viewFormsManagement) {
          setCheckFormManagement(true);
        }
        if (role?.viewCmsManagement) {
          setCheckCmsManagement(true);
        }
      }
    }
  }, [role, modalType, setPermissions, setRoleTitle, setStatus]);

  const onChangeCheckbox = (name, value) => {
    let _permissions = permissions;
    if (name === "viewFormsManagement") {
      setCheckFormManagement(!checkFormManagement);
    }
    if (name === "viewCmsManagement") {
      setCheckCmsManagement(!checkCmsManagement);
    }
    if (name === "selectAll") {
      Object.keys(_permissions).forEach((val) => {
        if (val !== "_id") {
          if (val !== "viewProfile") {
            _permissions = { ..._permissions, [val]: value };
          }
        }
      });
      setSelectAll(value);
    } else {
      _permissions = { ..._permissions, [name]: value };

      let allSelected = false;
      Object.keys(_permissions).forEach((val) => {
        if (_permissions[val]) {
        } else {
          allSelected = true;
        }
      });
      let selectCount = allSelected ? false : true;
      setSelectAll(selectCount);
    }
    setPermissions(_permissions);
  };

  const submit = async () => {
    let check = true;
    if (
      roleTitle === "" ||
      roleTitle === undefined ||
      roleTitle.trim() === ""
    ) {
      setErrorTitleMsg("Role Title is required!");
      check = false;
    } else if (roleTitle.length > 0) {
      setErrorTitleMsg("");
      check = true;
    }

    if (check) {
      if (modalType === 1) {
        const {_id , ...roleWithouId} = permissions
        const role = { ...roleWithouId, title: roleTitle, status: status };
        setCreatedLoader(true);
        let res = await addRole(role);
        if (res?.data?.success) {
          handleCloseModal();
          await getRoleData();
          toast.success(res.data.message);
          setRoleTitle("");
          setStatus(false);
        }
        setCreatedLoader(false);
      }
      if (modalType === 3) {
        const role = { ...permissions, title: roleTitle, status: status };
        setCreatedLoader(true);
        let res = await updateRole(role);
        if (res?.data?.success) {
          if (res.data.message) {
            toast.success(res.data.message);
          }
          handleCloseModal();
          await getRoleData();
        }
        setCreatedLoader(false);
      }
    }
  };

  return (
    <>
      <Container fluid>
        {modalType > 0 && (
          <Modal className="modal-primary" show={showRolesModal}>
            <Modal.Header className="justify-content-center">
              <Row>
                <div className="col-12">
                  <h4 className="mb-0 mb-md-3 mt-0">
                    {modalType === 1
                      ? "Add New"
                      : modalType === 2
                      ? "View"
                      : "Edit"}{" "}
                    Staff Role
                  </h4>
                </div>
              </Row>
            </Modal.Header>
            <Modal.Body className="modal-body roles-modal-body">
              <Form>
                <Form.Group>
                  <Row>
                    <Col md={12}>
                      <label className="label-font">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        className="mb-3"
                        placeholder="Enter title"
                        type="text"
                        name="title"
                        onChange={(e) => setRoleTitle(e.target.value)}
                        disabled={modalType === 2}
                        value={roleTitle}
                        required
                      />
                      <div className="d-flex justify-content-between align-items-end edit-title">
                        <span>
                          <label
                            className={
                              errorTitleMsg ? `pl-1 text-danger` : `d-none`
                            }
                          >
                            {errorTitleMsg}
                          </label>
                        </span>
                        <label className="right-label-checkbox">
                          Select All
                          <input
                            type="checkbox"
                            disabled={modalType === 2}
                            name="selectAll"
                            onChange={(e) =>
                              onChangeCheckbox(e.target.name, !selectAll)
                            }
                            checked={selectAll}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="align-items-start">
                    <Col md={4}>
                      <label className="label-font">Dashboard</label>
                    </Col>
                    <Col md={8}>
                      {/* setPermissions({...permissions,viewDashboard:!permissions.viewDashboard}) */}
                      <label className="right-label-checkbox">
                        View
                        <input
                          type="checkbox"
                          checked={permissions?.viewDashboard}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewDashboard
                            )
                          }
                          disabled={modalType === 2}
                          name="viewDashboard"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Roles</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewRole}
                          disabled={modalType === 2}
                          name="viewRole"
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewRole
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addRole}
                          disabled={modalType === 2}
                          name="addRole"
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addRole
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editRole}
                          disabled={modalType === 2}
                          name="editRole"
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editRole
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteRole}
                          disabled={modalType === 2}
                          name="deleteRole"
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteRole
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Admin Users</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewUsers}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewUsers
                            )
                          }
                          name="viewUsers"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addUser}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addUser
                            )
                          }
                          name="addUser"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editUser}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editUser
                            )
                          }
                          name="editUser"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteUser}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteUser
                            )
                          }
                          name="deleteUser"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Consultations List</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewConsultations}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewConsultations
                            )
                          }
                          name="viewConsultations"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        View Details
                        <input
                          type="checkbox"
                          checked={permissions.viewConsultationsDetails}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewConsultationsDetails
                            )
                          }
                          name="viewConsultationsDetails"
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* <label className="right-label-checkbox mr-3 mb-2">
                        Send
                        <input
                          type="checkbox"
                          checked={permissions.sendConsultations}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.sendConsultations
                            )
                          }
                          name="sendConsultations"
                        />
                        <span className="checkmark"></span>
                      </label> */}
                    </Col>
                  </Row>
                </Form.Group>
                {/* Paid COnsultations prescription not send */}
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">
                        Paid Consultation Prescription Not Sent
                      </label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={
                            permissions.viewPaidConsultationPRescriptionNotSent
                          }
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPaidConsultationPRescriptionNotSent
                            )
                          }
                          name="viewPaidConsultationPRescriptionNotSent"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        View Details
                        <input
                          type="checkbox"
                          checked={
                            permissions.viewPaidConsultationPRescriptionNotSentDetails
                          }
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPaidConsultationPRescriptionNotSentDetails
                            )
                          }
                          name="viewPaidConsultationPRescriptionNotSentDetails"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                {/* job apllication */}
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Job Applications</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewJobApplication}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewJobApplication
                            )
                          }
                          name="viewJobApplication"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        View Details
                        <input
                          type="checkbox"
                          checked={permissions.viewJobApplicationDetails}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewJobApplicationDetails
                            )
                          }
                          name="viewJobApplicationDetails"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteJobApplication}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteJobApplication
                            )
                          }
                          name="deleteJobApplication"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Prescriptions Refill</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewPrescriptionRefill}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPrescriptionRefill
                            )
                          }
                          name="viewPrescriptionRefill"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        View Details
                        <input
                          type="checkbox"
                          checked={permissions.viewPrescriptionRefillDetails}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPrescriptionRefillDetails
                            )
                          }
                          name="viewPrescriptionRefillDetails"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                {/* Email List */}
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Subscribers List</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewEmailSubscriber}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewEmailSubscriber
                            )
                          }
                          name="viewEmailSubscriber"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteEmailSubscriber}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteEmailSubscriber
                            )
                          }
                          name="deleteEmailSubscriber"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Patients Users</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewPatients}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPatients
                            )
                          }
                          name="viewPatients"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addPatients}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addPatients
                            )
                          }
                          name="addPatients"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editPatients}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editPatients
                            )
                          }
                          name="editPatients"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deletePatients}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deletePatients
                            )
                          }
                          name="deletePatients"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Promo Code</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewPromoCode}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPromoCode
                            )
                          }
                          name="viewPromoCode"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addPromoCode}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addPromoCode
                            )
                          }
                          name="addPromoCode"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editPromoCode}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editPromoCode
                            )
                          }
                          name="editPromoCode"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deletePromoCode}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deletePromoCode
                            )
                          }
                          name="deletePromoCode"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Blogs</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewBlog}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewBlog
                            )
                          }
                          name="viewBlog"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addBlog}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addBlog
                            )
                          }
                          name="addBlog"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editBlog}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editBlog
                            )
                          }
                          name="editBlog"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteBlog}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteBlog
                            )
                          }
                          name="deleteBlog"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Blog Categories</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewBlogCategories}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewBlogCategories
                            )
                          }
                          name="viewBlogCategories"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addBlogCategories}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addBlogCategories
                            )
                          }
                          name="addBlogCategories"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editBlogCategories}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editBlogCategories
                            )
                          }
                          name="editBlogCategories"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteBlogCategories}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteBlogCategories
                            )
                          }
                          name="deleteBlogCategories"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Partner Discount</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewPartnerDiscount}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPartnerDiscount
                            )
                          }
                          name="viewPartnerDiscount"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addPartnerDiscount}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addPartnerDiscount
                            )
                          }
                          name="addPartnerDiscount"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editPartnerDiscount}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editPartnerDiscount
                            )
                          }
                          name="editPartnerDiscount"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deletePartnerDiscount}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deletePartnerDiscount
                            )
                          }
                          name="deletePartnerDiscount"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Site Settings</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewSetting}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewSetting
                            )
                          }
                          name="viewSetting"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addSetting}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addSetting
                            )
                          }
                          name="addSetting"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editSetting}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editSetting
                            )
                          }
                          name="editSetting"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteSetting}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteSetting
                            )
                          }
                          name="deleteSetting"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Form Management</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewFormsManagement}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewFormsManagement
                            )
                          }
                          name="viewFormsManagement"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                {checkFormManagement ? (
                  <div className="roles-management">
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">Medical Type</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewMedicalType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewMedicalType
                                )
                              }
                              name="viewMedicalType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addMedicalType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addMedicalType
                                )
                              }
                              name="addMedicalType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editMedicalType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editMedicalType
                                )
                              }
                              name="editMedicalType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteMedicalType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteMedicalType
                                )
                              }
                              name="deleteMedicalType"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            Medical Consultations
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewMedicalConsultation}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewMedicalConsultation
                                )
                              }
                              name="viewMedicalConsultation"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addMedicalConsultation}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addMedicalConsultation
                                )
                              }
                              name="addMedicalConsultation"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editMedicalConsultation}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editMedicalConsultation
                                )
                              }
                              name="editMedicalConsultation"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteMedicalConsultation}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteMedicalConsultation
                                )
                              }
                              name="deleteMedicalConsultation"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            Prescription Refill
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewPrescriptionRefillType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewPrescriptionRefillType
                                )
                              }
                              name="viewPrescriptionRefillType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addPrescriptionRefillType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addPrescriptionRefillType
                                )
                              }
                              name="addPrescriptionRefillType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editPrescriptionRefillType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editPrescriptionRefillType
                                )
                              }
                              name="editPrescriptionRefillType"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deletePrescriptionRefillType}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deletePrescriptionRefillType
                                )
                              }
                              name="deletePrescriptionRefillType"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-0">
                      <Row>
                        <Col md={4}>
                          <label className="label-font">Forms</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewForms}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewForms
                                )
                              }
                              name="viewForms"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addForms}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addForms
                                )
                              }
                              name="addForms"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editForms}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editForms
                                )
                              }
                              name="editForms"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteForms}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteForms
                                )
                              }
                              name="deleteForms"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                ) : null}

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">CMS Management</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewCmsManagement}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewCmsManagement
                            )
                          }
                          name="viewCmsManagement"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                {checkCmsManagement ? (
                  <div className="roles-management">
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">CMS Home</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsHomeSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsHomeSection
                                )
                              }
                              name="viewCmsHomeSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsHomeSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsHomeSection
                                )
                              }
                              name="addCmsHomeSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsHomeSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsHomeSection
                                )
                              }
                              name="editCmsHomeSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsHomeSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsHomeSection
                                )
                              }
                              name="deleteCmsHomeSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">CMS About</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsAboutSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsAboutSection
                                )
                              }
                              name="viewCmsAboutSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsAboutSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsAboutSection
                                )
                              }
                              name="addCmsAboutSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsAboutSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsAboutSection
                                )
                              }
                              name="editCmsAboutSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsAboutSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsAboutSection
                                )
                              }
                              name="deleteCmsAboutSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">CMS Faqs</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsFaqSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsFaqSection
                                )
                              }
                              name="viewCmsFaqSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsFaqSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsFaqSection
                                )
                              }
                              name="addCmsFaqSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsFaqSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsFaqSection
                                )
                              }
                              name="editCmsFaqSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsFaqSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsFaqSection
                                )
                              }
                              name="deleteCmsFaqSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">CMS Services</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsServiceSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsServiceSection
                                )
                              }
                              name="viewCmsServiceSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsServiceSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsServiceSection
                                )
                              }
                              name="addCmsServiceSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsServiceSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsServiceSection
                                )
                              }
                              name="editCmsServiceSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsServiceSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsServiceSection
                                )
                              }
                              name="deleteCmsServiceSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            CMS Refill Prescriptions
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsPrescriptionSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsPrescriptionSection
                                )
                              }
                              name="viewCmsPrescriptionSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsPrescriptionSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsPrescriptionSection
                                )
                              }
                              name="addCmsPrescriptionSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsPrescriptionSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsPrescriptionSection
                                )
                              }
                              name="editCmsPrescriptionSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsPrescriptionSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsPrescriptionSection
                                )
                              }
                              name="deleteCmsPrescriptionSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">CMS Laboratory</label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsLabSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsLabSection
                                )
                              }
                              name="viewCmsLabSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsLabSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsLabSection
                                )
                              }
                              name="addCmsLabSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsLabSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsLabSection
                                )
                              }
                              name="editCmsLabSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsLabSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsLabSection
                                )
                              }
                              name="deleteCmsLabSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            CMS Sexual Health
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsSexualHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsSexualHealthSection
                                )
                              }
                              name="viewCmsSexualHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsSexualHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsSexualHealthSection
                                )
                              }
                              name="addCmsSexualHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsSexualHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsSexualHealthSection
                                )
                              }
                              name="editCmsSexualHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsSexualHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsSexualHealthSection
                                )
                              }
                              name="deleteCmsSexualHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            CMS Policy & Condition Page
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsPolicyPage}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsPolicyPage
                                )
                              }
                              name="viewCmsPolicyPage"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsPolicyPage}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsPolicyPage
                                )
                              }
                              name="addCmsPolicyPage"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsPolicyPage}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsPolicyPage
                                )
                              }
                              name="editCmsPolicyPage"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsPolicyPage}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsPolicyPage
                                )
                              }
                              name="deleteCmsPolicyPage"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>
                    {/* mental health */}
                    <Form.Group className="mb-0">
                      <Row>
                        <Col md={4}>
                          <label className="label-font">
                            CMS Mental Health
                          </label>
                        </Col>
                        <Col md={8} className="check-inline d-flex">
                          <label className="right-label-checkbox mr-3 mb-2">
                            View
                            <input
                              type="checkbox"
                              checked={permissions.viewCmsMentalHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.viewCmsMentalHealthSection
                                )
                              }
                              name="viewCmsMentalHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Add
                            <input
                              type="checkbox"
                              checked={permissions.addCmsMentalHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.addCmsMentalHealthSection
                                )
                              }
                              name="addCmsMentalHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Edit
                            <input
                              type="checkbox"
                              checked={permissions.editCmsMentalHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.editCmsMentalHealthSection
                                )
                              }
                              name="editCmsMentalHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="right-label-checkbox mr-3 mb-2">
                            Delete
                            <input
                              type="checkbox"
                              checked={permissions.deleteCmsMentalHealthSection}
                              disabled={modalType === 2}
                              onChange={(e) =>
                                onChangeCheckbox(
                                  e.target.name,
                                  !permissions.deleteCmsMentalHealthSection
                                )
                              }
                              name="deleteCmsMentalHealthSection"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                ) : null}

                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Email Template</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewEmailTemplate}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewEmailTemplate
                            )
                          }
                          name="viewEmailTemplate"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addEmailTemplate}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addEmailTemplate
                            )
                          }
                          name="addEmailTemplate"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editEmailTemplate}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editEmailTemplate
                            )
                          }
                          name="editEmailTemplate"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteEmailTemplate}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteEmailTemplate
                            )
                          }
                          name="deleteEmailTemplate"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>

                {/* password */}
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Password Management</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewPassword}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewPassword
                            )
                          }
                          name="viewPassword"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Update
                        <input
                          type="checkbox"
                          checked={permissions.updatePassword}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.updatePassword
                            )
                          }
                          name="updatePassword"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">States</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-checkbox mr-3 mb-2">
                        View
                        <input
                          type="checkbox"
                          checked={permissions.viewState}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.viewState
                            )
                          }
                          name="viewState"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Add
                        <input
                          type="checkbox"
                          checked={permissions.addState}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.addState
                            )
                          }
                          name="addState"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Edit
                        <input
                          type="checkbox"
                          checked={permissions.editState}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.editState
                            )
                          }
                          name="editState"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-checkbox mr-3 mb-2">
                        Delete
                        <input
                          type="checkbox"
                          checked={permissions.deleteState}
                          disabled={modalType === 2}
                          onChange={(e) =>
                            onChangeCheckbox(
                              e.target.name,
                              !permissions.deleteState
                            )
                          }
                          name="deleteState"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <FormGroup>
                  <Row>
                    <Col md={4}>
                      <label className="label-font">Status</label>
                    </Col>
                    <Col md={8} className="check-inline d-flex">
                      <label className="right-label-radio mr-3 mb-2">
                        Active
                        <input
                          name="status"
                          disabled={modalType === 2}
                          type="radio"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(true)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio mr-3 mb-2">
                        Inactive
                        <input
                          name="status"
                          disabled={modalType === 2}
                          type="radio"
                          checked={!status}
                          value={!status}
                          onChange={(e) => setStatus(false)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer className="pt-3">
              {modalType === 1 ? (
                createdLoader ? (
                  <Button className="btn-filled" disabled>
                    Create Role
                  </Button>
                ) : (
                  <Button className="btn-filled" onClick={() => submit()}>
                    Create Role
                  </Button>
                )
              ) : (
                ""
              )}
              {modalType === 3 ? (
                createdLoader ? (
                  <Button className="btn-filled" disabled>
                    Update
                  </Button>
                ) : (
                  <Button className="btn-filled" onClick={() => submit()}>
                    Update
                  </Button>
                )
              ) : (
                ""
              )}
              <Button
                className="outline-button"
                onClick={() => handleCloseModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default PermissionsModal;
