import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaPlus, FaEye, FaEdit, FaTrash } from "react-icons/fa";
import PermissionsModal from "./PermissionsModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteRole, getRoles } from "../../actions/rolesActions";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import InitializeLocalStorage from "../../components/PaginationHandle";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const Roles = () => {
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [roleTitle, setRoleTitle] = useState("");
  const [status, setStatus] = useState(false);
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [filterText, setFilterText] = useState("");
  const [role, setRole] = useState({});
  const [loader, setLoader] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState();
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [permissions, setPermissions] = useState({
    /**  system permissions **/

    viewDashboard: false,
    // viewProfile: true,

    // roles
    addRole: false,
    editRole: false,
    deleteRole: false,
    viewRole: false,

    // admin users
    addUser: false,
    editUser: false,
    deleteUser: false,
    viewUsers: false,

    // consultations list
    viewConsultations: false,
    viewConsultationsDetails: false,
    sendConsultations: false,
    viewPrescription: false,

    // viewPrescriptionRefill Admin
    viewPrescriptionRefill: false,
    viewPrescriptionRefillDetails: false,

    // Email SUbscriber Admin
    viewEmailSubscriber: false,
    deleteEmailSubscriber: false,

    // Blogs
    addBlog: false,
    editBlog: false,
    deleteBlog: false,
    viewBlog: false,

    //BlogCategories
    viewBlogCategories: false,
    addBlogCategories: false,
    editBlogCategories: false,
    deleteBlogCategories: false,

    // partner discount
    viewPartnerDiscount: false,
    addPartnerDiscount: false,
    editPartnerDiscount: false,
    deletePartnerDiscount: false,

    // settings
    addSetting: false,
    viewSetting: false,
    editSetting: false,
    deleteSetting: false,

    // Password Management
    viewPassword: false,
    updatePassword: false,

    // Forms Management
    viewFormsManagement: false,
    //Medical Type;
    addMedicalType: false,
    viewMedicalType: false,
    editMedicalType: false,
    deleteMedicalType: false,
    //Medical Consultation
    addMedicalConsultation: false,
    viewMedicalConsultation: false,
    editMedicalConsultation: false,
    deleteMedicalConsultation: false,
    //Prescription refill Type;
    viewPrescriptionRefillType: false,
    addPrescriptionRefillType: false,
    editPrescriptionRefillType: false,
    deletePrescriptionRefillType: false,

    //paid consultation prescriptionnot sent
    viewPaidConsultationPRescriptionNotSent: false,
    viewPaidConsultationPRescriptionNotSentDetails: false,

    // Job Applications
    viewJobApplication: false,
    viewJobApplicationDetails: false,
    deleteJobApplication: false,

    // Forms
    addForms: false,
    viewForms: false,
    editForms: false,
    deleteForms: false,

    //CMS Management
    viewCmsManagement: false,
    //Home
    addCmsHomeSection: false,
    viewCmsHomeSection: false,
    editCmsHomeSection: false,
    deleteCmsHomeSection: false,
    //About
    addCmsAboutSection: false,
    viewCmsAboutSection: false,
    deleteCmsAboutSection: false,
    editCmsAboutSection: false,
    //FAQ section
    addCmsFaqSection: false,
    viewCmsFaqSection: false,
    deleteCmsFaqSection: false,
    editCmsFaqSection: false,
    // Services
    viewCmsServiceSection: false,
    addCmsServiceSection: false,
    editCmsServiceSection: false,
    deleteCmsServiceSection: false,
    // Refill Prescriptions
    viewCmsPrescriptionSection: false,
    addCmsPrescriptionSection: false,
    editCmsPrescriptionSection: false,
    deleteCmsPrescriptionSection: false,
    // Laboratory
    viewCmsLabSection: false,
    addCmsLabSection: false,
    editCmsLabSection: false,
    deleteCmsLabSection: false,
    // Sexual Health
    viewCmsSexualHealthSection: false,
    addCmsSexualHealthSection: false,
    editCmsSexualHealthSection: false,
    deleteCmsSexualHealthSection: false,
    // Policy Page
    viewCmsPolicyPage: false,
    addCmsPolicyPage: false,
    editCmsPolicyPage: false,
    deleteCmsPolicyPage: false,

    // Mental Health
    viewCmsMentalHealthSection: false,
    addCmsMentalHealthSection: false,
    editCmsMentalHealthSection: false,
    deleteCmsMentalHealthSection: false,

    // Patient users
    viewPatients: false,
    addPatients: false,
    editPatients: false,
    deletePatients: false,
    // Email template Page
    viewEmailTemplate: false,
    addEmailTemplate: false,
    editEmailTemplate: false,
    deleteEmailTemplate: false,

    viewState: false,
    addState: false,
    editState: false,
    deleteState: false,

    // promoCode Page
    viewPromoCode: false,
    addPromoCode: false,
    editPromoCode: false,
    deletePromoCode: false,

    // promoCode Page
    viewLogs: false,
    deleteLogs: false,

    // Refill Prescriptions
    viewPrescriptionRefillNotSent: false,
  });

  const location = useLocation();
  const { pathname } = location;

  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const getRoleData = async () => {
    let res = await getRoles();
    if (res) {
      setRoles(res?.data);
    }
  };

  const hideModel = () => {
    setDelete(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const handleShowModal = (type, role = {}) => {
    setShowRolesModal(true);
    setModalType(type);
    if (type === 2 || type === 3) {
      setRole(role);
      setPermissions({ ...role });
    }
    if (type === 1) {
      setEmpty();
    }
  };

  const setEmpty = () => {
    setRoleTitle("");
    setStatus(false);
    setSelectAll(false);
    for (let key in permissions) {
      permissions[key] = false;
    }
    setRole(permissions);
  };

  const empty = () => {
    setRoleTitle("");
    setStatus(false);
    for (let key in permissions) {
      permissions[key] = false;
    }
    setRole(permissions);
  };

  const handleCloseModal = () => {
    setRole({});
    empty();
    setShowRolesModal(false);
  };

  useEffect(() => {
    const getAllRoles = async () => {
      setLoader(true);
      await getRoleData();
      setLoader(false);
    };
    getAllRoles();
  }, [dispatch]);

  const deleteRoleById = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteRole(deleteId);
    if (res?.status) {
      toast.success(res?.data?.message);
      await getRoleData();
    }
    setLoader(false);
  };

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const getProperty = (row, columnId) => {
      switch (columnId) {
        case "Role Name":
          return row?.title?.toLowerCase() || "";

        case "Status":
          return row?.status || "";

        case "Created At":
          const endDate = new Date(row?.createdAt);
          return `${
            endDate.getMonth() + 1
          }/${endDate.getDate()}/${endDate.getFullYear()}`.toLowerCase();

        default:
          return "";
      }
    };

    const a = getProperty(rowA, columnId);
    const b = getProperty(rowB, columnId);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => <div className="td-name">{row.title}</div>,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Role Name"),
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="text-center">
          {row.status ? (
            <label className="label label-success m-0">Active</label>
          ) : (
            <label className="label label-danger m-0">Inactive</label>
          )}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Status"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.viewRole ? (
              !(
                row._id === currentUserRole?._id || row?.title == "super admin"
              ) ? (
                <li className="d-inline-block align-top">
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                    placement="left"
                  >
                    <button
                      type="button"
                      className="btn-link btn-icon btn btn-info"
                      onClick={() => handleShowModal(2, row)}
                    >
                      <FaEye />
                    </button>
                  </OverlayTrigger>
                </li>
              ) : (
                <button
                  type="button"
                  className="btn-link btn-icon btn btn-success disabled"
                  disabled
                >
                  <FaEye />
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn-link btn-icon btn btn-success disabled"
                disabled
              >
                <FaEye />
              </button>
            )}
            {currentUserRole?.editRole ? (
              !(
                row._id === currentUserRole?._id || row?.title == "super admin"
              ) ? (
                <li className="d-inline-block align-top">
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                    placement="left"
                  >
                    <button
                      type="button"
                      className="btn-link btn-icon btn btn-success"
                      onClick={() => handleShowModal(3, row)}
                    >
                      <FaEdit />
                    </button>
                  </OverlayTrigger>
                </li>
              ) : (
                <button
                  type="button"
                  className="btn-link btn-icon btn btn-success disabled"
                  disabled
                >
                  <FaEdit />
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn-link btn-icon btn btn-success disabled"
                disabled
              >
                <FaEdit />
              </button>
            )}
            {currentUserRole?.deleteRole ? (
              !(
                row._id === currentUserRole?._id || row?.title == "super admin"
              ) ? (
                <li className="d-inline-block align-top">
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                    placement="left"
                  >
                    <button
                      type="button"
                      className="btn-link btn-icon btn btn-danger"
                      onClick={() => showModel(row._id)}
                    >
                      <FaTrash />
                    </button>
                  </OverlayTrigger>
                </li>
              ) : (
                <button
                  type="button"
                  className="btn-link btn-icon btn btn-danger disabled"
                  disabled
                >
                  <FaTrash />
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn-link btn-icon btn btn-danger disabled"
                disabled
              >
                <FaTrash />
              </button>
            )}
          </ul>
        </div>
      ),
      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(selectedOption, text);
  };

  const filterSearchDatatable = (text, searchText) => {
    const filteredItemsResult = roles.data.filter((data) => {
      if (text) {
        if (
          data.status.toString().toLowerCase().includes(text.toLowerCase()) &&
          data?.title.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return data;
        }
      } else if (data?.title.toLowerCase().includes(searchText.toLowerCase())) {
        return data;
      }
      return undefined;
    });
    setFilteredItems([...filteredItemsResult]);
    setSelectedOption(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...roles?.data]);
    setSelectedOption("");
  };
  useEffect(() => {
    if (roles?.data) {
      setFilteredItems([...roles?.data]);
    }
  }, [roles?.data]);

  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteRoleById}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="pt-3 pt-md-5 roles-listing-wrap">
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="filter-card card">
                    <Card.Header>
                      <div className="d-flex align-items-center justify-content-between table-head">
                        <Card.Title className="text-white" as="h4">
                          Filter
                        </Card.Title>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xl={4} sm={6} className="search-wrap">
                          <Form.Group>
                            <Form.Label className="d-block mb-2 text-white">
                              Search with title...
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="search"
                              placeholder="Search"
                              value={filterText}
                              onChange={(e) => filterDatatable(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xl={4} sm={6} className="search-wrap">
                          <Form.Group>
                            <Form.Label className="d-block mb-2 text-white">
                              Filter with status...
                            </Form.Label>
                            <select
                              value={selectedOption}
                              onChange={(e) =>
                                filterSearchDatatable(
                                  e.target.value,
                                  filterText
                                )
                              }
                            >
                              <option value="">Select Status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </Form.Group>
                        </Col>
                        <Col xl={4} md={6}>
                          <Form.Group>
                            <label className="d-none d-sm-block mb-2 form-label">
                              &nbsp;
                            </label>
                            <div className="d-flex  filter-btns-holder">
                              <button
                                type="button"
                                className="outline-button"
                                onClick={() => resetFilter()}
                              >
                                Reset
                              </button>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                    <h4 className="card-title">Roles Listing</h4>
                    {currentUserRole?.addRole ? (
                      <>
                        <div className="btn-add">
                          <Button
                            onClick={() => handleShowModal(1)}
                            className="float-sm-right btn-filled d-flex align-items-center"
                          >
                            <span className="add-icon mr-2">
                              <FaPlus />
                            </span>{" "}
                            Add New Role
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="btn-add">
                          <Button
                            className="float-sm-right btn-filled d-flex align-items-center disabled"
                            disabled
                          >
                            <span className="add-icon mr-2">
                              <FaPlus />
                            </span>{" "}
                            Add New Role
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    theme="solarized"
                    pagination
                    className="react-datatable"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <PermissionsModal
            showRolesModal={showRolesModal}
            handleCloseModal={handleCloseModal}
            modalType={modalType}
            setRoleTitle={setRoleTitle}
            roleTitle={roleTitle}
            setStatus={setStatus}
            status={status}
            role={role}
            setPermissions={setPermissions}
            permissions={permissions}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            getRoleData={getRoleData}
          />
        </>
      )}
    </>
  );
};

export default Roles;
