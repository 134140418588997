import React, { useState, useEffect, useMemo } from "react";
import { Card, Form, Container, Row, Col, Dropdown } from "react-bootstrap";
import {
  getPatientUsers,
  updatePatientStatus,
} from "../../actions/patientAction";

import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import _debounce from "lodash/debounce";
import "rc-pagination/assets/index.css";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import InitializeLocalStorage from "../../components/PaginationHandle";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const PatientUser = () => {
  const [patients, setPatients] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [debouncedText, setDebouncedText] = useState(filterText);
  const [sort, setSort] = useState();
  const [direction, setDirection] = useState();

  const location = useLocation();
  const { pathname } = location;

  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const getAllPatientUsers = async (page, limit = perPage) => {
    setLoader(true);
    let obj = {
      page,
      limit,
      userStatus: selectedOption,
      filterText: debouncedText,
      sort,
      direction,
    };
    let res = await getPatientUsers(obj);
    if (res?.data?.data?.user) {
      setTotalRows(res?.data?.data?.pagination?.total);
      setPatients(res?.data?.data?.user);
    }
    setLoader(false);
  };
  const handlePageChange = (page) => {
    getAllPatientUsers(page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    getAllPatientUsers(page, newPerPage);
    setPerPage(newPerPage);
  };
  const pateintStatus = async (id, status) => {
    let obj = {
      status: status,
    };
    let res = await updatePatientStatus(id, obj);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
      getAllPatientUsers();
    }
  };
  const handleSort = (column, sortDirection) => {
    setSort(column?.sortField);
    setDirection(sortDirection);
  };
  // const convertDate = (dateString) => {
  //   const chicagoTime = moment.utc(dateString);
  //   return chicagoTime.format("MM-DD-YYYY");
  // };

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("M-D-YYYY, h:mm A");
  };
  const DOBCOnverter = (dateString) => {
    return moment.utc(dateString).format("MM-DD-YYYY");
  };
  const columns = useMemo(() => [
    {
      name: "ID",
      selector: (row, index) => {
        const counter =
          ((isNaN(currentPage) ? 1 : currentPage) - 1) * perPage + index + 1;
        return counter;
      },
      minWidth: "50px",
      sortable: true,
      sortField: "ID",
    },
    {
      name: "First Name",
      selector: (row) => row?.firstName,
      minWidth: "150px",
      sortable: true,
      sortField: "firstName",
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
      minWidth: "150px",
      sortable: true,
      sortField: "lastName",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      minWidth: "230px",
      sortable: true,
      sortField: "email",
    },
    // {
    //   name: "DOB",
    //   selector: (row) => {
    //     const options = {
    //       year: "numeric",
    //       month: "numeric",
    //       day: "numeric",
    //       hour12: true,
    //     };

    //     const date = new Date(row?.dateOfBirth);
    //     return date.toLocaleDateString(undefined, options).replace(/\//g, "-");
    //   },
    //   minWidth: "220px",
    //   sortable: true,
    //   sortField: "dateOfBirth",
    // },

    {
      name: "DOB",
      selector: (row) => DOBCOnverter(row?.dateOfBirth),
      minWidth: "220px",
      sortable: true,
      sortField: "createdAt",
    },
    {
      name: "phone",
      selector: (row) => row?.phone,
      minWidth: "230px",
      sortable: true,
      sortField: "phone",
    },
    {
      name: "createdAt",
      selector: (row) => convertDate(row?.createdAt),
      minWidth: "200px",
      sortable: true,
      sortField: "createdAt",
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status === "true" ? (
            <label className="label label-success m-0">Active</label>
          ) : row.status === "false" ? (
            <label className="label label-danger m-0">Inactive</label>
          ) : (
            <label className="label label-warning m-0">Blocked</label>
          )}
        </>
      ),
      minWidth: "150px",
      sortable: true,
      sortField: "status",
    },
    {
      name: "Deletion",
      selector: (row) => (
        <>
          {row.isDeletion === "pending" ? (
            <label className="label label-success m-0">Pending</label>
          ) : row.isDeletion === "approved" ? (
            <label className="label label-danger m-0">Deleted</label>
          ) : (
            <label className="label label-warning m-0">None</label>
          )}
        </>
      ),
      minWidth: "150px",
      sortable: true,
      sortField: "status",
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <Dropdown
            className="patient-status-btn"
            style={{ position: "initial" }}
          >
            {
              <Dropdown.Toggle
                disabled={!currentUserRole.editPatients}
                variant=""
                id="dropdown-basic"
              >
                {row?.status == "true"
                  ? "Active"
                  : row?.status == "false"
                  ? "Inactive"
                  : "Blocked"}
              </Dropdown.Toggle>
            }
            <Dropdown.Menu>
              <Dropdown.Item
                className={
                  row.status === "true" ? "label-success text-white" : ""
                }
                onClick={() => pateintStatus(row?._id, true)}
              >
                Active
              </Dropdown.Item>
              <Dropdown.Item
                className={
                  row.status === "blocked" ? "label-warning text-white" : ""
                }
                onClick={() => pateintStatus(row?._id, "blocked")}
              >
                Blocked
              </Dropdown.Item>
              <Dropdown.Item
                className={
                  row.status === "false" ? "label-danger text-white" : ""
                }
                onClick={() => pateintStatus(row?._id, false)}
              >
                Inactive
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
      minWidth: "150px",
      sortable: false,
    },
  ]);

  const handleUserStatus = (e) => {
    let val = e?.target?.value;
    setSelectedOption(val);
  };

  const filterDatatable = (text) => {
    setFilterText(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...patients]);
    setSelectedOption("");
  };

  const debouncedFilterDatatable = _debounce((text) => {
    setDebouncedText(text.trim());
  }, 500);

  useEffect(() => {
    debouncedFilterDatatable(filterText);
    return () => debouncedFilterDatatable.cancel(); // Cleanup debounce on unmount
  }, [filterText, debouncedFilterDatatable]);

  useEffect(() => {
    getAllPatientUsers(1);
  }, [selectedOption, debouncedText, sort, direction]); /////////

  useEffect(() => {
    if (patients) {
      setFilteredItems([...patients]);
    }
  }, [patients]);

  return (
    <>
      <div className="pt-3 pt-md-5">
        <Container fluid>
          <Row className="patients-user-wrap">
            {/* Start of filter container */}
            <Col md="12">
              <Card className="filter-card card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title className="" as="h4">
                      Filter
                    </Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 ">
                          Search with title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Search"
                          value={filterText}
                          onChange={(e) => filterDatatable(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with status
                        </Form.Label>
                        <select
                          value={selectedOption}
                          onChange={(e) => handleUserStatus(e)}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="blocked">Blocked</option>

                          <option value="false">Inactive</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                      <Form.Group>
                        <label className="d-none d-sm-block mb-2 form-label">
                          &nbsp;
                        </label>
                        <div className="d-flex  filter-btns-holder">
                          <button
                            type="button"
                            className="outline-button"
                            onClick={() => resetFilter()}
                          >
                            Reset
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* End of filled container */}

              {/* Start of table for medical type */}
              <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                <h4 className="card-title">Patient Users</h4>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                theme="solarized"
                pagination
                className="react-datatable"
                progressPending={loader}
                progressComponent={<FullPageLoader />}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                paginationPerPage={perPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PatientUser;
