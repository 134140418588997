import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { getLogsById } from "../../actions//LogsAction";

import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

import moment from "moment-timezone";

export default function ViewLogs() {
  const { id } = useParams();

  const [logsData, setLogsData] = useState({});

  const [loader, setLoader] = useState(true);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;

  // const convertDate = (dateString) => {
  //   const dateObject = new Date(dateString);
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return dateObject.toLocaleDateString("en-US", options);
  // };

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("MM-DD-YYYY");
  };

  const getAgeFromDate = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var ageDiff = today.getTime() - birthDate.getTime();
    var ageDate = new Date(ageDiff);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  };

  const getLogs = async () => {
    let res = await getLogsById(id);
    if (res?.data?.data) {
      setLogsData(res.data.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    getLogs();
  }, [id]);

  return (
    <>
      {loader ? (
        <FullPageLoader></FullPageLoader>
      ) : (
        <div className="pt-2 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Log Detail
                      </Card.Title>
                    </div>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
            <Table striped className="consultation-table mb-4">
              <tbody>
                <tr>
                  <th>Action</th>
                  <td>{logsData?.action}</td>
                  <th>name</th>
                  <td>{logsData?.userName}</td>
                </tr>
                <tr>
                  <th>Role</th>
                  <td>{logsData?.Role}</td>
                  <th>Email</th>
                  <td>{logsData?.email}</td>
                </tr>
                <tr>
                  <th>createdAt</th>
                  <td>{convertDate(logsData?.createdAt)}</td>
                </tr>
              </tbody>
            </Table>
            <b>Updated Details</b>
            <br></br>
            <br></br>
            <div>
              <p dangerouslySetInnerHTML={{ __html: logsData?.details }}></p>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
