import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getCmsAboutSection,
  deleteCmsAboutSectionById,
} from "../../../actions/cmsAboutPageAction";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const AboutPageSections = () => {
  const [CmsAboutSection, setCmsAboutSection] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const hideModel = () => {
    setDelete(false);
  };
  const deleteCmsAboutSection = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteCmsAboutSectionById(deleteId);
    if (res.data.success) {
      toast.success(res.data.message);
      getAllCmsAboutSection();
    }
    setLoader(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const getAllCmsAboutSection = async () => {
    setLoader(true);
    let res = await getCmsAboutSection();
    if (res.data.data.contentPages) {
      setCmsAboutSection(res.data.data.contentPages);
    }
    setLoader(false);
  };
  useEffect(() => {
    getAllCmsAboutSection();
  }, []);

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA?.heading?.toLowerCase();
    const b = rowB?.heading?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Heading",
      selector: (row) => row?.heading,
    },
    {
      name: "Slug",
      selector: (row) => row?.slug.toLowerCase(),
    },
    {
      name: "Sub Heading",
      selector: (row) => row?.sub_heading,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status ? (
            <label className="label label-success m-0">Active</label>
          ) : (
            <label className="label label-danger m-0">Inactive</label>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.editCmsAboutSection ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/cms/about/edit/${row._id}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success"
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={""}
                    type="button"
                    className="btn-link btn-icon btn btn-success disabled"
                    disabled
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            )}

            {currentUserRole?.deleteCmsAboutSection ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    onClick={() => showModel(row._id)}
                    type="button"
                    className="btn-link btn-icon btn btn-danger"
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    type="button"
                    className="btn-link btn-icon btn btn-danger disabled"
                    disabled
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),
      sortable: false,
    },
  ];
  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(selectedOption, text);
  };

  const filterSearchDatatable = (text, searchText) => {
    const filteredItemsResult = CmsAboutSection.filter((data) => {
      if (text) {
        if (
          data.status.toString().toLowerCase().includes(text.toLowerCase()) &&
          (data?.heading.toLowerCase().includes(searchText.toLowerCase()) ||
            data?.slug.toLowerCase().includes(searchText.toLowerCase()) ||
            data?.sub_heading.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return data;
        }
      } else if (
        data?.heading.toLowerCase().includes(searchText.toLowerCase()) ||
        data?.slug.toLowerCase().includes(searchText.toLowerCase()) ||
        data?.sub_heading.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return data;
      }
      return undefined;
    });
    setFilteredItems([...filteredItemsResult]);
    setSelectedOption(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...CmsAboutSection]);
    setSelectedOption("");
  };

  useEffect(() => {
    if (CmsAboutSection) {
      setFilteredItems([...CmsAboutSection]);
    }
  }, [CmsAboutSection]);
  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteCmsAboutSection}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with title...
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Filter with status...
                          </Form.Label>
                          <select
                            value={selectedOption}
                            onChange={(e) =>
                              filterSearchDatatable(e.target.value, filterText)
                            }
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">About Page Sections</h4>
                  {currentUserRole?.addCmsAboutSection ? (
                    <div className="btn-add">
                      <Link
                        className="float-sm-right btn-filled d-flex align-items-center"
                        to="/cms/about/add"
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add About
                      </Link>
                    </div>
                  ) : (
                    <div className="btn-add">
                      <Button
                        className="float-sm-right btn-filled d-flex align-items-center disabled"
                        disabled
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add About{" "}
                      </Button>
                    </div>
                  )}
                </div>

                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default AboutPageSections;
