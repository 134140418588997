import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const addForm = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/forms/create", data));
const deleteFormById = (id) =>
  PromiseHandler(apiHelper("post", `/api/admin/forms/delete/${id}`));
const getForms = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/forms/get", data));
const getFormById = (id, formType) =>
  PromiseHandler(
    apiHelper("get", `/api/admin/forms/get/${id}?formType=${formType}`)
  );
const updateFormById = (id, data) =>
  PromiseHandler(apiHelper("post", `/api/admin/forms/update/${id}`, data));
export { updateFormById, getFormById, getForms, deleteFormById, addForm };
