import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
import Index from "./views/Login";
import PageNotFound from "./views/404/PageNotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover={true}
        />
        <Routes>
          {routes.map((route, index) => (
            <React.Fragment key={index}>
              {route?.collapse ? (
                <React.Fragment>
                  {route?.submenus?.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        exact={true}
                        path={route?.path}
                        element={
                          <route.layout
                            permissionName={route?.permissionName}
                            title={route?.name}
                          >
                            <route.component />
                          </route.layout>
                        }
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                <Route
                  exact={true}
                  key={index}
                  path={route?.path}
                  element={
                    <route.layout
                      permissionName={route?.permissionName}
                      title={route?.name}
                    >
                      <route.component />
                    </route.layout>
                  }
                />
              )}
            </React.Fragment>
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
