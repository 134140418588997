import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getAllPrescription = () =>
  PromiseHandler(apiHelper("get", "/api/admin/prescription-refill/get"));
const getPrescriptionById = (id) =>
  PromiseHandler(apiHelper("get", `/api/admin/prescription-refill/get/${id}`));
const prescriptionUpdate = (id, data) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/prescription-refill/update/${id}`, data)
  );
const addPrescription = (data) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/prescription-refill/create`, data)
  );
const prescriptionDeleteById = (id) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/prescription-refill/delete/${id}`)
  );
const getConsultationAll = () =>
  PromiseHandler(apiHelper("get", "/api/admin/forms/consultation-all"));
const getConsultationAllActive = () =>
  PromiseHandler(apiHelper("get", "/api/admin/forms/consultation-all-active"));

export {
  getAllPrescription,
  getConsultationAll,
  getPrescriptionById,
  prescriptionUpdate,
  prescriptionDeleteById,
  addPrescription,
  getConsultationAllActive,
};
