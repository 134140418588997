import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="error-page">
      <div className="error-wraper">
        <h2>PAGE NOT FOUND</h2>
        <h1>
          4<span>0</span>4
        </h1>
        <p>Oops! The page you requested could not be found.</p>
        <div className="error-page-btn">
          <Link to="/" className="outline-button m-0">
            Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
