import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  // Table
} from "react-bootstrap";
import { getPatientUsers, updatePassword } from "../../actions/patientAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import _debounce from "lodash/debounce";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import "rc-pagination/assets/index.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InitializeLocalStorage from "../../components/PaginationHandle";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const PasswordManagement = () => {
  const [patients, setPatients] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [passwords, setPasswords] = useState({});
  const [filterText, setFilterText] = useState("");
  const [debouncedText, setDebouncedText] = useState(filterText);
  const [sort, setSort] = useState();
  const [direction, setDirection] = useState();

  const location = useLocation();
  const { pathname } = location;

  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();

      setSubmitting(false);
    },
  });

  const handlePasswordChange = (userId, newPassword) => {
    setPasswords(() => ({
      [userId]: newPassword,
    }));
  };

  const handlePageChange = (page) => {
    getAllPatientUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getAllPatientUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const getAllPatientUsers = async (page, limit = perPage) => {
    setLoader(true);
    let obj = {
      page,
      limit,
      userStatus: selectedOption,
      filterText: debouncedText,
      sort,
      direction,
    };
    let res = await getPatientUsers(obj);
    if (res?.data?.data?.user) {
      setTotalRows(res?.data?.data?.pagination?.total);
      setPatients(res?.data?.data?.user);
    }
    setLoader(false);
  };

  const updatePatientUsers = async (row) => {
    const patientId = row._id;

    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

    if (!passwords[patientId]) {
      toast.error("Please enter a password for the user before Updating.");
      return;
    }
    if (!passwordRegex.test(passwords[patientId])) {
      toast.error(
        "Password must contain at least one uppercase letter and be at least 8 characters long."
      );
      return;
    } else {
      setLoader(true);
      let res = await updatePassword(patientId, {
        currentPassword: passwords[patientId],
      });
      if (res?.data) {
        toast.success("password has been updated successfully.");
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllPatientUsers();
  }, [sort, direction]);

  const handleSort = (column, sortDirection) => {
    setSort(column.sortField);
    setDirection(sortDirection);
  };

  const columns = [
    {
      name: "Email",
      selector: (row) => row?.email,
      minWidth: "230px",
      sortable: true,
      sortField: "email",
    },
    {
      name: "Gender",
      selector: (row) => row?.gender,
      minWidth: "150px",
      sortable: true,
      sortField: "gender",
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status == "true" ? (
            <label className="label label-success m-0">Active</label>
          ) : row.status == "false" ? (
            <label className="label label-danger m-0">Inactive</label>
          ) : (
            <label className="label label-warning m-0">Blocked</label>
          )}
        </>
      ),
      minWidth: "150px",
      sortable: true,
      sortField: "status",
    },

    {
      name: "Password",
      cell: (row) => (
        <div className="filter-field input">
          <input
            type="text"
            placeholder="Password"
            onChange={(e) => handlePasswordChange(row._id, e.target.value)}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          disabled={!currentUserRole?.updatePassword}
          className={"bg_blue"}
          onClick={() => updatePatientUsers(row)}
        >
          Update
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleUserStatus = (e) => {
    let val = e?.target?.value;
    setSelectedOption(val);
  };

  const filterDatatable = (text) => {
    setFilterText(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...patients]);
    setSelectedOption("");
  };

  const debouncedFilterDatatable = _debounce((text) => {
    setDebouncedText(text.trim());
  }, 500);

  useEffect(() => {
    debouncedFilterDatatable(filterText);
    return () => debouncedFilterDatatable.cancel(); // Cleanup debounce on unmount
  }, [filterText, debouncedFilterDatatable]);

  useEffect(() => {
    getAllPatientUsers(1);
  }, [selectedOption, debouncedText, sort, direction]);

  useEffect(() => {
    if (patients) {
      setFilteredItems([...patients]);
    }
  }, [patients]);

  return (
    <>
      <div className="pt-3 pt-md-5 password-mangement-fieldmin-width">
        <Container fluid>
          <Row>
            {/* Start of filter container */}
            <Col md="12">
              <Card className="filter-card card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title className="" as="h4">
                      Filter
                    </Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 ">
                          Search with title...
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Search"
                          value={filterText}
                          onChange={(e) => filterDatatable(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with status...
                        </Form.Label>
                        <select
                          value={selectedOption}
                          onChange={(e) => handleUserStatus(e)}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                      <Form.Group>
                        <label className="d-none d-sm-block mb-2 form-label">
                          &nbsp;
                        </label>
                        <div className="d-flex  filter-btns-holder">
                          <button
                            type="button"
                            className="outline-button"
                            onClick={() => resetFilter()}
                          >
                            Reset
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                <h4 className="card-title">Password Management</h4>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                theme="solarized"
                pagination
                className="react-datatable"
                progressPending={loader}
                progressComponent={<FullPageLoader />}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                paginationPerPage={perPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PasswordManagement;
