import React, { useState } from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import { addMedicalType } from "../../../actions/medicalTypeAction";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title must have at least 2 characters")
    .trim("Spaces are not allowed")
    .required("Title is required"),
  photo: Yup.string().required("Photo field is required"),
  status: Yup.string().required("Status field is required"),
});
const AddMedicalType = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      title: "",
      photo: "",
      status: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("photo", values.photo);
      formData.append("status", values.status);
      setLoader(true);
      let res = await addMedicalType(formData);
      if (res?.data?.success) {
        navigate("/forms/medical-type");
        toast.success(res?.data?.message);
      }
      setLoader(false);
    },
  });
  const [getFile, setFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const handlePreviewImage = (e, setFieldValue) => {
    if (e.target.files[0].type.split("/")[1]) {
      let imageType = e.target.files[0].type.split("/")[1];
      if (
        imageType === "png" ||
        imageType === "jpg" ||
        imageType === "jpeg" ||
        imageType === "gif" ||
        imageType === "webp"
      ) {
        setFieldValue("photo", e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        setUploadImage(true);
        e.target.value = null;
      }
    }
  };

  const hideModel = () => {
    setUploadImage(false);
  };

  return (
    <>
      {uploadImage ? (
        <SweetAlert
          warning
          showCloseButton
          confirmBtnText="OK"
          onConfirm={hideModel}
          confirmBtnBsStyle="danger"
          title="Failed! Please select correct Image Format"
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert "
        >
          Supported files ["png", "jpg", "jpeg", "gif", "webp"]
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/forms/medical-type">
                    Medical Type
                  </Link>
                  <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Add Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              placeholder="Enter tile"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <span
                                className={formik.errors.title ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.title}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            controlId="formFile"
                            className="mb-3"
                            onChange={(e) =>
                              handlePreviewImage(e, formik.setFieldValue)
                            }
                          >
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control
                              type="file"
                              name="photo"
                              accept=".png,.jpg,.jpeg,.webp"
                            />
                            {formik.touched.photo && formik.errors.photo ? (
                              <span
                                className={formik.errors.photo ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.photo}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            controlId="formFilePreview"
                            className="mb-3"
                          >
                            {getFile ? (
                              <img
                                src={getFile}
                                width="200"
                                height="200"
                                alt="not found"
                              />
                            ) : null}
                          </Form.Group>
                          <Row>
                            <Col md={1}>
                              <label className="label-font">Status</label>
                            </Col>
                            <Col
                              md={11}
                              className="check-inline d-flex mt-3 flex-column"
                            >
                              <label className="right-label-radio mr-3 mb-2">
                                Active
                                <input
                                  name="status"
                                  className="status"
                                  checked={formik.values.status}
                                  value={formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", true)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="right-label-radio mr-3 mb-2">
                                Inactive
                                <input
                                  name="status1"
                                  className="status"
                                  checked={!formik.values.status}
                                  value={!formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", false)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Col>
                          </Row>
                          <Button className="btn-filled" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default AddMedicalType;
