import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { getMedicalTypesActive } from "../../../actions/medicalTypeAction";
import {
  updateMedicalConsultation,
  getMedicalConsultationById,
} from "../../../actions/medicalConsultationAction";
import { getPartnersDiscount } from "../../../actions/partnerDiscountAction";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { ENV } from "../../../config/config";

// Schema for yup
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title must have at least 2 characters")
    .max(100, "Title can't be longer than 100 characters")
    .trim("Spaces are not allowed")
    .required("Title is required"),
  shortDescription: Yup.string()
    .trim()
    .required("Short description field is required"),
  price: Yup.number().required("Price field is required").positive(),
  minAge: Yup.number()
    .required("Minimum age field is required")
    .positive()
    .min(18, "Ming age must be 18 or greater"),
  medicalType: Yup.string().required("Please select medical type"),
  discount: Yup.number()
    .required("Discount field is required")
    .min(0, "discount must be zero or greater"),
  photo: Yup.string().required("Photo field is required"),
  description: Yup.string().required("Description field is required"),
});

const EditMedicalConsultation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [medicalType, setMedicalType] = useState({});
  const [medicalConsultation, setMedicalConsultation] = useState({});
  const [partnerDiscountData, setPartnerDiscountData] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [imageExists, setImageExists] = useState(true);

  const setImage = (img) => {
    return `${ENV.serverUrl}/public/${img}`;
  };

  const getAllPartnerDiscount = async () => {
    setLoader(true);
    let res = await getPartnersDiscount();
    if (res?.data?.data) {
      setPartnerDiscountData(res?.data?.data?.partners);
    }
    setLoader(false);
  };
  const [getFile, setFile] = useState("");
  const handlePreviewImage = (e, setFieldValue) => {
    if (e.target.files[0].type.split("/")[1]) {
      let imageType = e.target.files[0].type.split("/")[1];
      if (
        imageType === "png" ||
        imageType === "jpg" ||
        imageType === "jpeg" ||
        imageType === "gif" ||
        imageType === "webp"
      ) {
        setFieldValue("photo", e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        setUploadImage(true);
        e.target.value = null;
      }
    }
  };

  const handleImageError = () => {
    setImageExists(false);
  };

  const hideModel = () => {
    setUploadImage(false);
  };

  const onEditorChange = async (event, editor, setFieldValue) => {
    setFieldValue("description", editor.getData());
  };
  const getAllMedicalTypes = async () => {
    setLoader(true);
    let res = await getMedicalTypesActive();
    if (res.data.data) {
      setMedicalType(res.data.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    const getMedicalConsultationId = async () => {
      setLoader(true);
      let res = await getMedicalConsultationById(id);
      if (res.data.data) {
        let data = res.data.data;
        data.medicalType = data.medicalType?._id;
        data.partnerDiscountType = data?.partnerDiscountType?._id;
        setMedicalConsultation(data);
      }
      setLoader(false);
    };
    getMedicalConsultationId();
    getAllMedicalTypes();
    getAllPartnerDiscount();
  }, [id]);
  return (
    <>
      {uploadImage ? (
        <SweetAlert
          warning
          showCloseButton
          confirmBtnText="OK"
          onConfirm={hideModel}
          confirmBtnBsStyle="danger"
          title="Failed! Please select correct Image Format"
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert "
        >
          Supported files ["png", "jpg", "jpeg", "gif", "webp"]
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link
                    className="breadcrumb-item"
                    to="/forms/medical-consultations"
                  >
                    Medical Consultation
                  </Link>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Edit Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Formik
                          enableReinitialize
                          initialValues={medicalConsultation}
                          validationSchema={validationSchema}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            let formData = new FormData();
                            formData.append("title", values.title);
                            formData.append(
                              "shortDescription",
                              values.shortDescription
                            );
                            formData.append("medicalType", values.medicalType);
                            formData.append("discount", values.discount);
                            formData.append("minAge", values.minAge);
                            formData.append("description", values.description);
                            formData.append("status", values.status);
                            formData.append("price", values.price);
                            formData.append(
                              "partnerDiscountType",
                              values.partnerDiscountType
                            );

                            if (getFile) {
                              formData.append("photo", values.photo);
                            }
                            setLoader(true);
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true);
                            let res = await updateMedicalConsultation(
                              id,
                              formData
                            );
                            if (res.data.success) {
                              navigate("/forms/medical-consultations");
                              toast.success(res.data.message);
                            }
                            setLoader(false);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicTitle"
                              >
                                <Form.Label>
                                  Title <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="title"
                                  placeholder="Enter title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title}
                                />
                                {touched.title && errors.title ? (
                                  <span
                                    className={errors.title ? `` : `d-none`}
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.title}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicTitle"
                              >
                                <Form.Label>
                                  Short Description{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="shortDescription"
                                  placeholder="Enter title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.shortDescription}
                                />
                                {touched.shortDescription &&
                                errors.shortDescription ? (
                                  <span
                                    className={
                                      errors.shortDescription ? `` : `d-none`
                                    }
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.shortDescription}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={
                                    values.description ? values.description : ""
                                  }
                                  content={
                                    values.description ? values.description : ""
                                  }
                                  onChange={(event, editor) =>
                                    onEditorChange(event, editor, setFieldValue)
                                  }
                                />
                                {touched.description && errors.description ? (
                                  <span
                                    className={
                                      errors.description ? `` : `d-none`
                                    }
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.description}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                controlId="formFile"
                                className="mb-3"
                                onChange={(e) =>
                                  handlePreviewImage(e, setFieldValue)
                                }
                              >
                                <Form.Label>
                                  Upload Image{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  name="photo"
                                  accept=".png,.jpg,.jpeg,.webp"
                                />
                                {touched.photo && errors.photo ? (
                                  <span
                                    className={errors.photo ? `` : `d-none`}
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.photo}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                controlId="formFilePreview"
                                className="mb-3"
                              >
                                {getFile ? (
                                  <img
                                    src={getFile}
                                    width="200"
                                    height="200"
                                    alt="not found"
                                  />
                                ) : imageExists ? (
                                  <img
                                    src={setImage(values?.photo)}
                                    onError={handleImageError}
                                    crossOrigin="anonymous"
                                    width="200"
                                    height="200"
                                    alt="not found"
                                  />
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicMedicalType"
                              >
                                <Form.Label>
                                  Medical Type{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <Form.Control
                                  as="select"
                                  name="medicalType"
                                  value={values.medicalType}
                                  onChange={(e) =>
                                    setFieldValue("medicalType", e.target.value)
                                  }
                                >
                                  <option value="">Select medical type</option>
                                  {medicalType && medicalType.length > 0
                                    ? medicalType.map((d, k) => {
                                        return (
                                          <option
                                            key={d._id}
                                            value={d._id}
                                            className="text-capitalize"
                                          >
                                            {d.title}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Form.Control>
                                {touched.medicalType && errors.medicalType ? (
                                  <span
                                    className={
                                      errors.medicalType ? `` : `d-none`
                                    }
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.medicalType}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicpartnerDiscountType"
                              >
                                <Form.Label>Partner Discount </Form.Label>

                                <Form.Control
                                  as="select"
                                  name="partnerDiscountType"
                                  value={values.partnerDiscountType}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "partnerDiscountType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">
                                    Select partner discount
                                  </option>
                                  {partnerDiscountData &&
                                  partnerDiscountData.length > 0
                                    ? partnerDiscountData?.map((d, k) => {
                                        return (
                                          <option
                                            key={d._id}
                                            value={d._id}
                                            className="text-capitalize"
                                          >
                                            {d.title}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPrice"
                              >
                                <Form.Label>
                                  Price <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="price"
                                  placeholder="Enter Price"
                                  value={values.price}
                                  onChange={(e) => handleChange(e)}
                                />
                                {touched.price && errors.price ? (
                                  <span
                                    className={errors.price ? `` : `d-none`}
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.price}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Discount on Price
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="discount"
                                  placeholder="Enter discount"
                                  value={values.discount}
                                  onChange={(e) => handleChange(e)}
                                />
                                {touched.discount && errors.discount ? (
                                  <span
                                    className={errors.discount ? `` : `d-none`}
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.discount}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Min Age for Consultation (Years)
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="minAge"
                                  placeholder="Enter ming age"
                                  value={values.minAge}
                                  onChange={(e) => handleChange(e)}
                                />
                                {touched.minAge && errors.minAge ? (
                                  <span
                                    className={errors.minAge ? `` : `d-none`}
                                  >
                                    <label className="pl-1 text-danger">
                                      {errors.minAge}
                                    </label>
                                  </span>
                                ) : null}
                              </Form.Group>
                              <Row>
                                <Col md={1}>
                                  <label className="label-font">Status</label>
                                </Col>
                                <Col
                                  md={11}
                                  className="check-inline d-flex mt-3 flex-column"
                                >
                                  <label className="right-label-radio mr-3 mb-2">
                                    Active
                                    <input
                                      name="status"
                                      className="status"
                                      checked={values.status}
                                      value={values.status}
                                      type="radio"
                                      onChange={(e) =>
                                        setFieldValue("status", true)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>

                                  <label className="right-label-radio mr-3 mb-2">
                                    Inactive
                                    <input
                                      name="status1"
                                      className="status"
                                      checked={!values.status}
                                      value={!values.status}
                                      type="radio"
                                      onChange={(e) =>
                                        setFieldValue("status", false)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                              </Row>

                              <Button className="btn-filled" type="submit">
                                Update
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default EditMedicalConsultation;
