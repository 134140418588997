import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Button,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getForms,
  deleteFormById,
  updateFormById,
} from "../../../actions/formsAction";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import _debounce from "lodash/debounce";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const PartnerDiscount = () => {
  const [formData, setFormData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [debouncedText, setDebouncedText] = useState(filterText);
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const location = useLocation();
  const { pathname } = location;

  console.log("pathname", pathname);

  useLayoutEffect(() => {
    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
      localStorage.setItem("limit", 10);
      // localStorage.setItem("searchText", "");
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // localStorage.removeItem("limit");
      // localStorage.removeItem("page");
      // localStorage.removeItem("currentURL");
      // localStorage.removeItem("searchText");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const hideModel = () => {
    setDelete(false);
  };

  const formStatus = async (id, status) => {
    let obj = {
      status: status,
    };
    let res = await updateFormById(id, obj);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
      getAllForms();
    }
  };

  const deleteForm = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteFormById(deleteId);
    if (res.data.success) {
      toast.success(res.data.message);
      getAllForms();
    }
    setLoader(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const getAllForms = async (page, limit = perPage) => {
    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
      localStorage.setItem("limit", 10);
      localStorage.setItem("searchText", "");
    }

    var storedPage = localStorage.getItem("page");
    var storedLimit = localStorage.getItem("limit");

    if (storedPage === undefined || storedPage === null) {
      page = 1;
    } else {
      page = parseInt(storedPage);
    }
    if (storedLimit === undefined || storedLimit === null) {
      limit = 10;
    } else {
      limit = parseInt(storedLimit);
    }
    window.localStorage.setItem("currentURL", pathname);

    setCurrentPage(page);
    setPerPage(limit);
    setLoader(true);

    const obj = {
      page,
      limit,
      filterText: debouncedText,
      selectedOption,
    };

    let res = await getForms(obj);

    if (res?.data?.data) {
      setFormData(res.data.data?.forms);
      setTotalRows(res?.data?.data?.pagination?.total);
    }
    setLoader(false);
  };

  const handlePageChange = (page) => {
    console.log("handlePageChange", page);
    localStorage.setItem("page", page);
    getAllForms(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("handlePerRowsChange newPerPage", newPerPage);

    console.log("handlePerRowsChange", page);

    localStorage.setItem("limit", newPerPage);
    localStorage.setItem("page", page);
    getAllForms(page, newPerPage);
    setPerPage(newPerPage);
  };

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const getProperty = (row, columnId) => {
      // Helper function to get the property value based on the columnId
      switch (columnId) {
        case "Name":
          return row.title?.toLowerCase() || "";
        case "Form Type":
          return row.formType?.toLowerCase() || "";

        case "Status":
          return row.status || "";

        default:
          return "";
      }
    };
    const a = getProperty(rowA, columnId);
    const b = getProperty(rowB, columnId);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Name"),
    },
    {
      name: "Disease Name",
      selector: (row) => row?.formConsultation?.title,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "diseaseName"),
    },
    {
      name: "Form Type",
      selector: (row) => (
        <>
          {" "}
          {row.formType === "consultationForm" ? (
            <label className="label label-success m-0">Consultation Form</label>
          ) : row.formType === "STD" ? (
            <label className="label label-info m-0">STD</label>
          ) : row.formType === "prescriptionRefill" ? (
            <label className="label label-danger m-0">
              Prescription Refill
            </label>
          ) : (
            <label className="label label-danger m-0">Consultation Form</label>
          )}
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Form Type"),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {" "}
          {row.status === true ? (
            <label className="label label-success m-0">Active</label>
          ) : (
            <label className="label label-danger m-0">Inactive</label>
          )}
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Status"),
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.editForms ? (
              <li className="d-inline-block align-top">
                <Dropdown
                  className="patient-status-btn"
                  style={{ position: "initial" }}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {row?.status === true ? "Active" : "Inactive"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={
                        row.status === true ? "label-success text-white" : ""
                      }
                      onClick={() => formStatus(row?._id, true)}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        row.status === false ? "label-danger text-white" : ""
                      }
                      onClick={() => formStatus(row?._id, false)}
                    >
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <Dropdown
                  className="patient-status-btn"
                  style={{ position: "initial" }}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic" disabled>
                    {row?.status ? "Active" : "Inactive"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}

            {currentUserRole?.editForms ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/forms/edit/${row._id}?formType=${row?.formType}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success "
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={""}
                    type="button"
                    className="btn-link btn-icon btn btn-success disabled"
                    disabled
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            )}
            {currentUserRole?.deleteForms ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    onClick={() => showModel(row._id)}
                    type="button"
                    className="btn-link btn-icon btn btn-danger"
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    type="button"
                    className="btn-link btn-icon btn btn-danger disabled"
                    disabled
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),
      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
  };
  const handleSelectChangePrescription = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
    filterSearchDatatable();
  };

  const filterSearchDatatable = (text) => {
    if (text === undefined) {
      text = "";
    }

    const filteredItemsResult = formData.filter((data) => {
      const isSearchMatch = dataMatchesSearch(data, text);

      return isSearchMatch;
    });
    setFilteredItems([...filteredItemsResult]);
  };

  const dataMatchesSearch = (data, text) => {
    text = text && text.trim() !== "" ? text : filterText;
    return (
      !text ||
      data?.title?.toLowerCase().includes(text.toLowerCase()) ||
      data?.formType?.formType?.toString().includes(text.toLowerCase()) ||
      data.status.toString().toLowerCase().includes(text.toLowerCase())
    );
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...formData]);
    setSelectedOption("");
  };

  const debouncedFilterDatatable = _debounce((text) => {
    setDebouncedText(text.trim());
  }, 500);

  useEffect(() => {
    debouncedFilterDatatable(filterText);
    return () => debouncedFilterDatatable.cancel(); // Cleanup debounce on unmount
  }, [filterText, debouncedFilterDatatable]);

  useEffect(() => {
    if (formData) {
      setFilteredItems([...formData]);
    }
  }, [formData]);

  useEffect(() => {
    filterSearchDatatable();
    getAllForms();
  }, [selectedOption, debouncedText]);

  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteForm}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5 genertic-customform-width forms-admin-tablelayout">
          <Container fluid>
            <Row className="medical-typeuser-wrap">
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with title...
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Filter with status...
                          </Form.Label>
                          <select
                            value={selectedOption}
                            onChange={(e) => handleSelectChangePrescription(e)}
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">Forms</h4>
                  {currentUserRole?.addForms ? (
                    <div className="btn-add">
                      <Link
                        className="float-sm-right btn-filled d-flex align-items-center"
                        to="/forms/create"
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Form
                      </Link>
                    </div>
                  ) : (
                    <div className="btn-add">
                      <Button
                        className="float-sm-right btn-filled d-flex align-items-center disabled"
                        disabled
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Prescription Refill{" "}
                      </Button>
                    </div>
                  )}
                </div>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable"
                  progressPending={loader}
                  progressComponent={<FullPageLoader />}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default PartnerDiscount;
