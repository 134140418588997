import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getBlogCategoryById,
  updateBlogCategoryById,
} from "../../actions/blogCategoryAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
// Schema for yup
const blogCategorySchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, "Title must have at least 2 characters")
    .required("Title field is required"),
  status: Yup.string().required("Email field is required"),
});
const EditBlogCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [blogCategoryData, setBlogCategoryData] = useState({});
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: blogCategoryData,
    validationSchema: blogCategorySchema,
    onSubmit: async (values) => {
      setLoader(true);
      let res = await updateBlogCategoryById(id, values);
      if (res.data.success) {
        navigate("/blog-category");
        toast.success(res.data.message);
      }
      setLoader(false);
    },
  });

  useEffect(() => {
    const getBlogCategory = async () => {
      setLoader(true);
      let res = await getBlogCategoryById(id);
      if (res?.data?.success) {
        setBlogCategoryData(res?.data.data);
      }
      setLoader(false);
    };
    getBlogCategory();
  }, [id]);
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/blog-category">
                    Blog Categories
                  </Link>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Edit Blog Category
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicusername"
                          >
                            <Form.Label>
                              Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              value={formik.values.title}
                              placeholder="Enter title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <span
                                className={formik.errors.title ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.title}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Row>
                            <Col md={1}>
                              <label className="label-font">Status</label>
                            </Col>
                            <Col
                              md={11}
                              className="check-inline d-flex mt-3 flex-column"
                            >
                              <label className="right-label-radio mr-3 mb-2">
                                Active
                                <input
                                  name="status"
                                  className="status"
                                  checked={formik.values.status}
                                  value={formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", true)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="right-label-radio mr-3 mb-2">
                                Inactive
                                <input
                                  name="status1"
                                  className="status"
                                  checked={!formik.values.status}
                                  value={!formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", false)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Col>
                          </Row>
                          <Button className="btn-filled" type="submit">
                            Update
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default EditBlogCategory;
