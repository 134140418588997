import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import QRCode from "qrcode.react";
import Modal from "react-bootstrap/Modal";
import { updateAdminProfile, updatePassword } from "../../actions/adminAction";
import { useState } from "react";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { toast } from "react-toastify";
import { LOGIN_ADMIN } from "../../redux/actionTypes";
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import eye icons from a popular icon library
import { images } from "../../assets/images/images.jpeg";
import { ENV } from "../../config/config";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import InitializeLocalStorage from "../../components/PaginationHandle";
const userDefaultImg =
  "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658297126/hex-nft/assets/default-profile_captlc.png";

const ProfileSettingsSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .min(2, "Username must have at least 3 characters")
    .required("Name field is required"),
});

const Index = () => {
  const [qrCodeData, setQrCodeData] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { admin } = useSelector((state) => state?.admin);
  const currentAdmin = admin;
  const [loader, setLoader] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [getFile, setFile] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const generateTOTPURI = (issuer, label, secret) => {
    return `otpauth://totp/${encodeURIComponent(issuer)}:${encodeURIComponent(
      label
    )}?secret=${secret}&issuer=${encodeURIComponent(issuer)}`;
  };
  // Inside your useEffect hook
  useEffect(() => {
    if (admin && admin.twoFactorAuthSecret) {
      const totpURI = generateTOTPURI("Email", admin.email);
      setQrCodeData(totpURI);
    }
  }, [admin]);

  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const newToggleShowPassword = () => {
    setNewShowPassword(!newShowPassword);
  };
  const conformtoggleShowPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentAdmin,
    validationSchema: ProfileSettingsSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("twoFactorAuthEnabled", values.twoFactorAuthEnabled);

      if (getFile) formData.append("photo", values.photo);
      setLoader(true);
      let res = await updateAdminProfile(currentAdmin?._id, formData);
      if (res?.data?.success) {
        dispatch({
          type: LOGIN_ADMIN,
          payload: res?.data.admin,
        });
        ENV.encryptUserData(res?.data);
        toast.success(res.data.message);
      }
      setLoader(false);
    },
  });

  const handlePreviewImage = (e, setFieldValue) => {
    if (e.target.files[0].type.split("/")[1]) {
      let imageType = e.target.files[0].type.split("/")[1];
      if (
        imageType === "png" ||
        imageType === "jpg" ||
        imageType === "jpeg" ||
        imageType === "gif" ||
        imageType === "webp"
      ) {
        setFieldValue("photo", e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        setUploadImage(true);
        e.target.value = null;
      }
    }
  };

  const hideModel = () => {
    setUploadImage(false);
  };

  useEffect(() => {
    if (currentAdmin) {
      if (currentAdmin?.photo) {
        setFile(`${ENV.serverUrl}/public/${currentAdmin?.photo}`);
      }
    }
  }, [currentAdmin]);

  const updatePasswordSchema = Yup.object().shape({
    passwordCurrent: Yup.string()
      .trim()
      .required("Current Password field is required"),
    newPassword: Yup.string()
      .trim()
      .min(8, "At least 8 characters")
      .required("New Password field is required"),
    confirmPassword: Yup.string()
      .trim()
      .min(8, "At least 8 characters")
      .required("Confirm Password field is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });
  const iniState = {
    passwordCurrent: "",
    newPassword: "",
    confirmPassword: "",
  };
  const formik2 = useFormik({
    enableReinitialize: true,
    initialValues: iniState,
    validationSchema: updatePasswordSchema,
    onSubmit: async (values) => {
      setLoader(true);
      let res = await updatePassword(currentAdmin?._id, values);
      toast.error(res?.message);
      if (res?.data?.success) {
        toast.success("Password Updated successfully!");
      }
      setLoader(false);
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose} className="switch-modal-wraper">
        <Modal.Header className="justify-content-center">
          <Modal.Title className="mt-0 mb-0">
            Scan Code or Enter Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Scan the code below with your authenticator app to add this account.
            Some authenticator apps also allow you to type in the text version
            instead.
          </p>
          {qrCodeData && (
            <div className="text-center custom-qr-code-wraper">
              <QRCode value={qrCodeData} />
              <p>{admin.twoFactorAuthSecret}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="outline-button btn btn-primary"
            onClick={handleClose}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
      {uploadImage ? (
        <SweetAlert
          warning
          showCloseButton
          confirmBtnText="OK"
          onConfirm={hideModel}
          confirmBtnBsStyle="danger"
          title="Failed! Please select correct Image Format"
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert "
        >
          Supported files ["png", "jpg", "jpeg", "gif", "webp"]
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <div className="section-image">
            <Container>
              <Row className="mb-4">
                <Col xl="8" className="profile-info-order">
                  <Form
                    action=""
                    className="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Card className="pb-4 table-big-boy mb-xl-0 mb-4">
                      <Card.Header>
                        <Card.Header className="pl-0">
                          <Card.Title as="h4">Profile</Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Name<span className="staric-lightred"> *</span>
                              </label>
                              <Form.Control
                                name="username"
                                value={formik?.values?.username}
                                placeholder="enter name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="text"
                              ></Form.Control>
                              {formik.touched.username &&
                              formik.errors.username ? (
                                <span
                                  className={
                                    formik.errors.username ? `` : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik.errors.username}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col className="pl-3" md="6">
                            <Form.Group>
                              <label>Email</label>
                              <Form.Control
                                className="input-bg"
                                name="email"
                                value={formik?.values?.email}
                                placeholder="Email"
                                type="email"
                                disabled={true}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Role</label>
                              <Form.Control
                                className="input-bg"
                                readOnly
                                value={formik?.values?.role?.title}
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Form>
                              <label>Two Factor Authentication</label>
                              <Form.Check
                                type="switch"
                                className="custom-on-switch"
                                checked={formik?.values?.twoFactorAuthEnabled}
                                onClick={() => {
                                  if (!formik?.values?.twoFactorAuthEnabled) {
                                    handleShow();
                                  }
                                }}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "twoFactorAuthEnabled",
                                    !formik?.values?.twoFactorAuthEnabled
                                  )
                                }
                                id="custom-switch"
                              />
                            </Form>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-end align-items-center">
                              <Button className="btn-filled" type="submit">
                                Update Profile
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
                <Col xl="4">
                  <Card className="card-user custom-recommened-card table-big-boy big-boy-height">
                    <Card.Body>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={getFile ? getFile : userDefaultImg}
                        />
                        <Card.Title as="h5">
                          {/* {formik?.values?.username} */}
                        </Card.Title>
                        <p className="card-description"></p>
                      </div>

                      <Form className="profile-main-form">
                        <Form.Group className="pl-3 pr-3">
                          <div className="input-file-btn-holder ">
                            <Form.Control
                              className="text-white"
                              placeholder="Company"
                              onChange={(e) =>
                                handlePreviewImage(e, formik.setFieldValue)
                              }
                              type="file"
                              varient="info"
                              accept=".png,.jpeg,.jpg"
                              id="imageUploader"
                              name="photo"
                            ></Form.Control>
                            <span className="input-file-btn">Choose Image</span>
                          </div>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                    <p className="recommened-size">
                      Note: Recommened image resolution is 100x100 pixels
                    </p>
                    <Card.Footer>
                      <div className="pb-2"></div>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Form className="form" onSubmit={formik2.handleSubmit}>
                    <Card className="table-big-boy pb-4">
                      <Card.Header>
                        <Card.Header className="pl-0">
                          <Card.Title as="h4">Change Password</Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md="4">
                            <Form.Group className="position-relative">
                              <label>
                                Current Password
                                <span className="staric-lightred"> *</span>
                              </label>
                              <Form.Control
                                placeholder="Current Password"
                                value={formik2.values.passwordCurrent}
                                type={showPassword ? "text" : "password"}
                                name="passwordCurrent"
                                onChange={formik2.handleChange}
                                className="pr-4"
                              ></Form.Control>
                              <div className="input-group-append append-custom-eye">
                                <span className="" onClick={toggleShowPassword}>
                                  {showPassword ? <FiEyeOff /> : <FiEye />}
                                </span>
                              </div>
                              {formik2.touched.passwordCurrent &&
                              formik2.errors.passwordCurrent ? (
                                <span
                                  className={
                                    formik2.errors.passwordCurrent
                                      ? ``
                                      : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik2.errors.passwordCurrent}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group className="position-relative">
                              <label>
                                New Password
                                <span className="staric-lightred"> *</span>
                              </label>
                              <Form.Control
                                placeholder="New Password"
                                value={formik2.values.newPassword}
                                type={newShowPassword ? "text" : "password"}
                                name="newPassword"
                                onChange={formik2.handleChange}
                                className="pr-4"
                              ></Form.Control>
                              <div className="input-group-append append-custom-eye">
                                <span
                                  className=""
                                  onClick={newToggleShowPassword}
                                >
                                  {newShowPassword ? <FiEyeOff /> : <FiEye />}
                                </span>
                              </div>
                              {formik2.touched.newPassword &&
                              formik2.errors.newPassword ? (
                                <span
                                  className={
                                    formik2.errors.newPassword ? `` : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik2.errors.newPassword}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group className="position-relative">
                              <label>
                                Confirm Password
                                <span className="staric-lightred"> *</span>
                              </label>
                              <Form.Control
                                placeholder="Confirm Password"
                                value={formik2.values.confirmPassword}
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                onChange={formik2.handleChange}
                                className="pr-4"
                              ></Form.Control>
                              <div className="input-group-append append-custom-eye">
                                <span
                                  className=""
                                  onClick={conformtoggleShowPassword}
                                >
                                  {showConfirmPassword ? (
                                    <FiEyeOff />
                                  ) : (
                                    <FiEye />
                                  )}
                                </span>
                              </div>
                              {formik2.touched.confirmPassword &&
                              formik2.errors.confirmPassword ? (
                                <span
                                  className={
                                    formik2.errors.confirmPassword
                                      ? ``
                                      : `d-none`
                                  }
                                >
                                  <label className="pl-1 text-danger">
                                    {formik2.errors.confirmPassword}
                                  </label>
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row></Row>
                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-end align-items-center">
                              <Button className="btn-filled" type="submit">
                                Update Password
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      )}
    </>
  );
};

export default Index;
