import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getAllSiteSettings = () =>
  PromiseHandler(apiHelper("get", "/api/admin/settings/show-admin"));
const createSiteSettings = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/settings/create", data));
const getSiteSettingsById = (id) =>
  PromiseHandler(
    apiHelper("get", `/api/admin/settings/getSiteSettingsById/${id}`)
  );
const updateSiteSettings = (id, data) =>
  PromiseHandler(apiHelper("post", `/api/admin/settings/update/${id}`, data));
const deleteSiteSettings = (id) =>
  PromiseHandler(apiHelper("delete", `/api/admin/settings/delete/${id}`));
export {
  deleteSiteSettings,
  updateSiteSettings,
  getSiteSettingsById,
  createSiteSettings,
  getAllSiteSettings,
};
