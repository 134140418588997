import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const adminLogin = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/user/login", data));
const updateAdminProfile = (id, data) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/user/admin-user-update/${id}`, data)
  );
const verify2FA = (data) =>
  PromiseHandler(apiHelper("post", `/api/admin/user/verify/2fa`, data));
const updatePassword = (id, data) =>
  PromiseHandler(apiHelper("post", `/api/admin/user/update/${id}`, data));
const getAdminStats = () =>
  PromiseHandler(apiHelper("get", `/api/admin/user/get-admin-stats`));
const getAdminDashboard = () =>
  PromiseHandler(apiHelper("get", `/api/admin/user/get-admin-dashboard`));

export {
  verify2FA,
  updateAdminProfile,
  adminLogin,
  getAdminStats,
  updatePassword,
  getAdminDashboard,
};
