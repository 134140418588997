import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

function AdminFooter() {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <p className="copyright text-center all-right-reserved">
              {" "}
              ©
              <Link to="/">
                {year} By <span>The Pulse CareMD</span>. All Rights Reserved.
              </Link>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
