// layouts
import Auth from "./layouts/Auth";
import Admin from "./layouts/Admin";

// Auth Components
import Login from "./views/Login";

// Private Components
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import Roles from "./views/Roles";
import MedicalType from "./views/Forms/MedicalType/MedicalType";
import AddMedicalType from "./views/Forms/MedicalType/AddMedicalType";
import EditMedicalType from "./views/Forms/MedicalType/EditMedicalType";
import MedicalConsultations from "./views/Forms/MedicalConsultation/MedicalConsultations";
import AddMedicalConsultation from "./views/Forms/MedicalConsultation/AddMedicalConsultation";
import EditMedicalConsultation from "./views/Forms/MedicalConsultation/EditMedicalConsultation";
import AdminUser from "./views/AdminUser";
import AddAdminUser from "./views/AdminUser/AddAdminUser";
import EditAdminUser from "./views/AdminUser/EditAdminUser";

import CmsHomeSections from "./views/CmsPages/Home/index";
import AddCmsHomeSection from "./views/CmsPages/Home/AddHomeSection";
import EditCmsHomeSection from "./views/CmsPages/Home/EditHomeSection";

import CmsAboutSections from "./views/CmsPages/About/index";
import AddCmsAboutSection from "./views/CmsPages/About/AddAboutSection";
import EditCmsAboutSection from "./views/CmsPages/About/EditAboutSection";

import CmsFaqSections from "./views/CmsPages/Faqs/index";
import AddCmsFaqSection from "./views/CmsPages/Faqs/AddFaqSection";
import EditCmsFaqSection from "./views/CmsPages/Faqs/EditFaqSection";
import CmsServiceSections from "./views/CmsPages/Services/index";
import AddCmsServiceSection from "./views/CmsPages/Services/AddServiceSection";
import EditCmsServiceSection from "./views/CmsPages/Services/EditServiceSection";
import CmsPrescriptionSections from "./views/CmsPages/RefillPrescription/index";
import AddCmsPrescriptionSection from "./views/CmsPages/RefillPrescription/AddPrescriptionSection";
import EditCmsPrescriptionSection from "./views/CmsPages/RefillPrescription/EditPrescriptionSection";

import CmsLabSections from "./views/CmsPages/Laboratory/index";
import AddCmsLabSection from "./views/CmsPages/Laboratory/AddLabSection";
import EditCmsLabSection from "./views/CmsPages/Laboratory/EditLabSection";

import CmsSexualHealthSections from "./views/CmsPages/SexualHealth/index";
import AddCmsSexualHealthSection from "./views/CmsPages/SexualHealth/AddSexualHealthSection";
import EditCmsSexualHealthSection from "./views/CmsPages/SexualHealth/EditSexualHealthSection";

import CmsMentalHealthSections from "./views/CmsPages/MentalHealth/index";
import AddCmsMentalHealthSection from "./views/CmsPages/MentalHealth/AddMentalHealthSection";
import EditCmsMentalHealthSection from "./views/CmsPages/MentalHealth/EditMentalHealthSection";

import CmsPolicyPage from "./views/CmsPages/PolicyPages/index";
import AddCmsPolicyPage from "./views/CmsPages/PolicyPages/AddPolicyPage";
import EditCmsPolicyPage from "./views/CmsPages/PolicyPages/EditPolicyPage";

import Forms from "./views/Forms/Forms";
import CreateForm from "./views/Forms/Forms/CreateForm";
import EditForm from "./views/Forms/Forms/EditForm";
import SiteSettings from "./views/SiteSettings";
import AddSiteSettings from "./views/SiteSettings/AddSiteSettings";
import EditSiteSettings from "./views/SiteSettings/EditSiteSettings";

import PartnerDiscount from "./views/PartnerDiscount";
import AddPartnerDiscount from "./views/PartnerDiscount/AddPartnerDiscount";
import EditPartnerDiscount from "./views/PartnerDiscount/EditPartnerDiscount";

import BlogCategory from "./views/blogCategory";
import AddBlogCategory from "./views/blogCategory/AddBlogCategory";
import EditBlogCategory from "./views/blogCategory/EditBlogCategory";
import Blog from "./views/blog";
import AddBlog from "./views/blog/AddBlog";
import EditBlog from "./views/blog/EditBlog";

import State from "./views/State";
import AddState from "./views/State/AddState";
import EditState from "./views/State/EditState";
import EmailTemplate from "./views/EmailTemplate";
import AddEmailTemplate from "./views/EmailTemplate/AddEmailTemplate";
import EditEmailTemplate from "./views/EmailTemplate/EditEmailTemplate";
import PatientUser from "./views/PatientUser";

import Consultations from "./views/Consultations/index";
import ViewConsultation from "./views/Consultations/ViewConsultation";

import PrescriptionRefill from "./views/Forms/PrescriptionFill";
import AddPrescriptionRefill from "./views/Forms/PrescriptionFill/AddPrescriptionRefill";
import EditPrescriptionRefill from "./views/Forms/PrescriptionFill/EditPrescriptionRefill";
import ConsultationPrescriptionRefill from "./views/Consultations/PrescriptionRefill";

import AddPromoCode from "./views/promoCode/AddPromoCodeForm";
import EditPromoCodeForm from "./views/promoCode/EditPromoCodeForm";
import PromoIndex from "./views/promoCode/Index";
import Logs from "./views/Logs/Index";
import ViewLogs from "./views/Logs/ViewLogDetails";
import PasswordManagement from "./views/PasswordManagement";
import PaidPrecriptionNotSent from "./views/Consultations/PaidPrecriptionNotSent";

import JobApplications from "./views/JobApplications";
import ViewJobApllication from "./views/JobApplications/ViewJobApllication";
import SubscriberList from "./views/SubscribersList";

const routes = [
  {
    path: "/",
    exact: true,
    access: true,
    name: "Admin Login",
    layout: Auth,
    component: Login,
  },
  {
    path: "/dashboard",
    permissionName: "viewDashboard",
    icon: "fa fa-tasks",
    exact: true,
    access: true,
    name: "Dashboard",
    layout: Admin,
    component: Dashboard,
    showInSideBar: true,
  },
  {
    path: "/profile",
    permissionName: "viewProfile",
    exact: true,
    access: true,
    name: "Profile",
    layout: Admin,
    component: Profile,
  },
  //Consultations list
  {
    path: "/consultations",
    permissionName: "viewConsultations",
    icon: "fa fa-file",
    exact: true,
    name: "Consultations List",
    layout: Admin,
    component: Consultations,
    showInSideBar: true,
  },
  {
    path: "/view-consultation/:id",
    permissionName: "viewConsultationsDetails",
    icon: "fa fa-user-md",
    exact: true,
    name: "View Consultations",
    layout: Admin,
    component: ViewConsultation,
  },

  //Prescription List
  {
    path: "/prescription-refill",
    permissionName: "viewPrescriptionRefill",
    icon: "fa fa-user-md",
    exact: true,
    name: "Prescription Refill",
    layout: Admin,
    component: ConsultationPrescriptionRefill,
    showInSideBar: true,
  },
  {
    path: "/paid-consultations-prescription-not-sent",
    permissionName: "viewPaidConsultationPRescriptionNotSent",
    icon: "fa-file-prescription",
    exact: true,
    name: "Paid Consultation prescription Not Sent",
    layout: Admin,
    component: PaidPrecriptionNotSent,
    showInSideBar: true,
  },

  {
    path: "/view-consultation/:id",
    permissionName: "viewPaidConsultationPRescriptionNotSentDetails",
    icon: "fa fa-user-md",
    exact: true,
    name: "View Consultations",
    layout: Admin,
    component: ViewConsultation,
  },
  // Subscriber List
  {
    path: "/subscription-list",
    permissionName: "viewEmailSubscriber",
    icon: "fa fa-user-md",
    exact: true,
    name: "Subscribers List",
    layout: Admin,
    component: SubscriberList,
    showInSideBar: true,
  },
  {
    path: "/roles",
    permissionName: "viewRole",
    icon: "fa fa-lock",
    exact: true,
    access: true,
    name: "Roles",
    layout: Admin,
    component: Roles,
    showInSideBar: true,
  },
  {
    path: "/user/edit/:id",
    permissionName: "editUser",
    exact: true,
    access: true,
    name: "Edit User",
    layout: Admin,
    component: EditAdminUser,
  },
  {
    path: "/user/add",
    permissionName: "addUser",
    exact: true,
    access: true,
    name: "Add User",
    layout: Admin,
    component: AddAdminUser,
  },
  {
    path: "/users",
    permissionName: "viewUsers",
    icon: "fa fa-users",
    exact: true,
    access: true,
    name: "Admin Users",
    layout: Admin,
    component: AdminUser,
    showInSideBar: true,
  },
  {
    path: "/patients",
    permissionName: "viewPatients",
    icon: "fa fa-hospital",
    exact: true,
    access: true,
    name: "Patients Users",
    layout: Admin,
    component: PatientUser,
    showInSideBar: true,
  },
  {
    path: "/promo",
    permissionName: "viewPromoCode",
    icon: "fa fa-gift",
    exact: true,
    access: true,
    name: "PROMO CODES",
    layout: Admin,
    component: PromoIndex,
    showInSideBar: true,
  },

  {
    path: "/promo/Add",
    permissionName: "addPromoCode",
    exact: true,
    access: true,
    layout: Admin,
    component: AddPromoCode,
  },

  {
    path: "/promo/Edit/:id",
    permissionName: "editPromoCode",
    exact: true,
    access: true,
    layout: Admin,
    component: EditPromoCodeForm,
  },

  {
    path: "/partner-discount",
    permissionName: "viewPartnerDiscount",
    icon: "fa fa-percent",
    exact: true,
    name: "Partner Discount",
    layout: Admin,
    component: PartnerDiscount,
    showInSideBar: true,
  },
  {
    path: "/partner-discount/add",
    permissionName: "addPartnerDiscount",
    exact: true,
    name: "Add Partner Discount",
    layout: Admin,
    component: AddPartnerDiscount,
  },
  {
    path: "/partner-discount/edit/:id",
    permissionName: "editPartnerDiscount",
    exact: true,
    name: "Edit Partner Discount",
    layout: Admin,
    component: EditPartnerDiscount,
  },

  {
    path: "/email-template",
    permissionName: "viewEmailTemplate",
    icon: "fa fa-envelope",
    exact: true,
    name: "Email Template",
    layout: Admin,
    component: EmailTemplate,
    showInSideBar: true,
  },
  {
    path: "/email-template/add",
    permissionName: "addEmailTemplate",
    exact: true,
    name: "Add Email Template",
    layout: Admin,
    component: AddEmailTemplate,
  },
  {
    path: "/email-template/edit/:id",
    permissionName: "editEmailTemplate",
    exact: true,
    name: "Edit Email Template",
    layout: Admin,
    component: EditEmailTemplate,
  },

  {
    path: "/password-management",
    permissionName: "viewPassword",
    icon: "fa fa-users",
    exact: true,
    name: "Password Management ",
    layout: Admin,
    component: PasswordManagement,
    showInSideBar: true,
  },

  // JOb Applications
  {
    path: "/job-applications",
    permissionName: "viewJobApplication",
    icon: "fa fa-users",
    exact: true,
    access: true,
    name: "Job Applications",
    layout: Admin,
    component: JobApplications,
    showInSideBar: true,
  },
  {
    path: "/view-job-application/:id",
    permissionName: "viewJobApplicationDetails",
    icon: "fa fa-user-md",
    exact: true,
    name: "View Job Applications",
    layout: Admin,
    component: ViewJobApllication,
  },

  // site settings
  {
    path: "/site-settings",
    permissionName: "viewSetting",
    icon: "fa fa-cog",
    exact: true,
    name: "Site Settings",
    layout: Admin,
    component: SiteSettings,
    showInSideBar: true,
  },
  {
    path: "/site-settings/add",
    permissionName: "addSetting",
    exact: true,
    name: "Add Site Setting",
    layout: Admin,
    component: AddSiteSettings,
  },
  {
    path: "/site-settings/edit/:id",
    permissionName: "editSetting",
    exact: true,
    name: "Edit Site Setting",
    layout: Admin,
    component: EditSiteSettings,
  },

  // Blogs

  {
    path: "/blog",
    permissionName: "viewBlog",
    icon: "fa fa-clipboard",
    exact: true,
    name: "Blogs",
    layout: Admin,
    component: Blog,
    showInSideBar: true,
  },
  {
    path: "/blog/add",
    permissionName: "addBlog",
    exact: true,
    access: true,
    name: "Add Blog",
    layout: Admin,
    component: AddBlog,
  },
  {
    path: "/blog/edit/:id",
    permissionName: "editBlog",
    exact: true,
    access: true,
    name: "Edit Blog",
    layout: Admin,
    component: EditBlog,
  },

  //Blog Category
  {
    path: "/blog-category",
    permissionName: "viewBlogCategories",
    icon: "fa fa-folder",
    exact: true,
    name: "Blog Categories",
    layout: Admin,
    component: BlogCategory,
    showInSideBar: true,
  },
  {
    path: "/blog-category/add",
    permissionName: "addBlogCategories",
    exact: true,
    name: "Add Blog Category",
    layout: Admin,
    component: AddBlogCategory,
  },
  {
    path: "/blog-category/edit/:id",
    permissionName: "editBlogCategories",
    exact: true,
    name: "Edit Blog Category",
    layout: Admin,
    component: EditBlogCategory,
  },

  //States
  {
    path: "/state",
    permissionName: "viewState",
    icon: "fa fa-map",
    exact: true,
    name: "States",
    layout: Admin,
    component: State,
    showInSideBar: true,
  },
  {
    path: "/state/add",
    permissionName: "addState",
    exact: true,
    name: "Add State",
    layout: Admin,
    component: AddState,
  },
  {
    path: "/state/edit/:id",
    permissionName: "editState",
    exact: true,
    name: "Edit State",
    layout: Admin,
    component: EditState,
  },
  {
    path: "/logs",
    permissionName: "viewLogs",
    icon: "fa fa-history",
    exact: true,
    name: "LOGS",
    layout: Admin,
    component: Logs,
    showInSideBar: true,
  },
  {
    path: "/view-log/:id",
    permissionName: "viewLogs",
    icon: "fa fa-user-md",
    exact: true,
    name: "View Consultations",
    layout: Admin,
    component: ViewLogs,
  },
  {
    collapse: true,
    name: "FORMS",
    state: "forms",
    icon: "fa fa-sticky-note",
    permissionName: "viewFormsManagement",
    showInSideBar: true,
    submenus: [
      {
        path: "/forms/medical-type",
        permissionName: "viewMedicalType",
        exact: true,
        access: true,
        name: "Medical Type",
        layout: Admin,
        icon: "fa fa-medkit",
        component: MedicalType,
        showInSideBar: true,
      },
      {
        path: "/forms/medical-consultations",
        permissionName: "viewMedicalConsultation",
        exact: true,
        access: true,
        name: "Medical Consultations",
        layout: Admin,
        icon: "fa fa-user-md",
        component: MedicalConsultations,
        showInSideBar: true,
      },
      {
        path: "/forms/forms",
        permissionName: "viewForms",
        exact: true,
        access: true,
        name: "Forms",
        icon: "fa fa-sticky-note",
        layout: Admin,
        component: Forms,
        showInSideBar: true,
      },
      {
        path: "/forms/prescription-refill",
        permissionName: "viewPrescriptionRefillType",
        exact: true,
        access: true,
        name: "Prescription Refill",
        icon: "fa fa-sticky-note",
        layout: Admin,
        component: PrescriptionRefill,
        showInSideBar: true,
      },
    ],
  },
  {
    collapse: true,
    name: "CMS",
    state: "cms",
    permissionName: "viewCmsManagement",
    showInSideBar: true,
    icon: "fa fa-tasks",
    submenus: [
      {
        path: "/cms/home",
        permissionName: "viewCmsHomeSection",
        exact: true,
        access: true,
        name: "Home",
        layout: Admin,
        icon: "fa fa-home",
        component: CmsHomeSections,
        showInSideBar: true,
      },
      {
        path: "/cms/about",
        permissionName: "viewCmsAboutSection",
        exact: true,
        access: true,
        name: "About",
        layout: Admin,
        icon: "fa fa-tag",
        component: CmsAboutSections,
        showInSideBar: true,
      },
      {
        path: "/cms/faqs",
        permissionName: "viewCmsFaqSection",
        exact: true,
        access: true,
        name: "FAQs",
        layout: Admin,
        icon: "nc-icon nc-bulb-63",
        component: CmsFaqSections,
        showInSideBar: true,
      },
      {
        path: "/cms/services",
        permissionName: "viewCmsServiceSection",
        exact: true,
        access: true,
        name: "Services",
        layout: Admin,
        icon: "fa fa-server",
        component: CmsServiceSections,
        showInSideBar: true,
      },
      {
        path: "/cms/prescriptions",
        permissionName: "viewCmsPrescriptionSection",
        exact: true,
        access: true,
        name: "Refill Prescription",
        layout: Admin,
        icon: "fa fa-plus-square",
        component: CmsPrescriptionSections,
        showInSideBar: true,
      },
      {
        path: "/cms/labs",
        permissionName: "viewCmsLabSection",
        exact: true,
        access: true,
        name: "Laboratory",
        layout: Admin,
        icon: "fa fa-flask",
        component: CmsLabSections,
        showInSideBar: true,
      },
      {
        path: "/cms/sexual-health",
        permissionName: "viewCmsSexualHealthSection",
        exact: true,
        access: true,
        name: "Sexual Health",
        layout: Admin,
        icon: "fa fas fa-heartbeat",
        component: CmsSexualHealthSections,
        showInSideBar: true,
      },
      {
        path: "/cms/mental-health",
        permissionName: "viewCmsMentalHealthSection",
        exact: true,
        access: true,
        name: "Mental Health",
        layout: Admin,
        icon: "fa fas fa-heartbeat",
        component: CmsMentalHealthSections,
        showInSideBar: true,
      },

      {
        path: "/cms/policy-pages",
        permissionName: "viewCmsPolicyPage",
        exact: true,
        access: true,
        name: "Policy & Condition Page",
        layout: Admin,
        icon: "fa fa-lock",
        component: CmsPolicyPage,
        showInSideBar: true,
      },
    ],
  },

  {
    path: "/forms/medical-type/add",
    permissionName: "addMedicalType",
    exact: true,
    name: "Add Medical Type",
    layout: Admin,
    component: AddMedicalType,
  },
  {
    path: "/forms/medical-type/edit/:id",
    permissionName: "editMedicalType",
    exact: true,
    name: "Edit Medical Type",
    layout: Admin,
    component: EditMedicalType,
  },
  {
    path: "/forms/medical-consultations/add",
    permissionName: "addMedicalConsultation",
    exact: true,
    name: "Add Medical Consultation",
    layout: Admin,
    component: AddMedicalConsultation,
  },
  {
    path: "/forms/medical-consultations/edit/:id",
    permissionName: "editMedicalConsultation",
    exact: true,
    name: "Edit Medical Type",
    layout: Admin,
    component: EditMedicalConsultation,
  },
  {
    path: "/forms/prescription-refill/add",
    permissionName: "addMedicalConsultation",
    exact: true,
    name: "Add Prescription Refill",
    layout: Admin,
    component: AddPrescriptionRefill,
  },
  {
    path: "/forms/prescription-refill/edit/:id",
    permissionName: "editMedicalConsultation",
    exact: true,
    name: "Edit Prescription Refill",
    layout: Admin,
    component: EditPrescriptionRefill,
  },

  {
    path: "/forms/create",
    permissionName: "addForms",
    exact: true,
    access: true,
    name: "Create Form",
    layout: Admin,
    component: CreateForm,
  },
  {
    path: "/forms/edit/:id",
    permissionName: "editForms",
    exact: true,
    access: true,
    name: "Edit Form",
    layout: Admin,
    component: EditForm,
  },

  {
    path: "/cms/home/add",
    permissionName: "addCmsHomeSection",
    exact: true,
    name: "Add Home",
    layout: Admin,
    component: AddCmsHomeSection,
  },
  {
    path: "/cms/home/edit/:id",
    permissionName: "editCmsHomeSection",
    exact: true,
    name: "Edit Home",
    layout: Admin,
    component: EditCmsHomeSection,
  },

  {
    path: "/cms/about/add",
    permissionName: "addCmsAboutSection",
    exact: true,
    name: "Add About",
    layout: Admin,
    component: AddCmsAboutSection,
  },
  {
    path: "/cms/about/edit/:id",
    permissionName: "editCmsAboutSection",
    exact: true,
    name: "Edit About",
    layout: Admin,
    component: EditCmsAboutSection,
  },

  {
    path: "/cms/faqs/add",
    permissionName: "addCmsFaqSection",
    exact: true,
    name: "Add Faq",
    layout: Admin,
    component: AddCmsFaqSection,
  },
  {
    path: "/cms/faqs/edit/:id",
    permissionName: "editCmsFaqSection",
    exact: true,
    name: "Edit Faq",
    layout: Admin,
    component: EditCmsFaqSection,
  },

  {
    path: "/cms/service/add",
    permissionName: "addCmsServiceSection",
    exact: true,
    name: "Add Service",
    layout: Admin,
    component: AddCmsServiceSection,
  },
  {
    path: "/cms/service/edit/:id",
    permissionName: "editCmsServiceSection",
    exact: true,
    name: "Edit Serivce",
    layout: Admin,
    component: EditCmsServiceSection,
  },

  {
    path: "/cms/prescription/add",
    permissionName: "addCmsPrescriptionSection",
    exact: true,
    name: "Add Prescription",
    layout: Admin,
    component: AddCmsPrescriptionSection,
  },
  {
    path: "/cms/prescription/edit/:id",
    permissionName: "editCmsPrescriptionSection",
    exact: true,
    name: "Edit Prescription",
    layout: Admin,
    component: EditCmsPrescriptionSection,
  },

  {
    path: "/cms/lab/add",
    permissionName: "addCmsLabSection",
    exact: true,
    name: "Add Laboratory",
    layout: Admin,
    component: AddCmsLabSection,
  },
  {
    path: "/cms/lab/edit/:id",
    permissionName: "addCmsLabSection",
    exact: true,
    name: "Edit Laboratory",
    layout: Admin,
    component: EditCmsLabSection,
  },

  {
    path: "/cms/sexual-health/add",
    permissionName: "addCmsSexualHealthSection",
    exact: true,
    name: "Add Sexual Health",
    layout: Admin,
    component: AddCmsSexualHealthSection,
  },
  {
    path: "/cms/sexual-health/edit/:id",
    permissionName: "editCmsSexualHealthSection",
    exact: true,
    name: "Edit Sexual Health",
    layout: Admin,
    component: EditCmsSexualHealthSection,
  },

  {
    path: "/cms/mental-health/add",
    permissionName: "addCmsMentalHealthSection",
    exact: true,
    name: "Add Mental Health",
    layout: Admin,
    component: AddCmsMentalHealthSection,
  },
  {
    path: "/cms/mental-health/edit/:id",
    permissionName: "editCmsMentalHealthSection",
    exact: true,
    name: "Edit Mental Health",
    layout: Admin,
    component: EditCmsMentalHealthSection,
  },

  {
    path: "/cms/policy-page/add",
    permissionName: "addCmsPolicyPage",
    exact: true,
    name: "Add Policy",
    layout: Admin,
    component: AddCmsPolicyPage,
  },
  {
    path: "/cms/policy-page/edit/:id",
    permissionName: "editCmsPolicyPage",
    exact: true,
    name: "Edit Policy",
    layout: Admin,
    component: EditCmsPolicyPage,
  },
];

export default routes;
