import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Auth = ({ title, children }) => {
  let navigate = useNavigate();

  useEffect(() => {
    let user = localStorage.getItem("adminToken");
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = "ThePulseCareMD";
    }
  }, [title]);

  return (
    <>
      <div className="wrapper login-wrapper">{children}</div>
    </>
  );
};

export default Auth;
