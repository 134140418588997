import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";
import { FaEllipsisV, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../config/config";

function AdminNavbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const logOut = () => {
    ENV.logOut();
    navigate("/");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-fill btn-icon d-none d-lg-block"
                  onClick={() => document.body.classList.toggle("sidebar-mini")}
                >
                  <FaEllipsisV className="fas fa-ellipsis-v visible-on-sidebar-regular mb-1" />
                  <FaBars className="fas fa-bars visible-on-sidebar-mini mb-1" />
                </Button>
                <Button
                  className="btn-fill btn-icon d-block d-lg-none"
                  onClick={() =>
                    document.documentElement.classList.toggle("nav-open")
                  }
                >
                  <FaEllipsisV className="fas fa-ellipsis-v visible-on-sidebar-regular mb-1" />
                  <FaBars className="fas fa-bars visible-on-sidebar-mini mb-1" />
                </Button>
              </div>
            </div>
          </div>
          <div className="d-flex" ref={dropdownRef}>
            <Dropdown as={Nav.Item} show={dropdownOpen}>
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-41471887333"
                variant="default"
                onClick={toggleDropdown}
              >
                <i className="nc-icon nc-bullet-list-67"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                alignRight
                aria-labelledby="navbarDropdownMenuLink"
              >
                <NavLink
                  to="/profile"
                  className="dropdown-item"
                  onClick={closeDropdown}
                >
                  <i className="fa fa-user mr-2"></i>
                  Profile
                </NavLink>
                <p
                  style={{ cursor: "pointer" }}
                  className="dropdown-item mb-0"
                  onClick={logOut}
                >
                  <i className="fa fa-power-off mr-2"></i>
                  Log out
                </p>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
