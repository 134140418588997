import { useLayoutEffect } from "react";

function InitializeLocalStorage(pathname, currentURLKey) {
  useLayoutEffect(() => {
    if (!(pathname === window.localStorage.getItem(currentURLKey))) {
      localStorage.setItem("page", 1);
    }
  }, []);
}

export default InitializeLocalStorage;
