import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import {
  updateMedicalType,
  getMedicalTypeById,
} from "../../../actions/medicalTypeAction";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ENV } from "../../../config/config";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title must have at least 2 characters")
    .trim("Spaces are not allowed")
    .required("Title is required"),
  photo: Yup.string().required("Photo field is required"),
  status: Yup.string().required("Status field is required"),
});
const EditMedicalType = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [getFile, setFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [getMedicalType, setMedicalType] = useState({});

  const setImage = (img) => {
    return `${ENV.serverUrl}/public/${img}`;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getMedicalType,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("status", values.status);

      if (getFile) {
        formData.append("photo", values.photo);
      }
      setLoader(true);
      let res = await updateMedicalType(id, formData);
      if (res?.data?.success) {
        navigate("/forms/medical-type");
        toast.success(res?.data?.message);
      }
      setLoader(false);
    },
  });

  const handlePreviewImage = (e, setFieldValue) => {
    setFieldValue("photo", e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    const getMedicalTypeId = async () => {
      setLoader(true);
      let res = await getMedicalTypeById(id);
      if (res.data.data) {
        setMedicalType(res.data.data);
      }
      setLoader(false);
    };
    getMedicalTypeId();
  }, [id]);
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item " to="/forms/medical-type">
                    Medical Type
                  </Link>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Edit Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              value={formik.values?.title}
                              placeholder="Enter tile"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <span
                                className={formik.errors.title ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.title}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            controlId="formFile"
                            className="mb-3"
                            onChange={(e) =>
                              handlePreviewImage(e, formik.setFieldValue)
                            }
                          >
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control
                              type="file"
                              name="photo"
                              accept=".png,.jpg,.jpeg,.webp"
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formFilePreview"
                            className="mb-3"
                          >
                            {getFile ? (
                              <img
                                src={getFile}
                                width="200"
                                height="200"
                                alt="not found"
                              />
                            ) : formik.values?.photo ? (
                              <img
                                src={setImage(formik.values?.photo)}
                                crossOrigin="anonymous"
                                width="200"
                                height="200"
                                alt="not found"
                              />
                            ) : null}
                          </Form.Group>
                          <Row>
                            <Col md={1}>
                              <label className="label-font">Status</label>
                            </Col>
                            <Col
                              md={11}
                              className="check-inline d-flex mt-3 flex-column"
                            >
                              <label className="right-label-radio mr-3 mb-2">
                                Active
                                <input
                                  name="status"
                                  className="status"
                                  checked={formik.values.status}
                                  value={formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", true)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="right-label-radio mr-3 mb-2">
                                Inactive
                                <input
                                  name="status1"
                                  className="status"
                                  checked={!formik.values.status}
                                  value={!formik.values.status}
                                  type="radio"
                                  onChange={(e) =>
                                    formik.setFieldValue("status", false)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Col>
                          </Row>
                          <Button className="btn-filled" type="submit">
                            Update
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default EditMedicalType;
