import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getAllEmailSubscribers = (data) =>
  PromiseHandler(
    apiHelper("get", "/api/admin/get-all-email-subscribers/get-all", data)
  );

const deleteEmailSubscriberById = (id) =>
  PromiseHandler(
    apiHelper("delete", `/api/admin/get-all-email-subscribers/delete/${id}`)
  );

export { getAllEmailSubscribers, deleteEmailSubscriberById };
