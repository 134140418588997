import { LOGIN_ADMIN } from "../actionTypes";

const initialState = {
    admin: []
}

const adminReducer = (state = initialState , action) => {
    switch (action.type) {
        case LOGIN_ADMIN:
            return {
                ...state,
                admin: action.payload
            }
        default:
            return state
    }       
}

export default adminReducer