import React, { useState } from "react";
import {
    Button,
    Card,
    Form,
    Container,
    Row,
    Col,
    Breadcrumb
} from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addCmsPolicyPage } from "../../../actions/cmsPolicyPageAction";

import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

// Schema for yup
const validationSchema = Yup.object().shape({
    heading: Yup.string()
        .min(2, "Heading must have at least 2 characters")
        .max(100, "Heading can't be longer than 100 characters")
        .trim("Spaces are not allowed")
        .required("Heading is required"),
    section_type: Yup.string()
        .trim()
        .required('Section Type field is required'),
    page_type: Yup.string()
        .trim()
        .required('Page Type field is required'),
    content: Yup.string().required('content field is required'),
});

const AddPolicyPage = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [getFile, setFile] = useState("");
    const [uploadImage, setUploadImage] = useState(false);
    const handlePreviewImage = (e, setFieldValue) => {
        if (e.target.files[0].type.split('/')[1]) {
            let imageType = e.target.files[0].type.split('/')[1];
            if (imageType === "png" || imageType === "jpg" || imageType === "jpeg" || imageType === "gif" || imageType === "webp") {
                setFieldValue('image', e.target.files[0])
                setFile(URL.createObjectURL(e.target.files[0]));
            } else {
                setUploadImage(true)
                e.target.value = null
            }
        }
    }

    const hideModel = () => {
        setUploadImage(false)
    }
    const onEditorChange = async (event, editor, setFieldValue) => {
        setFieldValue('content', editor.getData());
    }
    return (
        <>
            {
                uploadImage ?
                    <SweetAlert
                        warning
                        showCloseButton
                        confirmBtnText="OK"
                        onConfirm={hideModel}
                        confirmBtnBsStyle="danger"
                        title="Failed! Please select correct Image Format"
                        onCancel={hideModel}
                        focusCancelBtn
                        customClass="del-sweet-alert "
                    >
                        Supported files ["png", "jpg", "jpeg", "gif", "webp"]
                    </SweetAlert>
                    :
                    null
            }
            {
                loader ? <FullPageLoader />
                    :
                    <div className="pt-3 pt-md-5">
                        <Container fluid>
                            <Row>
                                <Col md="12">
                                    <Breadcrumb className="care-md-breadcrumb">
                                        <Link className="breadcrumb-item" to="/cms/policy-pages">Policy & Condition</Link>
                                        <Breadcrumb.Item active>Add</Breadcrumb.Item>
                                    </Breadcrumb>
                                    <Card className="filter-card card">
                                        <Card.Header>
                                            <div className="d-flex align-items-center justify-content-between table-head">
                                                <Card.Title className="text-white" as="h4">
                                                    Add Content
                                                </Card.Title>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>

                                                    <Formik
                                                        initialValues={{ heading: "", content: "", section_type: "", page_type: "", image: "", status: false }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                            const formData = new FormData();
                                                            formData.append('heading', values.heading)
                                                            formData.append('content', values.content)
                                                            formData.append('section_type', values.section_type)
                                                            formData.append('page_type', values.page_type)
                                                            formData.append('status', values.status)
                                                            formData.append('image', values.image)
                                                            setLoader(true)
                                                            // When button submits form and form is in the process of submitting, submit button is disabled
                                                            setSubmitting(true);
                                                            let res = await addCmsPolicyPage(formData)
                                                            if (res.data.success) {

                                                                navigate('/cms/policy-pages')
                                                                toast.success(res.data.message)
                                                            }
                                                            setLoader(false)

                                                        }}
                                                    >
                                                        {({ values,
                                                            errors,
                                                            touched,
                                                            handleChange,
                                                            handleBlur,
                                                            handleSubmit,
                                                            setFieldValue,
                                                            isSubmitting }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Group className="mb-3" controlId="formBasicHeading">
                                                                    <Form.Label>Heading <span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control type="text" name="heading" placeholder="Enter heading" onChange={handleChange} onBlur={handleBlur} value={values.heading} />
                                                                    {touched.heading && errors.heading ? (
                                                                        <span className={errors.heading ? `` : `d-none`}>
                                                                            <label className="pl-1 text-danger">{errors.heading}</label>
                                                                        </span>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="formBasicContent">
                                                                    <Form.Label>Content <span className="text-danger">*</span></Form.Label>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={values.content ? values.content : ''}
                                                                        content={values.content ? values.content : ''}
                                                                        onChange={(event, editor) => onEditorChange(event, editor, setFieldValue)}
                                                                    />
                                                                    {touched.content && errors.content ? (
                                                                        <span className={errors.content ? `` : `d-none`}>
                                                                            <label className="pl-1 text-danger">{errors.content}</label>
                                                                        </span>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="formBasicSectionType">
                                                                    <Form.Label>Page Type<span className="text-danger">*</span></Form.Label>
                                                                    <label className="right-label-radio mr-3 mb-2">Terms & Condition
                                                                        <input name="page_type" className="status" type="radio" onChange={(e) => setFieldValue('page_type', 1)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <label className="right-label-radio mr-3 mb-2">Privacy Policy
                                                                        <input name="page_type" className="status" type="radio" onChange={(e) => setFieldValue('page_type', 2)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <label className="right-label-radio mr-3 mb-2">Refund Policy
                                                                        <input name="page_type" className="status" type="radio" onChange={(e) => setFieldValue('page_type', 3)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <label className="right-label-radio mr-3 mb-2">For Pharmacies
                                                                        <input name="page_type" className="status" type="radio" onChange={(e) => setFieldValue('page_type', 4)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    {touched.page_type && errors.page_type ? (
                                                                        <span className={errors.page_type ? `` : `d-none`}>
                                                                            <label className="pl-1 text-danger">{errors.page_type}</label>
                                                                        </span>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="formBasicSectionType">
                                                                    <Form.Label>Section Display Order<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control type="number" name="section_type" placeholder="Enter Section Display Order"
                                                                        onChange={(e) => handleChange(e)} />
                                                                    {touched.section_type && errors.section_type ? (
                                                                        <span className={errors.section_type ? `` : `d-none`}>
                                                                            <label className="pl-1 text-danger">{errors.section_type}</label>
                                                                        </span>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group controlId="formFile" className="mb-3" onChange={(e) => handlePreviewImage(e, setFieldValue)}>
                                                                    <Form.Label>Upload Image</Form.Label>
                                                                    <Form.Control type="file" name="image" accept=".png,.jpg,.jpeg,.webp" />
                                                                    {touched.image && errors.image ? (
                                                                        <span className={errors.image ? `` : `d-none`}>
                                                                            <label className="pl-1 text-danger">{errors.image}</label>
                                                                        </span>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group controlId="formFilePreview" className="mb-3">
                                                                    {getFile ? (<img src={getFile} width="200" height="200" alt="not found" />) : null}

                                                                </Form.Group>
                                                                <Row>
                                                                    <Col md={1}>
                                                                        <label className="label-font">Status</label>
                                                                    </Col>
                                                                    <Col md={11} className="check-inline d-flex mt-3 flex-column">
                                                                        <label className="right-label-radio mr-3 mb-2">Active
                                                                            <input name="status" className="status" checked={values.status} value={values.status} type="radio"
                                                                                onChange={(e) => setFieldValue('status', true)} />
                                                                            <span className="checkmark"></span>
                                                                        </label>

                                                                        <label className="right-label-radio mr-3 mb-2">Inactive
                                                                            <input name="status1" className="status" checked={!values.status} value={!values.status} type="radio"
                                                                                onChange={(e) => setFieldValue('status', false)} />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </Col>
                                                                </Row>
                                                                <Button className="btn-filled" type="submit">
                                                                    Submit
                                                                </Button>
                                                            </Form>
                                                        )}
                                                    </Formik>


                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }

        </>
    );
}

export default AddPolicyPage;