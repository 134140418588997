import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { getAdminStats, getAdminDashboard } from "../../actions/adminAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { Line, Pie } from "react-chartjs-2";
import moment from "moment";
import randomColor from "randomcolor";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import InitializeLocalStorage from "../../components/PaginationHandle";

const Index = () => {
  const [dailyStats, setDailyStats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const [totalPaid, setTotalPaid] = useState(0);
  const [dailyPaid, setDailyPaid] = useState(0);
  const [paid, setPaid] = useState(0);
  const [pending, setPending] = useState(0);
  const [declined, setDeclined] = useState(0);
  const [lineChartData, setLineChartData] = useState();
  const [pieChart, setPieChart] = useState();
  const location = useLocation();
  const { pathname } = location;
  InitializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  const getAllstatDashboard = async () => {
    setLoader1(true);
    let res = await getAdminDashboard();
    if (res?.data) {
      setPaid(res?.data?.Paid);
      setPending(res?.data?.pending);
      setDeclined(res?.data?.declined);
      setDailyStats(res?.data?.todayPaidCount);
      setDailyPaid(res?.data?.todayPaid[0]?.totalPrice);
      setTotalPaid(res?.data?.totalPaid[0]?.totalPrice);
      setLoader1(false);
    }
  };

  useEffect(() => {
    getAllStats();
    getAllstatDashboard();
  }, []);
  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };

  const getAllStats = async () => {
    setLoader(true);
    let res = await getAdminStats();
    if (res?.data) {
      let data = [];
      let labels = [];
      let paid = [];
      let unpaid = [];
      let declined = [];
      res.data?.orderChartData?.forEach((data_, index) => {
        data.push(data_?.appointments);
        declined.push(data_?.declined);
        paid.push(data_?.paid);
        unpaid.push(data_?.unpaid);
        const date = moment(data_?.first).add(1, "day");
        labels.push(date.format("MM-DD-YYYY"));
      });

      setLineChartData({
        labels,
        datasets: [
          {
            label: "Paid",
            data: paid,
            borderColor: "rgba(135, 203, 22 , 1)",
            backgroundColor: "rgba(135, 203, 22, 1)",
          },

          {
            label: "Unpaid",
            data: unpaid,
            borderColor: "rgba(255, 193, 7 , 1)",
            backgroundColor: "rgba(255, 193, 7, 1)",
          },
          {
            label: "Declined",
            data: declined,
            borderColor: "rgba(251, 64, 75,1)",
            backgroundColor: "rgba(251, 64, 75, 1)",
          },
        ],
      });

      const backgroundColors = res.data.chartLabels.map(() =>
        randomColor({ luminosity: "light" })
      );

      const borderColors = backgroundColors.map((color) =>
        color.replace("0.2", "1")
      );
      const pieData = {
        labels: res?.data?.chartLabels,
        datasets: [
          {
            label: "# of Appointments",
            data: res.data.chartData,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };

      setPieChart(pieData);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="pt-3 pt-md-5 main-dashboard">
        {loader1 ? (
          <FullPageLoader />
        ) : (
          <Container fluid>
            <Row>
              <>
                <Col xl={4} md={6}>
                  <Card className="card-stats">
                    <Card.Body>
                      <Link to="/consultations?isPaid=paid" className="d-flex">
                        <div className="numbers">
                          <p className="card-category">Paid Consultations</p>
                          <Card.Title as="h2">{paid ?? 0}</Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                          <i className="far fa-check-circle"></i>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card className="card-stats">
                    <Card.Body>
                      <Link
                        to="/consultations?isPaid=notpaid"
                        className="d-flex"
                      >
                        <div className="numbers">
                          <p className="card-category">Pending Consultations</p>
                          <Card.Title as="h2">{pending ?? 0}</Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                          <i className="fa fa-undo"></i>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card className="card-stats">
                    <Card.Body>
                      <Link
                        to="/consultations?isPaid=declined"
                        className="d-flex"
                      >
                        <div className="numbers">
                          <p className="card-category">
                            {" "}
                            Declined Consultations
                          </p>
                          <Card.Title as="h2">{declined ?? 0}</Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                          <i className="fa fa-ban"></i>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </>
              <>
                <Col xl={4} md={6}>
                  <Card className="card-stats">
                    <Card.Body>
                      <div className="d-flex">
                        <div className="numbers">
                          <p className="card-category">
                            Total Amount of Paid Consultations
                          </p>
                          <Card.Title as="h2">
                            {totalPaid?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            }) ?? "$0.00"}{" "}
                          </Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                          <i className="fas fa-money-bill"></i>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card className="card-stats">
                    <Card.Body>
                      <div className="d-flex">
                        <div className="numbers">
                          <p className="card-category">
                            Today Paid Consultations Payment
                          </p>
                          <Card.Title as="h2">
                            {dailyPaid?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            }) ?? "$0.00"}{" "}
                          </Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                          <i className="fa fa-hospital-user"></i>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </>

              <Col xl={4} md={6}>
                <Card className="card-stats">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="numbers">
                        <p className="card-category">
                          Today Paid Consultations
                        </p>
                        <Card.Title as="h2">{dailyStats ?? 0}</Card.Title>
                      </div>
                      <div className="icon-big text-center icon-warning">
                        <i className="far fa-handshake"></i>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {loader ? (
              <Skeleton count={20} />
            ) : (
              <Row className="align-items-center">
                <Col xl={7} lg={6}>
                  {lineChartData && lineChartData.labels && (
                    <Line options={options} data={lineChartData}></Line>
                  )}
                </Col>
                <Col xl={5} lg={6} className="mt-lg-5">
                  {pieChart && <Pie data={pieChart} />}
                </Col>
              </Row>
            )}
          </Container>
        )}
      </div>
    </>
  );
};

export default Index;
