import React from "react";
const PermissionDenied = () => {
  return (
    <div className="error-page">
      <div className="error-wraper">
        <h2>Access Denied</h2>
        <h1>
          4<span>0</span>3
        </h1>
        <p>Sorry, you do not have permission to view this page.</p>
      </div>
    </div>
  );
};

export default PermissionDenied;
