import React, { useState } from 'react'
import { Container, Row, Col, Form, Card, Breadcrumb, Button } from 'react-bootstrap';
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader'
import { Link } from 'react-router-dom';
import { toast } from "react-toastify"
import { createSiteSettings } from '../../actions/siteSettings';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';



const SiteSettingsSchema = Yup.object().shape({
    attributeName: Yup.string().trim().min(2, "Attribute must have at least 3 characters").required("Attribute field is required"),
    attributeValue: Yup.string().required("Attribute Value field is required"),
});

const AddSiteSettings = () => {

    const [loader, setLoader] = useState(false);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            attributeName: '',
            attributeValue: '',
            status: false,
        },
        validationSchema: SiteSettingsSchema,
        onSubmit: async (values) => {
            setLoader(true)
            let res = await createSiteSettings(values)
            if (res?.data?.success) {
                navigate('/site-settings')
                toast.success(res.data.message)
            }
            setLoader(false)
        },
    });

    return (
        <>
            {
                loader ? <FullPageLoader /> :
                    <div className="pt-3 pt-md-5">
                        <Container fluid>
                            <Row>
                                <Col md="12">
                                    <Breadcrumb className="care-md-breadcrumb">
                                        <Link className="breadcrumb-item" to="/site-settings">Site Settings</Link>
                                        <Breadcrumb.Item active>Add</Breadcrumb.Item>
                                    </Breadcrumb>
                                    <Card className="filter-card card">
                                        <Card.Header>
                                            <div className="d-flex align-items-center justify-content-between table-head">
                                                <Card.Title className="text-white" as="h4">
                                                    Add Site Settings
                                                </Card.Title>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Form onSubmit={formik.handleSubmit}>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Attribute <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="attributeName" value={formik.values.attributeName} placeholder="Enter attribute" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.attributeName && formik.errors.attributeName ? (
                                                                <span className={formik.errors.attributeName ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.attributeName}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicusername">
                                                            <Form.Label>Attribute Value <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="attributeValue" value={formik.values.attributeValue} placeholder="Enter attribute" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.attributeValue && formik.errors.attributeValue ? (
                                                                <span className={formik.errors.attributeValue ? `` : `d-none`}>
                                                                    <label className="pl-1 text-danger">{formik.errors.attributeValue}</label>
                                                                </span>
                                                            ) : null}
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Row>
                                                                <Col md={1}>
                                                                    <label className="label-font">Status</label>
                                                                </Col>
                                                                <Col md={11} className="check-inline d-flex">
                                                                    <label className="right-label-radio mr-3 mb-2">Active
                                                                        <input name="status" type="radio" checked={formik.values.status} value={formik.values.status} onChange={(e) => formik.setFieldValue('status', true)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <label className="right-label-radio mr-3 mb-2">Inactive
                                                                        <input name="status" type="radio" checked={!formik.values.status} value={!formik.values.status} onChange={(e) => formik.setFieldValue('status', false)} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Button className="btn-filled" type="submit">
                                                            Submit
                                                        </Button>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </>
    )
}

export default AddSiteSettings