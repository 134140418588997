import { LOGIN_ADMIN } from "../actionTypes";

export const setAdmin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_ADMIN,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
