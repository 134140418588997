import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Row, Col } from "react-bootstrap";
import {
  getConsultationById,
  addOrUpdatePrescription,
} from "../../actions/consultationAction";
import moment from "moment-timezone";

import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ENV } from "../../config/config";
import { Bars } from "react-loader-spinner";

import { getPharmacyById } from "../../actions/pharmacyAction";

const sendPrescriptionSchema = Yup.object().shape({
  pharmacyName: Yup.string().trim().required("Pharmacy name field is required"),
  pharmacyAddress: Yup.string()
    .trim()
    .required("Pharmacy address field is required"),
  prescription: Yup.string()
    .trim()
    .required("Pharmacy prescription field is required"),
});
export default function ViewConsultation() {
  const { id, parameter } = useParams();
  const navigate = useNavigate();

  const [consultationData, setConsultationData] = useState({});
  const [pharmacyData, setPharmacyData] = useState({});

  const [userId, setUserId] = useState("");

  const [formfield, setFormfieldData] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [sendloader, setSendloader] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;

  const convertUnderScoreToSpace = (str) => {
    var text = str.replace(/_/g, " ");
    return text.replace(/nbsp/g, " ");
  };
  const setFilePath = (files) => {
    return `${ENV.serverUrl}/public/uploads${files}`;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: sendPrescriptionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSendloader(true); // Show the loader when form submission starts
        let res = await addOrUpdatePrescription(
          consultationData?.user,
          id,
          values
        );
        if (res.data.success) {
          navigate("/consultations");
          toast.success(res.data.message);
        }
      } catch (error) {
        // Handle errors here
        console.error("Error submitting form:", error);
      } finally {
        setSendloader(false); // Hide the loader after form submission completes
        setSubmitting(false); // Set Formik's submitting state to false
      }
    },
  });

  // const convertDate = (dateString) => {
  //   const chicagoTime = moment.utc(dateString);
  //   return chicagoTime.format("MM-DD-YYYY");
  // };

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("MM-DD-YYYY");
  };

  const DOBCOnverter = (dateString) => {
    return moment.utc(dateString).format("MM-DD-YYYY");
  };

  const getAgeFromDate = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var ageDiff = today.getTime() - birthDate.getTime();
    var ageDate = new Date(ageDiff);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  };

  useEffect(() => {
    const getConsultation = async () => {
      let res = await getConsultationById(id);

      if (res?.data?.data) {
        setUserId(res?.data?.data?.user);
        const updatedPharmacy = res?.data?.data?.updatedPharmacy;
        const pharmacyData = res?.data?.data;
        const data = {
          pharmacyName: updatedPharmacy
            ? `${updatedPharmacy.pharmacyName} `
            : `${pharmacyData.pharmacyName} `,
          pharmacyAddress: updatedPharmacy
            ? `${updatedPharmacy.pharmacyAddress} ${updatedPharmacy.pharmacyCity} ${updatedPharmacy.state?.title} ${updatedPharmacy.pharmacyZip}`
            : `${pharmacyData.pharmacyAddress} ${pharmacyData.pharmacyCity} ${pharmacyData.state?.title} ${pharmacyData.pharmacyZip}`,

          prescription: res?.data?.data.prescription
            ? res?.data?.data.prescription.prescription
            : res?.data?.data.template?.content,
        };
        setInitialValue(data);
        setFormfieldData(JSON.parse(res.data.data?.formField));

        setConsultationData(res.data.data);
      }
      setLoader(false);
    };
    getConsultation();
  }, [id]);
  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "image.jpg";
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  return (
    <>
      {show && (
        <Modal
          className="send-Prescription-modal custom-prescription-modal"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold w-100 text-center">
              Prescription Form
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pharmacy Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pharmacyName"
                      placeholder="Enter pharmacy name"
                      value={formik.values?.pharmacyName}
                      readOnly
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                  {formik.touched.pharmacyName && formik.errors.pharmacyName ? (
                    <span
                      className={formik.errors.pharmacyName ? `` : `d-none`}
                    >
                      <label className="pl-1 text-danger">
                        {formik.errors.pharmacyName}
                      </label>
                    </span>
                  ) : null}
                </Col>

                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pharmacy Location <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pharmacyAddress"
                      placeholder="Enter pharmacy location"
                      value={formik.values?.pharmacyAddress}
                      readOnly
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                  {formik.touched.pharmacyName && formik.errors.pharmacyName ? (
                    <span
                      className={formik.errors.pharmacyName ? `` : `d-none`}
                    >
                      <label className="pl-1 text-danger">
                        {formik.errors.pharmacyName}
                      </label>
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    Content <span className="text-danger">*</span>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    // data={consultationData?.template ? consultationData?.template?.content : ''}
                    data={
                      formik.values.prescription
                        ? formik.values.prescription
                        : ""
                    }
                    content={
                      formik.values.prescription
                        ? formik.values.prescription
                        : ""
                    }
                    onChange={(event, editor) =>
                      formik.setFieldValue("prescription", editor.getData())
                    }
                  />
                  {formik.touched.prescription && formik.errors.prescription ? (
                    <span
                      className={formik.errors.prescription ? `` : `d-none`}
                    >
                      <label className="pl-1 text-danger">
                        {formik.errors.prescription}
                      </label>
                    </span>
                  ) : null}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {sendloader ? (
                <Button
                  className="btn-light-blue auth-btn mb-4 disabled"
                  disabled
                >
                  <Bars
                    height="33"
                    width="33"
                    color="#fff"
                    ariaLabel="bars-loading"
                    visible={true}
                    wrapperClass="bars-wrapper-loader"
                  />
                </Button>
              ) : (
                <Button
                  className="float-sm-right btn-filled d-flex align-items-center btn btn-primary"
                  type="submit"
                >
                  Send
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {loader ? (
        <FullPageLoader></FullPageLoader>
      ) : (
        <>
          {/* back button */}
          <Link
            className="generic-back-btn btn-filled align-items-center btn btn-primary"
            to={
              localStorage?.getItem("prevPath")
                ? `${localStorage?.getItem("prevPath")}`
                : ""
            }
          >
            Back
          </Link>
          <div className="dashboard-consultation-wrapper">
            <div className="consultation-heading mb-5">
              {/* <h2>
              {consultationData?.formType?.formType == "consultationForm"
                ? "Consultation List"
                : consultationData?.formType?.formType == "preccriptionRefill"
                ? "Preccription Refill"
                : "Paid Consultation Prescription Not Sent"}{" "}
            </h2> */}
              <h2>Consultation Detail</h2>
            </div>
            {consultationData?.discount && (
              <>
                <div className="table-heading position-relative mb-4">
                  <h5>Partner Form</h5>
                </div>
                <Row>
                  <Col md="12">
                    <Link
                      className="float-sm-left btn-filled d-flex align-items-center link-btn view-partner-form "
                      target="_blank"
                      to={`/view-consultation/${consultationData?.discount?.userAppointmentType}`}
                    >
                      <span className="add-icon mr-2">
                        <FaEye />
                      </span>
                    </Link>
                  </Col>
                </Row>
              </>
            )}
            <div className="table-heading position-relative mb-5">
              <h5>About Patient</h5>
            </div>
            <div className="table-responsive">
              <Table striped className="consultation-table mb-4">
                <tbody>
                  <tr>
                    <th>Patient Name</th>
                    <td>
                      {consultationData?.firstName +
                        " " +
                        consultationData.lastName}
                    </td>
                    <th>Diseases</th>
                    <td>
                      <span className="badge badge-success">
                        {consultationData?.diseaseName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>AGE</th>
                    <td>
                      {getAgeFromDate(consultationData?.dateOfBirth)} Year
                    </td>
                    <th>Email</th>
                    <td>{consultationData?.email}</td>
                  </tr>
                  <tr>
                    <th>Gender</th>
                    <td>{consultationData?.gender}</td>
                    <th>Phone Number</th>
                    <td>{consultationData?.phone}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{consultationData?.address}</td>
                    <th>City</th>
                    <td>{consultationData?.city}</td>
                  </tr>

                  <tr>
                    <th>State</th>
                    <td>{consultationData?.state?.title}</td>
                    <th>Zip Code</th>
                    <td>{consultationData?.zip}</td>
                  </tr>
                  <tr>
                    <th>weight</th>
                    <td>{consultationData?.weight}</td>
                    <th>Height</th>
                    <td>{consultationData?.height}</td>
                  </tr>
                  <tr>
                    <th>DOB</th>
                    <td>{DOBCOnverter(consultationData?.dateOfBirth)}</td>
                    <th>Actual Price</th>
                    <td>
                      $
                      {!consultationData?.actual_price ||
                      consultationData?.actual_price == 0
                        ? consultationData?.price
                        : consultationData?.actual_price}
                    </td>
                  </tr>

                  <tr>
                    <th>After Promo Discount </th>
                    <td>
                      {consultationData?.promoCode?.discountValue ? "$" : ""}
                      {consultationData?.promoCode?.discountValue ||
                        "No Discount"}
                    </td>
                    <th>After Partner Discount </th>
                    <td>
                      {consultationData?.discount?.discount ? "%" : ""}
                      {consultationData?.discount?.discount?.toFixed(0, 2) ||
                        "No Discount"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="table-heading position-relative mb-5">
              <h5>Medical Info</h5>
            </div>
            <div className="table-responsive medical-custom-info">
              <Table striped className="consultation-table mb-4">
                <tbody>
                  {Object.keys(formfield)?.map((key, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th> {convertUnderScoreToSpace(key)}</th>
                          {typeof formfield[key] === "object" ? (
                            <td>
                              {formfield[key]?.map((k, i) => {
                                return (
                                  <>
                                    <span key={i}>{k}</span>
                                    <br></br>
                                  </>
                                );
                              })}
                            </td>
                          ) : (
                            <td>{formfield[key]}</td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="table-heading position-relative mb-5">
              <h5>Pharmacy Information</h5>
            </div>
            <div className="table-responsive">
              <Table striped className="consultation-table mb-4">
                <tbody>
                  <tr>
                    <th> PHARMACY NAME</th>
                    <td>{consultationData?.pharmacyName}</td>
                  </tr>
                  <tr>
                    <th>PHARMACY PHONE</th>
                    <td>{consultationData?.pharmacyPhone}</td>
                  </tr>
                  <tr>
                    <th>PHARMACY ADDRESS</th>
                    <td>{consultationData?.pharmacyAddress}</td>
                  </tr>
                  <tr>
                    <th>PHARMACY CITY</th>
                    <td>{consultationData?.pharmacyCity}</td>
                  </tr>
                  <tr>
                    <th>PHARMACY STATE</th>
                    <td>{consultationData?.pharmacyState?.title}</td>
                  </tr>
                  <tr>
                    <th>PHARMACY Zip Code</th>
                    <td>{consultationData?.pharmacyZip}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {consultationData?.updatedPharmacy ? (
              <>
                <div className="table-heading position-relative mb-5">
                  <h5>Updated Pharmacy Information</h5>
                </div>
                <div className="table-responsive">
                  <Table striped className="consultation-table mb-4">
                    <tbody>
                      <tr>
                        <th> PHARMACY NAME</th>
                        <td>
                          {consultationData?.updatedPharmacy?.pharmacyName}
                        </td>
                      </tr>
                      <tr>
                        <th>PHARMACY PHONE</th>
                        <td>
                          {consultationData?.updatedPharmacy?.pharmacyPhone}
                        </td>
                      </tr>
                      <tr>
                        <th>PHARMACY ADDRESS</th>
                        <td>
                          {consultationData?.updatedPharmacy?.pharmacyAddress}
                        </td>
                      </tr>
                      <tr>
                        <th>PHARMACY CITY</th>
                        <td>
                          {consultationData?.updatedPharmacy?.pharmacyCity}
                        </td>
                      </tr>
                      <tr>
                        <th>PHARMACY STATE</th>
                        <td>
                          {consultationData?.updatedPharmacy?.state?.title}
                        </td>
                      </tr>
                      <tr>
                        <th>PHARMACY Zip Code</th>
                        <td>
                          {consultationData?.updatedPharmacy?.pharmacyZip}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <div className="table-heading position-relative mb-5">
                <h5>Updated Pharmacy Information Not Found</h5>
              </div>
            )}

            <div className="table-heading position-relative mb-5">
              <h5>
                {consultationData?.images?.length > 0
                  ? "Attachment found"
                  : "No Attachments found"}
              </h5>
            </div>

            {consultationData?.images?.map((image, index) => {
              const imageUrl = setFilePath(image?.photo);
              return (
                <>
                  <img
                    key={index}
                    onClick={() => downloadImage(imageUrl)}
                    src={imageUrl}
                    className="mr-5 mb-2"
                    crossOrigin="anonymous"
                    width="200"
                    height="200"
                    alt="not found"
                  />
                </>
              );
            })}

            <button
              type="button"
              className="float-sm-right btn-filled d-flex align-items-center btn btn-primary"
              onClick={handleShow}
            >
              {consultationData?.prescription
                ? "Update Prescription"
                : "Send Prescription"}{" "}
            </button>
          </div>
        </>
      )}
    </>
  );
}
