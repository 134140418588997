import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { RiRefreshFill } from "react-icons/ri";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import _debounce from "lodash/debounce";
import moment from "moment-timezone";

import { getAllPaidPrescriptionNotSent } from "../../actions/consultationAction";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";

import DataTable, { createTheme } from "react-data-table-component";
const paginationRowsPerPageOptions = [10, 20, 30];

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

function PaidPrecriptionNotSent() {
  const [consultationsData, setConsultationData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [selectedOptionPayment, setSelectedOptionPayment] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [debouncedText, setDebouncedText] = useState(filterText);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [exportCsv, setExportCsv] = useState(0);
  const [sort, setSort] = useState();
  const [direction, setDirection] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [notGreaterStartDate, SetNotGreaterStartDate] = useState();

  const refreshTable = () => {
    localStorage.setItem(
      "page",
      localStorage.getItem("page") ? localStorage.getItem("page") : 1
    );
    localStorage.setItem(
      "PaidPrescriptionLimit",
      localStorage.getItem("PaidPrescriptionLimit")
        ? localStorage.getItem("PaidPrescriptionLimit")
        : 10
    );

    getConsultations();
  };

  const location = useLocation();
  const { pathname } = location;

  window?.localStorage.setItem("prevPath", pathname);

  const convertDate = (dateString) => {
    const utcTime = moment.utc(dateString);
    const chicagoTime = utcTime.tz("America/Chicago", false);
    return chicagoTime.format("M-D-YYYY, h:mm A");
  };

  useLayoutEffect(() => {
    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
    }
  }, []);

  const handlePageChange = (page) => {
    localStorage.setItem("page", page);

    getConsultations(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    localStorage.setItem("PaidPrescriptionLimit", newPerPage);
    localStorage.setItem("page", page);
    getConsultations(page, newPerPage);
    setPerPage(newPerPage);
  };

  const debouncedFilterDatatable = _debounce((text) => {
    setDebouncedText(text.trim());
  }, 500);

  const getConsultations = async (page, limit = perPage) => {
    setLoader(true);
    if (startDate && endDate && startDate > endDate) {
      SetNotGreaterStartDate("Start Date cannot be greater than End Date");
      setLoader(false);
      return;
    } else {
      SetNotGreaterStartDate(null);
    }

    if (!(pathname === window.localStorage.getItem("currentURL"))) {
      localStorage.setItem("page", 1);
    }

    var storedPage = localStorage.getItem("page");
    var storedLimit = localStorage.getItem("PaidPrescriptionLimit");

    if (storedPage === undefined || storedPage === null) {
      page = 1;
    } else {
      page = parseInt(storedPage);
    }
    if (storedLimit === undefined || storedLimit === null) {
      limit = 10;
    } else {
      limit = parseInt(storedLimit);
    }
    window.localStorage.setItem("currentURL", pathname);

    setCurrentPage(page);
    setPerPage(limit);
    setFilterText(
      localStorage.getItem("PaidPrescriptionsearchText")
        ? localStorage.getItem("PaidPrescriptionsearchText")
        : debouncedText
    );

    let obj = {
      exportCsv,
      startDate,
      endDate,
      page,
      limit,
      isReviewed: selectedOption,
      isPaid: selectedOptionPayment,
      filterText: localStorage.getItem("PaidPrescriptionsearchText")
        ? localStorage.getItem("PaidPrescriptionsearchText")
        : debouncedText,
      sort,
      direction,
    };
    let res = await getAllPaidPrescriptionNotSent(obj);
    if (res?.data?.data?.appointments) {
      setTotalRows(res?.data?.data?.pagination?.total);
      setConsultationData(res?.data?.data?.appointments);
      if (res?.data?.downloadLink) {
        downloadFile(res?.data?.downloadLink);
        setExportCsv(0);
      }
    }
    setLoader(false);
  };

  function downloadFile(fileUrl) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleSort = (column, sortDirection) => {
    setSort(column.sortField);
    setDirection(sortDirection);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("page");
      localStorage.removeItem("currentURL");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const columns = [
    {
      name: "ID",
      selector: (row, index) => {
        const counter =
          ((isNaN(currentPage) ? 1 : currentPage) - 1) * perPage + index + 1;
        return counter;
      },
      minWidth: "50px",
      sortable: true,
      sortField: "ID",
    },
    {
      name: "Disease",
      selector: (row) => row.diseaseName,
      minWidth: "190px",
      sortable: true,
      sortField: "diseaseName",
    },
    {
      name: "Form Type",
      selector: (row) => (
        <>
          <div className="form-type">
            <label className="label label-success m-0">
              {row?.formType?.formType === "consultationForm"
                ? "Consultation Form"
                : row?.formType?.formType}{" "}
            </label>{" "}
          </div>
        </>
      ),
      minWidth: "160px",
      sortable: true,
      sortField: "formType",
    },
    {
      name: "Patient Name",
      selector: (row) => row?.firstName + " " + row?.lastName,
      minWidth: "160px",
      sortable: true,
      sortField: "firstName",
    },

    {
      name: "created At",
      selector: (row) => convertDate(row?.createdAt),
      minWidth: "200px",
      sortable: true,
      sortField: "updatedAt",
    },

    {
      name: "updated At",
      selector: (row) => convertDate(row?.updatedAt),
      minWidth: "200px",
      sortable: true,
      sortField: "updatedAt",
    },

    {
      name: "Discount",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.discount?.isUsed ? (
              <label className="label label-success m-0">
                <i className="fa fa-check" aria-hidden="true"></i>
              </label>
            ) : (
              <label className="label label-danger m-0">
                <i className="fa fa-times" aria-hidden="true"></i>
              </label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },
    {
      name: "Promo",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.promoCode?.isUsed ? (
              <label className="label label-success m-0">
                <i className="fa fa-check" aria-hidden="true"></i>
              </label>
            ) : (
              <label className="label label-danger m-0">
                <i className="fa fa-times" aria-hidden="true"></i>
              </label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },
    {
      name: "platform",
      selector: (row) => (
        <>
          {" "}
          <div className="discount">
            {row?.isAppAppointment ? (
              <label className="label label-success m-0">App</label>
            ) : (
              <label className="label label-danger m-0">Web</label>
            )}
          </div>
        </>
      ),
      minWidth: "130px",
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return (
          <>
            {" "}
            {row?.isPaid === "paid" ? (
              <label className="label label-success m-0">Paid</label>
            ) : row?.isPaid === "unpaid" ? (
              <label className="label label-warning m-0 text-white bg-warning">
                Not Paid
              </label>
            ) : (
              row?.isPaid === "declined" && (
                <label className="label label-danger m-0">Declined</label>
              )
            )}
          </>
        );
      },
      minWidth: "180px",
      sortable: true,
      sortField: "isPaid",
    },

    {
      name: "Prescription Status",
      selector: (row) => (
        <>
          {" "}
          {row.isReviewed ? (
            <label className="label label-success m-0">Prescription Sent</label>
          ) : (
            <label className="label label-danger m-0">
              Prescription Not Sent
            </label>
          )}
        </>
      ),
      minWidth: "210px",
      sortable: true,
      sortField: "isReviewed",
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.viewPaidConsultationPRescriptionNotSentDetails ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/view-consultation/${row._id}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success "
                  >
                    <FaEye />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">View</Tooltip>}
                  placement="left"
                >
                  <span className="btn-link btn-icon btn btn-success" disabled>
                    <FaEye />
                  </span>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),
      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    window.localStorage.setItem("PaidPrescriptionsearchText", text);

    setFilterText(text);
  };

  const handleSelectChangePayment = (e) => {
    let val = e.target.value;
    setSelectedOptionPayment(val);
  };

  const handleSelectChangePrescription = (e) => {
    let val = e.target.value;
    setSelectedOption(val);
  };

  const resetFilter = () => {
    window?.localStorage.removeItem("PaidPrescriptionsearchText");

    setDebouncedText("");

    setStartDate("");
    setEndDate("");
    setFilterText("");
    setFilteredItems([...consultationsData]);
    setSelectedOption("");
    setSelectedOptionPayment("");
    setExportCsv(0);
  };

  useEffect(() => {
    if (consultationsData) {
      setFilteredItems([...consultationsData]);
    }
  }, [consultationsData]);

  useEffect(() => {
    debouncedFilterDatatable(filterText);
    return () => debouncedFilterDatatable.cancel(); // Cleanup debounce on unmount
  }, [filterText, debouncedFilterDatatable]);

  useEffect(() => {
    getConsultations();
  }, [
    selectedOption,
    selectedOptionPayment,
    debouncedText,
    endDate,
    startDate,
    exportCsv,
    sort,
    direction,
  ]);
  const generatePageNumbers = (currentPage = 1, totalPages = 10) => {
    currentPage = isNaN(currentPage) ? 1 : currentPage;

    const maxPages = 4;
    const windowStart = Math.max(currentPage - Math.floor(maxPages / 2), 1);
    const windowEnd = Math.min(windowStart + maxPages - 1, totalPages);
    return Array.from(
      { length: windowEnd - windowStart + 1 },
      (_, i) => i + windowStart
    );
  };
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="pt-3 pt-md-5">
        <Container fluid>
          <Row>
            {/* Start of filter container */}
            <Col md="12">
              <div
                className={`custom-collapse ${
                  isOpen ? "open" : ""
                } desktop_none mobile_block`}
              >
                <div
                  className="custom-collapse-header"
                  onClick={toggleCollapse}
                >
                  <span>
                    Filter
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </span>
                  <span className={`arrow ${isOpen ? "open" : ""}`}></span>
                </div>
                <div
                  className="custom-collapse-body"
                  style={{ maxHeight: isOpen ? "1000px" : "0" }}
                >
                  <div className={`collapse-content ${isOpen ? "open" : ""}`}>
                    <Card className="filter-card card">
                      <Card.Header>
                        <div className="d-flex align-items-center justify-content-between table-head">
                          <Card.Title className="text-white" as="h4">
                            Filter
                          </Card.Title>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xl={4} className="search-wrap">
                            <Form.Group>
                              <Form.Label className="d-block mb-2 text-white">
                                Search with title
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={
                                  localStorage.getItem("RefillsearchText")
                                    ? localStorage.getItem("RefillsearchText")
                                    : filterText
                                }
                                onChange={(e) =>
                                  filterDatatable(e.target.value)
                                }
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xl={8} className="d-flex justify-content-end ">
                            <div className="d-flex justify-content-between flex-xl-nowrap flex-wrap ">
                              <div className="datepikker-btn d-flex flex-xl-nowrap flex-wrap datapiker-custom-alignment">
                                <Form.Group className="mr-2">
                                  <Form.Label className="d-block mb-2 text-white">
                                    Start Date:
                                  </Form.Label>
                                  <div className="date-picker-container form-controll">
                                    <DatePicker
                                      showIcon
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      maxDate={new Date()}
                                      icon="fa fa-calendar"
                                      placeholderText="mm/dd/yyyy"
                                    />
                                  </div>
                                </Form.Group>
                                <Form.Group className="mr-2">
                                  <Form.Label className="d-block mb-2 text-white">
                                    End Date:
                                  </Form.Label>
                                  <div className="date-picker-container form-controll">
                                    <DatePicker
                                      showIcon
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      maxDate={new Date()}
                                      icon="fa fa-calendar"
                                      placeholderText="mm/dd/yyyy"
                                    />
                                  </div>
                                  <span style={{ color: "#ff777f" }}>
                                    {notGreaterStartDate}
                                  </span>
                                </Form.Group>
                                <Form.Group>
                                  <label className="d-none d-sm-block mb-2 form-label">
                                    &nbsp;
                                  </label>
                                  <div className="d-flex  filter-btns-holder">
                                    <button
                                      type="button"
                                      className="outline-button w-auto"
                                      onClick={() => resetFilter()}
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="export-btn wi-100">
                                <Form.Group className="mr-3 ml-md-3">
                                  <label className="d-none d-sm-block mb-2 form-label">
                                    &nbsp;
                                  </label>
                                  <div className="d-flex  filter-btns-holder">
                                    <button
                                      type="button"
                                      className="outline-button w-auto export-btn"
                                      onClick={() => setExportCsv(1)}
                                    >
                                      Export
                                    </button>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </Col>
                          {/* <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with status
                        </Form.Label>
                        <select
                          value={selectedOption}
                          onChange={(e) => handleSelectChangePrescription(e)}
                        >
                          <option value="">Select Prescription</option>
                          <option value="false">Prescription Not Sent</option>
                        </select>
                      </Form.Group>
                    </Col>

                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with payment status
                        </Form.Label>
                        <select
                          value={selectedOptionPayment}
                          onChange={(e) => handleSelectChangePayment(e)}
                        >
                          <option value="">Select</option>
                          <option value="paid">Paid</option>
                        </select>
                      </Form.Group>
                    </Col> */}
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <Card className="filter-card card mobile_none">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title className="text-white" as="h4">
                      Filter
                    </Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Search with title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Search"
                          value={
                            localStorage.getItem("RefillsearchText")
                              ? localStorage.getItem("RefillsearchText")
                              : filterText
                          }
                          onChange={(e) => filterDatatable(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={8} className="d-flex justify-content-end ">
                      <div className="d-flex justify-content-between flex-xl-nowrap flex-wrap ">
                        <div className="datepikker-btn d-flex flex-xl-nowrap flex-wrap datapiker-custom-alignment">
                          <Form.Group className="mr-2">
                            <Form.Label className="d-block mb-2 text-white">
                              Start Date:
                            </Form.Label>
                            <div className="date-picker-container form-controll">
                              <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                maxDate={new Date()}
                                icon="fa fa-calendar"
                                placeholderText="mm/dd/yyyy"
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="mr-2">
                            <Form.Label className="d-block mb-2 text-white">
                              End Date:
                            </Form.Label>
                            <div className="date-picker-container form-controll">
                              <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                maxDate={new Date()}
                                icon="fa fa-calendar"
                                placeholderText="mm/dd/yyyy"
                              />
                            </div>
                            <span style={{ color: "#ff777f" }}>
                              {notGreaterStartDate}
                            </span>
                          </Form.Group>
                          <Form.Group>
                            <label className="d-none d-sm-block mb-2 form-label">
                              &nbsp;
                            </label>
                            <div className="d-flex  filter-btns-holder">
                              <button
                                type="button"
                                className="outline-button w-auto"
                                onClick={() => resetFilter()}
                              >
                                Reset
                              </button>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="export-btn wi-100">
                          <Form.Group className="mr-3 ml-md-3">
                            <label className="d-none d-sm-block mb-2 form-label">
                              &nbsp;
                            </label>
                            <div className="d-flex  filter-btns-holder">
                              <button
                                type="button"
                                className="outline-button w-auto export-btn"
                                onClick={() => setExportCsv(1)}
                              >
                                Export
                              </button>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with status
                        </Form.Label>
                        <select
                          value={selectedOption}
                          onChange={(e) => handleSelectChangePrescription(e)}
                        >
                          <option value="">Select Prescription</option>
                          <option value="false">Prescription Not Sent</option>
                        </select>
                      </Form.Group>
                    </Col>

                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group>
                        <Form.Label className="d-block mb-2 text-white">
                          Filter with payment status
                        </Form.Label>
                        <select
                          value={selectedOptionPayment}
                          onChange={(e) => handleSelectChangePayment(e)}
                        >
                          <option value="">Select</option>
                          <option value="paid">Paid</option>
                        </select>
                      </Form.Group>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>

              <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                <h4 className="card-title">
                  Paid Consultation Prescription Not Sent
                </h4>
                <div>
                  <div className="d-flex datatable-title filter-btns-holder">
                    <button
                      onClick={refreshTable}
                      type="button"
                      className="outline-button w-auto"
                    >
                      <RiRefreshFill
                        style={{ cursor: "pointer" }}
                        color="#fff"
                        size={28}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                className="react-datatable"
                progressPending={loader}
                paginationPerPage={perPage}
                paginationServer
                progressComponent={<FullPageLoader />}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                paginationComponent={() => (
                  <div className="pagination">
                    rows per page :
                    <select
                      value={perPage}
                      onChange={(e) =>
                        handlePerRowsChange(
                          Number(e.target.value) == NaN
                            ? 1
                            : Number(e.target.value)
                        )
                      }
                    >
                      {paginationRowsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div className="total_pages">
                      {" "}
                      {currentPage && currentPage > 1
                        ? perPage * ((isNaN(currentPage) ? 1 : currentPage) - 1)
                        : 1}{" "}
                      -{(isNaN(currentPage) ? 1 : currentPage) * perPage} of{" "}
                      {totalRows}
                    </div>
                    <div>
                      <button
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      >
                        &lt;&lt;
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        &lt;
                      </button>
                      {generatePageNumbers(
                        currentPage,
                        Math.ceil(totalRows / perPage)
                      ).map((page) => (
                        <button
                          key={page}
                          onClick={() => handlePageChange(page)}
                          className={currentPage === page ? "active" : ""}
                        >
                          {page}
                        </button>
                      ))}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage === Math.ceil(totalRows / perPage)
                        }
                      >
                        <span>&gt; </span>
                      </button>
                      <button
                        onClick={() =>
                          handlePageChange(Math.ceil(totalRows / perPage))
                        }
                        disabled={
                          currentPage === Math.ceil(totalRows / perPage) ||
                          Math.ceil(totalRows / perPage) === 0
                        }
                      >
                        <span>&gt;&gt;</span>
                      </button>
                    </div>
                  </div>
                )}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PaidPrecriptionNotSent;
