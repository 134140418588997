import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  FormGroup,
  Breadcrumb,
} from "react-bootstrap";

import {
  getPartnerDiscountById,
  updatePartnerDiscount,
} from "../../actions/partnerDiscountAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Schema for yup
const partnerDiscountSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title must have at least 2 characters")
    .max(100, "Title can't be longer than 100 characters")
    .trim("Spaces are not allowed")
    .required("Title field is required"),
  discount: Yup.number()
    .required("Discount field is required")
    .positive()
    .integer()
    .min(1)
    .max(99),
});

const EditPartnerDiscount = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [partnerDiscountData, setPartnerDiscountData] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: partnerDiscountData,
    validationSchema: partnerDiscountSchema,
    onSubmit: async (values) => {
      setLoader(true);
      let res = await updatePartnerDiscount(id, values);
      if (res?.data?.success) {
        navigate("/partner-discount");
        toast.success(res.data.message);
      }
      setLoader(false);
    },
  });
  useEffect(() => {
    const partnerDiscountById = async () => {
      setLoader(true);
      let res = await getPartnerDiscountById(id);
      if (res.data.data) {
        setPartnerDiscountData(res.data.data);
      }
      setLoader(false);
    };
    partnerDiscountById();
  }, [id]);
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              <Col md="12">
                <Breadcrumb className="care-md-breadcrumb">
                  <Link className="breadcrumb-item" to="/partner-discount">
                    Partner Discount
                  </Link>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Add Content
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              placeholder="Enter title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <span
                                className={formik.errors.title ? `` : `d-none`}
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.title}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Discount <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="discount"
                              placeholder="Enter discount"
                              value={formik.values.discount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.discount &&
                            formik.errors.discount ? (
                              <span
                                className={
                                  formik.errors.discount ? `` : `d-none`
                                }
                              >
                                <label className="pl-1 text-danger">
                                  {formik.errors.discount}
                                </label>
                              </span>
                            ) : null}
                          </Form.Group>
                          <FormGroup>
                            <Row>
                              <Col md={4}>
                                <label className="label-font">Status</label>
                              </Col>
                              <Col md={8} className="check-inline d-flex">
                                <label className="right-label-radio mr-3 mb-2">
                                  Active
                                  <input
                                    name="status"
                                    type="radio"
                                    checked={formik.values.status}
                                    value={formik.values.status}
                                    onChange={(e) =>
                                      formik.setFieldValue("status", true)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="right-label-radio mr-3 mb-2">
                                  Inactive
                                  <input
                                    name="status"
                                    type="radio"
                                    checked={!formik.values.status}
                                    value={!formik.values.status}
                                    onChange={(e) =>
                                      formik.setFieldValue("status", false)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Button className="btn-filled" type="submit">
                            Update
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default EditPartnerDiscount;
