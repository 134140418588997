import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getAllJobApplications = (data) =>
  PromiseHandler(
    apiHelper("get", "/api/admin/get-all-job-applications/get", data)
  );
const getJobApplicationById = (id) =>
  PromiseHandler(
    apiHelper("get", `/api/admin/get-all-job-applications/get/${id}`)
  );

const deleteJobAPllicationById = (id) =>
  PromiseHandler(
    apiHelper("delete", `/api/admin/get-all-job-applications/delete/${id}`)
  );

export {
  getAllJobApplications,
  getJobApplicationById,
  deleteJobAPllicationById,
};
