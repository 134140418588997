import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import {
  getAllEmailSubscribers,
  deleteEmailSubscriberById,
} from "../../actions/emailSubscriberAction";
import { useSelector } from "react-redux";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import { useLocation } from "react-router-dom";
import initializeLocalStorage from "../../components/PaginationHandle";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const SubscriberList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [filterText, setFilterText] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  initializeLocalStorage(pathname, localStorage.getItem("currentURL"));

  const deleteSubscriber = async (id) => {
    try {
      const response = await deleteEmailSubscriberById(id);
      if (response.data.success) {
        toast.success(response.data.message);
        getAllEmailSubscribersList();
      }
    } catch (error) {}
  };

  const getAllEmailSubscribersList = async () => {
    setLoader(true);
    let res = await getAllEmailSubscribers();
    if (res?.data?.data?.response) {
      setSubscribers(res?.data?.data?.response);
    }
    setLoader(false);
  };

  useEffect(() => {
    getAllEmailSubscribersList();
  }, []);

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const getProperty = (row, columnId) => {
      switch (columnId) {
        case "Email":
          return row?.email?.toLowerCase() || "";

        case "Created At":
          const endDate = new Date(row?.createdAt);
          return `${
            endDate.getMonth() + 1
          }/${endDate.getDate()}/${endDate.getFullYear()}`.toLowerCase();

        default:
          return "";
      }
    };

    const a = getProperty(rowA, columnId);
    const b = getProperty(rowB, columnId);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Email"),
    },

    {
      name: "Created At",
      selector: (row) => {
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };

        const date = new Date(row?.createdAt);
        return date.toLocaleDateString(undefined, options).replace(/\//g, "-");
      },
      minWidth: "220px",
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Created At"),
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <div className="td-actions table-actions-btns">
            <ul className="list-unstyled mb-0 d-flex justify-content-center">
              {currentUserRole?.deleteEmailSubscriber ? (
                !(
                  row?.username === admin?.username ||
                  row?.role?.title == "super admin"
                ) ? (
                  <li className="d-inline-block align-top">
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                      placement="left"
                    >
                      <button
                        onClick={() => deleteSubscriber(row._id)}
                        type="button"
                        className="btn-link btn-icon btn btn-danger"
                      >
                        <FaTrash />
                      </button>
                    </OverlayTrigger>
                  </li>
                ) : (
                  <li className="d-inline-block align-top">
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                      placement="left"
                    >
                      <button
                        type="button"
                        className="btn-link btn-icon btn btn-danger disabled"
                        disabled
                      >
                        <FaTrash />
                      </button>
                    </OverlayTrigger>
                  </li>
                )
              ) : (
                <button
                  type="button"
                  className="btn-link btn-icon btn btn-success disabled"
                  disabled
                >
                  <FaTrash />
                </button>
              )}
            </ul>
          </div>
        </>
      ),
      sortable: false,
      minWidth: "220px",
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(selectedOption, text);
  };

  const filterSearchDatatable = (text, searchText) => {
    const filteredItemsResult = subscribers.filter((data) => {
      if (text) {
        if (
          data.status.toString().toLowerCase().includes(text.toLowerCase()) &&
          data?.email.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return data;
        }
      } else if (data?.email.toLowerCase().includes(searchText.toLowerCase())) {
        return data;
      }
      return undefined;
    });
    setFilteredItems([...filteredItemsResult]);
    setSelectedOption(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...subscribers]);
    setSelectedOption("");
  };

  useEffect(() => {
    if (subscribers) {
      setFilteredItems([...subscribers]);
    }
  }, [subscribers]);

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5">
          <Container fluid>
            <Row>
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with Name...
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {/* <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Filter with status...
                          </Form.Label>
                          <select
                            value={selectedOption}
                            onChange={(e) =>
                              filterSearchDatatable(e.target.value, filterText)
                            }
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </Form.Group>
                      </Col> */}
                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* End of filled container */}

                {/* Start of table for medical type */}
                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">Subscribers List</h4>
                </div>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default SubscriberList;
