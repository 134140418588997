import {apiHelper} from "../redux/apiHelper"
export const getCmsPrescriptionSection=async()=>{
    try{
       let res=await apiHelper('get','/api/admin/cms-refill-prescription-section/list');
       if(res){
         return res;
       }
    }catch(e){
     console.log(e);
    }
 }
 export const addCmsPrescriptionSection=async(data)=>{

    try{
        let res=await apiHelper('post',`/api/admin/cms-refill-prescription-section/create`,data);
        if(res){
          return res;
        }
     }catch(e){
        console.log(e);
     }
 }
 export const updateCmsPrescriptionSection=async(id,data)=>{
   try{
       let res=await apiHelper('post',`/api/admin/cms-refill-prescription-section/update/${id}`,data);
       if(res){
         return res;
       }
    }catch(e){
       console.log(e);
    }
}
export const getCmsPrescriptionSectionById=async(id)=>{
   try{
       let res=await apiHelper('get',`/api/admin/cms-refill-prescription-section/get/${id}`);
       if(res){
         return res;
       }
    }catch(e){
     console.log(e);
    }
}
 export const deleteCmsPrescriptionSectionById=async(id)=>{
    try{
        let res=await apiHelper('delete',`/api/admin/cms-refill-prescription-section/delete/${id}`);
        if(res){
          return res;
        }
     }catch(e){
      console.log(e);
     }
    
}