import { apiHelper, PromiseHandler } from "../redux/apiHelper";
const getPatientUsers = (data) =>
  PromiseHandler(apiHelper("post", "/api/admin/user/get-patients", data));
const updatePatientStatus = (id, data) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/user/update-patient-status/${id}`, data)
  );
const updatePassword = (id, data) =>
  PromiseHandler(
    apiHelper("post", `/api/admin/user/update-patient-password/${id}`, data)
  );
export { getPatientUsers, updatePatientStatus, updatePassword };
