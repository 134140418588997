import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { adminLogin, verify2FA } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { LOGIN_ADMIN } from "../../redux/actionTypes";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useSelector } from "react-redux";
import routes from "../../routes";
import logo from "../../assets/images/Logo.png";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .trim("Spaces are not allowed")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Index = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoader(true);
      let res = await adminLogin(values);

      if (res?.data?.success) {
        if (res?.data?.twoFactorAuthEnabled && res?.data?.twoFactorAuthSecret) {
          setLoader(false);
          if (res?.data?.qrCodeToShow) {
            setShowQrCode(true);
            setQrCodeData(res?.data?.qRCodeGenerate);
          }
          setUserData(res?.data?.admin);
          setShowModel(true);
        } else {
          if (res?.data.admin?.role) {
            if (res?.data?.admin?.role?.status) {
              dispatch({
                type: LOGIN_ADMIN,
                payload: res?.data?.admin,
              });
              ENV.encryptUserData(res?.data);
              let otherViewRole;
              if (
                res?.data?.admin?.role &&
                !res?.data?.admin?.role?.viewDashboard
              ) {
                otherViewRole = Object.keys(res?.data?.admin?.role).find(
                  (key) => key.startsWith("view") && res?.data?.admin?.role[key]
                );
                if (otherViewRole) {
                  const pathsForOtherViewRole = routes
                    .filter((item) => item.permissionName === otherViewRole)
                    .map((item) => item.path);
                  navigate(pathsForOtherViewRole[0]);
                  toast.success("Successfully Logged In");
                  setLoader(false);
                }
              } else {
                navigate("/dashboard");
              }
            } else {
              toast.error("Currently status is inactive!");
            }
          } else {
            toast.error("Currently No Role!");
          }
        }
      }
      setLoader(false);
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [qRCodeData, setQrCodeData] = useState("");
  const [getUserData, setUserData] = useState({});
  const [getCodeData, setCodeData] = useState("");
  const [getErrorCodeData, setErrorCodeData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [path, setPath] = useState("/dashboard");

  const emailRef = useRef(null);

  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin?.role;

  useEffect(() => {
    if (admin.role && !admin?.role?.viewDashboard) {
      const otherViewRole = Object.keys(admin.role).find(
        (key) => key.startsWith("view") && admin.role[key]
      );
      if (otherViewRole) {
        const pathsForOtherViewRole = routes
          .filter((item) => item.permissionName === otherViewRole)
          .map((item) => item.path);
        setPath(pathsForOtherViewRole[0]);
      }
    }
  }, [admin]);

  const handleClose = () => {
    setShowModel(false);
    setShowQrCode(false);
    setQrCodeData("");
    setCodeData("");
    setUserData("");
    setErrorCodeData("");
  };
  const submitHandel2FA = async (e) => {
    e.preventDefault();
    if (getCodeData === "") {
      setErrorCodeData("Please enter OTP code");
      return false;
    }
    setErrorCodeData("");
    var data = {
      code: getCodeData,
      email: getUserData.email,
    };
    setLoader(true);
    let res = await verify2FA(data);
    if (res?.data?.success) {
      if (res?.data?.admin?.role) {
        if (res?.data?.admin?.role?.status) {
          dispatch({
            type: LOGIN_ADMIN,
            payload: res?.data?.admin,
          });
          ENV.encryptUserData(res?.data);
          navigate(path);
          toast.success("Successfully Logged In");
          setLoader(false);
        } else {
          toast.error("Currently status is inactive!");
        }
      } else {
        toast.error("Currently No Role!");
      }
    }
  };

  const removeSpaces = (e) => {
    emailRef.current.value = e.target.value.replaceAll("\\s", "");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };
  const setValue = (e) => {
    setCodeData(e.target.value);
  };

  const showPasswordFunc = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {showModel ? (
        <Modal
          show={showModel}
          onHide={handleClose}
          centered
          className="two-factor-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>TWO FACTOR AUTHENTICATION</Modal.Title>
          </Modal.Header>
          <Form onSubmit={submitHandel2FA}>
            <Modal.Body>
              <p>
                Please enter the OTP generated on your Authenticator App. Ensure
                you submit the current one because it refreshes every 30
                seconds.
              </p>
              <Form.Group
                controlId="formFilePreview"
                className="mb-3 text-center"
              >
                {showQrCode ? (
                  <img
                    src={qRCodeData}
                    width="200"
                    height="200"
                    alt="qr-code not found"
                  />
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Label>
                  One Time Password <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  placeholder="One Time Password"
                  onChange={setValue}
                />
                {getErrorCodeData.length > 0 ? (
                  <p className="text-danger mt-2">{getErrorCodeData}</p>
                ) : null}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                className="btn-filled btn"
              >
                Login
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
       {!localStorage.getItem("adminInfo") ? (

        <div className="full-page section-image" data-color="black">
          <div className="content d-flex align-items-center p-0">
            <Container>
              <Col className="mx-auto" lg="5" md="8">
                <Form onSubmit={formik.handleSubmit} className="form">
                  <Card className="card-login custom-login-card">
                    <Card.Header className="text-center">
                      <div className="logo-holder d-inline-block align-top">
                        <img src={logo} className="img-fluid" alt="logo" />
                      </div>
                      <h4 className="log-in-heading">Log In</h4>
                      <p className="login-tagline">
                        Enter your email and password below
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Body>
                        <Form.Group onKeyPress={handleKeyPress}>
                          <label>
                            Email <span className="staric-lightred">*</span>
                          </label>
                          <Form.Control
                            placeholder="Enter Email"
                            type="email"
                            id="emailaddress"
                            ref={emailRef}
                            name="email"
                            autoComplete="off"
                            onKeyUp={removeSpaces}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <span
                              className={formik.errors.email ? `` : `d-none`}
                            >
                              <label className="pl-1 staric-lightred mb-0">
                                {formik.errors.email}
                              </label>
                            </span>
                          ) : null}
                        </Form.Group>
                        <Form.Group onKeyPress={handleKeyPress}>
                          <label>
                            Password <span className="staric-lightred">*</span>
                          </label>
                          <div className="show-password-field position-relative">
                            <Form.Control
                              placeholder="Enter Password"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <i
                              className={
                                showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                              }
                              onClick={() => showPasswordFunc()}
                            ></i>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <span
                              className={formik.errors.password ? `` : `d-none`}
                            >
                              <label className="pl-1 staric-lightred  mb-0">
                                {formik.errors.password}
                              </label>
                            </span>
                          ) : null}
                        </Form.Group>
                      </Card.Body>
                    </Card.Body>
                    <Card.Footer className="ml-auto mr-auto w_94 pt-0 text-center">
                      {loader ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <Button className="btn-filled w-100" type="submit">
                          Login
                        </Button>
                      )}
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
          <div
            className="full-page-background"
            style={{
              background:
                "transparent linear-gradient(224deg, #FD6F21 0%, #0D0F17 100%) 0% 0% no-repeat padding-box",
            }}
          ></div>
        </div>
      ) : (
        navigate(path)
      )}
    </>
  );
};

export default Index;
