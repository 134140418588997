import axios from "axios";
import { ENV } from "../config/config";
import { toast } from "react-toastify";

let baseUrl = ENV.serverUrl;

const apiHelper = async (apiType, path, data) => {
  if (baseUrl === undefined || !baseUrl) {
    baseUrl = "";
  }

  let adminToken = localStorage.getItem("adminToken");

  if (
    apiType === "post" ||
    apiType === "put" ||
    apiType === "get" ||
    apiType === "delete"
  ) {
    try {
      let response = await axios({
        method: apiType,
        url: `${baseUrl + path}`,
        data,
        headers: {
          Authorization: "Bearer " + JSON.parse(adminToken),
        },
      });
      return response;
    } catch (error) {
      
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          toastId: "005",
        });
        return;
        // toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message, {
          toastId: "005",
        });
        // toast.error(error?.message);
      }
    }
  }
};
const PromiseHandler = async (method) => {
  try {
    return await method;
  } catch (error) {
    console.log(error);
  }
};
export { PromiseHandler, apiHelper };
