import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Button,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getMedicalConsultations,
  deleteMedicalConsultationById,
  updateMedicalConsultation,
} from "../../../actions/medicalConsultationAction";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";

createTheme(
  "solarized",
  {
    text: {
      primary: "#000",
      secondary: "#000",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#DFE0EB",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const MedicalConsultations = () => {
  const [medicalConsultation, setMedicalConsultation] = useState([]);
  const [loader, setLoader] = useState(false);
  const { admin } = useSelector((state) => state.admin);
  const currentUserRole = admin.role;
  const [getDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  const hideModel = () => {
    setDelete(false);
  };

  const medicalConsultationStatus = async (id, status) => {
    let obj = {
      status: status,
    };
    let res = await updateMedicalConsultation(id, obj);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
      getAllMedicalConsultation();
    }
  };

  const deleteMedicalConsultation = async () => {
    setDelete(false);
    setLoader(true);
    let res = await deleteMedicalConsultationById(deleteId);
    if (res.data.success) {
      toast.success(res.data.message);
      getAllMedicalConsultation();
    }
    setLoader(false);
  };
  const showModel = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  const getAllMedicalConsultation = async () => {
    setLoader(true);
    let res = await getMedicalConsultations();
    if (res.data.data) {
      setMedicalConsultation(res.data.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    getAllMedicalConsultation();
  }, []);

  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const getProperty = (row, columnId) => {
      switch (columnId) {
        case "Name":
          return row.title?.toLowerCase() || "";
        case "min age":
          return row?.minAge || "";
        case "Medical Type":
          return row?.medicalType?.title.toLowerCase() || "";
        case "Status":
          return row?.status || "";

        default:
          return "";
      }
    };

    const a = getProperty(rowA, columnId);
    const b = getProperty(rowB, columnId);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.title,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Name"),
    },
    {
      name: "Slug",
      selector: (row) => row?.slug,
    },
   
    {
      name: "min age",
      selector: (row) => row?.minAge,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "min age"),
    },
    {
      name: "discount",
      selector: (row) => row?.partnerDiscount?.discount ?? "N/A",
    },
    {
      name: "Medical Type",
      selector: (row) => row?.medicalType?.title,
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Medical Type"),
    },
    {
      name: "Price",
      selector: (row) => row?.price,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status === true ? (
            <label className="label label-success m-0">Active</label>
          ) : (
            <label className="label label-danger m-0">Inactive</label>
          )}
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => caseInsensitiveSort(a, b, "Status"),
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="td-actions table-actions-btns">
          <ul className="list-unstyled mb-0 d-flex justify-content-center">
            {currentUserRole?.editMedicalConsultation ? (
              <li className="d-inline-block align-top">
                <Dropdown
                  className="patient-status-btn"
                  style={{ position: "initial" }}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {row?.status === true ? "Active" : "Inactive"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={
                        row.status === true ? "label-success text-white" : ""
                      }
                      onClick={() => medicalConsultationStatus(row?._id, true)}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        row.status === false ? "label-danger text-white" : ""
                      }
                      onClick={() => medicalConsultationStatus(row?._id, false)}
                    >
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <Dropdown
                  className="patient-status-btn"
                  style={{ position: "initial" }}
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic" disabled>
                    {row?.status ? "Active" : "Inactive"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}

            {currentUserRole?.editMedicalConsultation ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={`/forms/medical-consultations/edit/${row._id}`}
                    type="button"
                    className="btn-link btn-icon btn btn-success"
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Edit</Tooltip>}
                  placement="left"
                >
                  <Link
                    to={""}
                    type="button"
                    className="btn-link btn-icon btn btn-success disabled"
                    disabled
                  >
                    <FaEdit />
                  </Link>
                </OverlayTrigger>
              </li>
            )}

            {currentUserRole?.deleteMedicalConsultation ? (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    onClick={() => showModel(row._id)}
                    type="button"
                    className="btn-link btn-icon btn btn-danger"
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            ) : (
              <li className="d-inline-block align-top">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-436082023">Delete</Tooltip>}
                  placement="left"
                >
                  <button
                    type="button"
                    className="btn-link btn-icon btn btn-danger disabled"
                    disabled
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        </div>
      ),
      minWidth: "212px",
      sortable: false,
    },
  ];

  const filterDatatable = (text) => {
    setFilterText(text);
    filterSearchDatatable(selectedOption, text);
  };

  const filterSearchDatatable = (text, searchText) => {
    const filteredItemsResult = medicalConsultation.filter((data) => {
      if (text) {
        if (
          data.status.toString().toLowerCase().includes(text.toLowerCase()) &&
          (data?.title.toLowerCase().includes(searchText.toLowerCase()) ||
            data?.slug.toLowerCase().includes(searchText.toLowerCase()) ||
            data?.medicalType?.title
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (data?.price).toString().includes(searchText.toLowerCase()))
        ) {
          return data;
        }
      } else if (
        data?.title.toLowerCase().includes(searchText.toLowerCase()) ||
        data?.slug.toLowerCase().includes(searchText.toLowerCase()) ||
        data?.medicalType?.title
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        (data?.price).toString().includes(searchText.toLowerCase())
      ) {
        return data;
      }
      return undefined;
    });
    setFilteredItems([...filteredItemsResult]);
    setSelectedOption(text);
  };

  const resetFilter = () => {
    setFilterText("");
    setFilteredItems([...medicalConsultation]);
    setSelectedOption("");
  };

  useEffect(() => {
    if (medicalConsultation) {
      setFilteredItems([...medicalConsultation]);
    }
  }, [medicalConsultation]);

  return (
    <>
      {getDelete ? (
        <SweetAlert
          warning
          showCloseButton
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          title="Are you sure you want to delete?"
          onConfirm={deleteMedicalConsultation}
          onCancel={hideModel}
          focusCancelBtn
          customClass="del-sweet-alert"
        >
          You will not be able to recover this data!
        </SweetAlert>
      ) : null}
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="pt-3 pt-md-5 ">
          <Container fluid>
            <Row className="medical-typeuser-wrap">
              {/* Start of filter container */}
              <Col md="12">
                <Card className="filter-card card">
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title className="text-white" as="h4">
                        Filter
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Search with title...
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="search"
                            placeholder="Search"
                            value={filterText}
                            onChange={(e) => filterDatatable(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={6} className="search-wrap">
                        <Form.Group>
                          <Form.Label className="d-block mb-2 text-white">
                            Filter with status...
                          </Form.Label>
                          <select
                            value={selectedOption}
                            onChange={(e) =>
                              filterSearchDatatable(e.target.value, filterText)
                            }
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group>
                          <label className="d-none d-sm-block mb-2 form-label">
                            &nbsp;
                          </label>
                          <div className="d-flex  filter-btns-holder">
                            <button
                              type="button"
                              className="outline-button"
                              onClick={() => resetFilter()}
                            >
                              Reset
                            </button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* End of filled container */}

                <div className="datatable-title card d-flex flex-row justify-content-between align-items-center mb-0">
                  <h4 className="card-title">Medical Consultations</h4>
                  {currentUserRole?.addMedicalConsultation ? (
                    <div className="btn-add">
                      <Link
                        className="float-sm-right btn-filled d-flex align-items-center"
                        to="/forms/medical-consultations/add"
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Medical Consultation
                      </Link>
                    </div>
                  ) : (
                    <div className="btn-add">
                      <Button
                        className="float-sm-right btn-filled d-flex align-items-center disabled"
                        disabled
                      >
                        <span className="add-icon mr-2">
                          <FaPlus />
                        </span>{" "}
                        Add Prescription Refill{" "}
                      </Button>
                    </div>
                  )}
                </div>

                <DataTable
                  columns={columns}
                  data={filteredItems}
                  theme="solarized"
                  pagination
                  className="react-datatable medical-consultation-table"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default MedicalConsultations;
